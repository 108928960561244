// Assets
import iconCamera from 'assets/img/icon/camera.png';
import iconTimeSheet from 'assets/img/icon/schedule.png';
import iconBlank from 'assets/img/icon/blank.png';

import iconClockIn from 'assets/img/icon/clock-in.png';
import iconClockOut from 'assets/img/icon/clock-out.png';
import iconPayStub from 'assets/img/icon/paystub.png';

import iconProject from 'assets/img/icon/project.png';
import iconTask from 'assets/img/icon/task.png';
import iconChat from 'assets/img/icon/chat.png';


import 'styles/reduction.scss';
import 'App.css';

// Core modules
import React, {useState, useEffect} from 'react';
import Accordion from 'react-bootstrap/Accordion';

// Framework modules
import Page from 'components/Page';
import { Badge, Card, CardBody, CardHeader, CardImg, CardLink, CardText, CardTitle, CardGroup, Col, Input, ListGroup, ListGroupItem, Row, Modal, ModalBody, ModalFooter, ModalHeader, Button, FormGroup, Label } from 'reactstrap';
import { IconWidget, NumberWidget } from 'components/Widget';
import NotificationSystem from 'react-notification-system';
import { NOTIFICATION_SYSTEM_STYLE } from 'utils/constants';
import {
  MdBubbleChart,
  MdInsertChart,
  MdPersonPin,
  MdPieChart,
  MdRateReview,
  MdShare,
  MdShowChart,
  MdThumbUp,
} from 'react-icons/md';
import ViewMap		from './ViewMap';
import { Cookies }	from 'react-cookie';
import DataAdapter	from './adapter/DataAdapter';
import TaskDetail	from "./model/TaskDetails/TaskDetail";
import Task			from "./model/Tasks/Task";
import Person		from "./model/Persons/Person";
import Project		from "./model/Projects/Project";
import Invoice		from "./model/Invoices/Invoice";
import Chat			from "./model/Chats/Chat";

const cookie =new Cookies();
const notificationSystem = React.createRef();
const addNotification = (_MESSAGE_, _TYPE_) => {
	const notification = notificationSystem.current;
	notification.addNotification({
	  message: _MESSAGE_,
	  level: _TYPE_
	});
};	


function TileMenu(props){
	const [hasClockIn, setHasClockIn]	=	useState(false);
	const [taskId, setTaskId]				=	useState('');
	const [task, setTask]				=	useState('');
	const [taskFolder, setTaskFolder]	=	useState('');
	const [clockIn, setClockIn]			=	useState(false);
	const [duration, setDuration]		=	useState(false);
	const [project, setProject]			=	useState('');
	const [projectRooms, setProjectRooms]	=	useState([]);
	const [jobs, setJobs]				=	useState([]);
    const [latitude, setLatitude]		=	useState(-1);
    const [longitude, setLongitude]		= 	useState(-1);

	const showPositionError = (error) => {
	  switch(error.code) {
		case error.PERMISSION_DENIED:
			addNotification("Please tap on the lock beside the address bar and Turn On the location","info");
			//x.innerHTML = "User denied the request for Geolocation."
		  break;
		case error.POSITION_UNAVAILABLE:
			addNotification("Location information is unavailable","info");
			//x.innerHTML = "Location information is unavailable."
		  break;
		case error.TIMEOUT:
			addNotification("The request to get user location timed out, Please try to refresh the page again","info");
			//x.innerHTML = "The request to get user location timed out."
		  break;
		case error.UNKNOWN_ERROR:
			addNotification("An unknown error occurred","info");
			//x.innerHTML = "An unknown error occurred."
		  break;
	  }
	}
	
	useEffect(()=>{
		var	sql	=	"SELECT pt.pt_id, pt.task, pt.attachments, prj.prj_address AS `project`, DATE_FORMAT(CONVERT_TZ(ptd.Clock_in,@@session.time_zone,'-05:00'),'%H:%i') clock_in,time_format(abs(timediff(now(), ptd.Clock_in)),'%H:%i') AS `duration` FROM rb_project_task_details ptd LEFT JOIN rb_project_tasks pt ON pt.pt_id=ptd.pt_id LEFT JOIN rb_projects prj ON prj.prj_id = ptd.prj_id WHERE ptd.task_type=122 AND (date(ptd.start_date)=date(now()) OR date(ptd.start_date)=date(DATE_SUB(now(), INTERVAL 1 DAY))) AND  ptd.sender_id = " + cookie.get("v-person-id") + " AND ptd.Clock_in IS NOT NULL AND (ptd.Clock_out IS NULL OR ptd.Clock_out = '0000-00-00 00:00:00') ORDER BY ptd.Clock_in DESC;";
		var dataAdapter =new DataAdapter(sql);
			dataAdapter.getRecords().then(data=>{
				if(data.length>0){
					setTaskId(data[0].pt_id);
					setTask(data[0].task);
					setTaskFolder(data[0].attachments);
					setClockIn(data[0].clock_in);
					setDuration(data[0].duration);
					setProject(data[0].project);
					setHasClockIn(true);
				}
			});



			navigator.geolocation.getCurrentPosition(function(position) {
				setLatitude(position.coords.latitude);
				setLongitude(position.coords.longitude);
				window.ReactNativeWebView.postMessage(position.coords.latitude + ',' + position.coords.longitude);
				
					(new DataAdapter("SELECT prj.*  FROM rb_projects prj WHERE prj.prj_status = 9 AND prj.prj_department = 12 AND prj.latitude<>0 AND prj.longitude<>0 AND ABS(prj.latitude-" + position.coords.latitude + ")<0.01 AND ABS(prj.longitude-" + position.coords.longitude + ")<0.01;"))
						.getRecords()
						.then(data=>{
							if(data.length>0){
								setJobs(data);
							}
						});
					
				}, );		

			(new DataAdapter("SELECT prj.*  FROM rb_projects prj WHERE prj.prj_status = 9 AND prj_department IN (SELECT rb_type_no FROM rb_types WHERE rb_type='prj_department' AND rb_type_type_no=-1);"))
				.getRecords()
				.then(data=>{
					if(data.length>0){
						setProjectRooms(data);
					}
				});
			
	},[]);

	const handleAttachmentUpload = (files, _currentFolder) => {
		for(var i=0; i < files.length-1 ; i++){
			const formData = new FormData()
			formData.append('myfile', files[i]);
		
			fetch("https://node.renobuilding.ca:3001" + '/file?project=' + _currentFolder, {
					method: 'POST',
			body: formData
			})
			.then(response => response.json())
			.then(data => {
				addNotification(data.data.name + ' File(s) Uploaded', "info");
			})
			.catch(error => {
				console.error(error)
			});
		}
			const formData = new FormData()
			formData.append('myfile', files[files.length-1]);
		
			fetch("https://node.renobuilding.ca:3001" + '/file?project=' + _currentFolder, {
					method: 'POST',
			body: formData
			})
			.then(response => response.json())
			.then(data => {
				addNotification(data.data.name + ' File(s) Uploaded', "info");
			})
			.catch(error => {
				console.error(error)
			});
	}

    return(
      <Page>
			<Row>
				<Col md={2} sm={4} xs={4} >
					  <Card className="p-2" >
							<CardImg top src={iconClockIn} onClick={()=>{
								
								  var taskDetail =new TaskDetail();
								  if(jobs.length>0){
									taskDetail.openClockForm(jobs[0]);
								  }else{
									addNotification("You are not in the job site","info");
									taskDetail.openClockForm(-1);
								  }
								  
								/*
								if(!hasClockIn){
								  var taskDetail =new TaskDetail();
								  if(jobs.length>0){
									taskDetail.openClockForm(jobs[0]);
								  }else{
									addNotification("You are not in the job site","info");
									taskDetail.openClockForm(-1);
								  }
								}else{
									addNotification("You have been clocked in before","info");
								}
								*/
						  }}/>
						<CardBody  className="p-0 mt-1">
						  <CardTitle className="text-center small" >CLOCK IN</CardTitle>
						</CardBody>
					  </Card>					  
				</Col>
				<Col md={2} sm={4} xs={4} >
					  <Card className="p-2" >
							<CardImg top src={iconClockOut} onClick={()=>{
								navigator.geolocation.getCurrentPosition(function(position) {
										setLatitude(position.coords.latitude);
										setLongitude(position.coords.longitude);
									});		
									var _UTC_ = (new Date()).toISOString().split('T')[0] + ' ' + (new Date()).toISOString().split('T')[1].slice(0, 5);
									var sql	=	"UPDATE rb_project_task_details ptd LEFT JOIN rb_person pr ON pr.person_id = ptd.sender_id SET ptd.percent_done=100, ptd.note='', ptd.Clock_out = '" + _UTC_ + "', ptd.real_hours = TIMESTAMPDIFF(MINUTE, Clock_in, '" + _UTC_ + "'), ptd.real_cost= IF(ptd.Clock_out IS NULL OR ptd.Clock_out='0000-00-00 00:00:00', '0',ROUND(TIMESTAMPDIFF(MINUTE, ptd.Clock_in, ptd.Clock_out)*pr.emp_wage_amount/60,2)), latitude_out ='" + latitude + "', longitude_out='" + longitude + "' " +
									"WHERE (ptd.Clock_out='0000-00-00 00:00:00' OR ptd.Clock_out IS NULL) AND ptd.task_status IN (8,9) AND ptd.task_type=122 AND ptd.sender_id=" + cookie.get('v-person-id') ;
									
									var dataAdapter =new DataAdapter(sql);
										dataAdapter.getRecords().then(data=>{
											addNotification("You are clocked out, Thank you for the work.", "info");
											window.location.reload();
										})
								/*		
 								if(hasClockIn){
								navigator.geolocation.getCurrentPosition(function(position) {
									setLatitude(position.coords.latitude);
									setLongitude(position.coords.longitude);
									});		
									var _UTC_ = (new Date()).toISOString().split('T')[0] + ' ' + (new Date()).toISOString().split('T')[1].slice(0, 5);
									var sql	=	"UPDATE rb_project_task_details ptd LEFT JOIN rb_person pr ON pr.person_id = ptd.sender_id SET ptd.percent_done=100, ptd.note='', ptd.Clock_out = '" + _UTC_ + "', ptd.real_hours = TIMESTAMPDIFF(MINUTE, Clock_in, '" + _UTC_ + "'), ptd.real_cost= IF(ptd.Clock_out IS NULL OR ptd.Clock_out='0000-00-00 00:00:00', '0',ROUND(TIMESTAMPDIFF(MINUTE, ptd.Clock_in, ptd.Clock_out)*pr.emp_wage_amount/60,2)), latitude_out ='" + latitude + "', longitude_out='" + longitude + "' " +
									"WHERE ptd.task_status IN (8,9) AND ptd.task_type=122 AND ptd.sender_id=" + cookie.get('v-person-id') + " ORDER BY ptd_id DESC limit 1;";
								  var dataAdapter =new DataAdapter(sql);
										dataAdapter.getRecords().then(data=>{
											addNotification("You are clocked out, Thank you for the work.", "info");
											window.location.reload();
										})
								}else{
									addNotification("You already clocked out","info");
								}
								*/
						  }}/>
						<CardBody className="p-0 mt-1">
						  <CardTitle className="text-center small"  >CLOCK OUT</CardTitle>
						</CardBody>
					  </Card>				
				</Col>
				<Col md={2} sm={4} xs={4} >
					  <Card className="p-2"  >
						<CardImg top src={iconCamera} onClick={async()=>{
							if(taskId){
								 let input = document.createElement('input');
								  input.type = 'file';
								  input.onchange = () => {
										//let files =   Array.from(input.files);
										handleAttachmentUpload(input.files, taskFolder);
									};
								  input.click();
  
							}else{								
								var sql	=	"SELECT pt.pt_id, pt.task, pt.attachments  FROM rb_project_task_details ptd INNER JOIN rb_project_tasks pt ON pt.pt_id = ptd.pt_id WHERE ptd.sender_id = " + cookie.get("v-person-id") + " ORDER BY ptd.Clock_in DESC LIMIT 1;";
								var dataAdapter =new DataAdapter(sql);
								var response	=	await dataAdapter.getRecords();
								if(response.length>0 && window.confirm("The last task you have worked on it, was #" + response[0].pt_id + " " + response[0].task + ", \n Do you want to to upload its pictures?")){
									//addNotification("To upload picture, you must specify what task you are working on","info");
									 let input = document.createElement('input');
									  input.type = 'file';
									  input.onchange = () => {
											//let files =   Array.from(input.files);
											handleAttachmentUpload(input.files, response[0].attachments);
										};
									  input.click();
								}
							}
					  }} />
						<CardBody  className="p-0 mt-1">
						  <CardTitle className="text-center small" >UPLOAD PICTURE</CardTitle>
						</CardBody>
					  </Card>					  
				</Col>
				<Col md={2} sm={4} xs={4} >
					  <Card className="p-2"  >
						<CardImg top src={iconChat} onClick={()=>{
							var _chat	=new Chat();
								_chat.openChatRooms();
							
						}} />
						<CardBody  className="p-0 mt-1">
						  <CardTitle className="text-center small" >PROJECT ROOMS</CardTitle>
						</CardBody>
					  </Card>					  
				</Col>
				{cookie.get('v-person-role')*1==11?
				<Col md={2} sm={4} xs={4} >
					  <Card className="p-2" >
						<CardImg top src={iconTask} onClick={()=>{
						  var taskDetail =new TaskDetail();
								taskDetail.openDailyTimeCard();
					  }}/>
						<CardBody  className="p-0 mt-1">
						  <CardTitle className="text-center small" >Report</CardTitle>
						</CardBody>
					  </Card>					  
				</Col>:null}
				<Col md={2} sm={4} xs={4} >
					  <Card className="p-2" >
						<CardImg top src={iconPayStub} onClick={()=>{
						  var _person =new Person();
								_person.openViewPayStub();
					  }}/>
						<CardBody  className="p-0 mt-1">
						  <CardTitle className="text-center small" >PAYSTUB</CardTitle>
						</CardBody>
					  </Card>					  
				</Col>
			</Row>
			<Row>
				<Col md={12} sm={12} xs={12} >
					{hasClockIn?
						<>
						<img src={iconClockIn} height="25" /> {cookie.get('v-name') + ' ' + cookie.get('v-lastname')} clocked in on {clockIn} at <b>{project}</b>, working <b>{duration}</b> for task <b>{task? taskId + " " + task: "..."}</b> up to now.
						</>
					:
					''}
					{longitude !=-1 && latitude !=-1?
						<>
						{jobs.length>0?
							<>You are near <b>{jobs[0].prj_address + ', ' + jobs[0].prj_city} </b>job site.</>
						:''}
					</>
					:
					''}
				</Col>
			</Row>
			<NotificationSystem ref={notificationSystem} style={NOTIFICATION_SYSTEM_STYLE}/>
        </Page>
    );

}
export default TileMenu;