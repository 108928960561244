import React,{Suspense} from "react";
import ReactDOM from 'react-dom';
import DataAdapter from "../../adapter/DataAdapter";
import Dialog from "../../component/Dialog";


class Invoice {
    constructor(){
		console.log("Invoice");
    }
	getTransByProjectId			=	async(_project, _task) =>{
        const _query    =   "SELECT * FROM rb_receive_n_payments WHERE rp_type=2 AND prj_id= " + _project + (_task? " AND pt_id = " + _task: "");
        var dataAdapter =new DataAdapter(_query);
        var data = await dataAdapter.getRecords();
        return data;		
	}
	getTransByProjectId2			=	async(_project, _task) =>{
        const _query    =   "SELECT rp.rp_id, rp.rp_date, rp.amount, rp.rp_desc, ty.rb_type_desc AS rp_cat FROM rb_receive_n_payments rp LEFT JOIN rb_types ty ON (ty.rb_type='rp_cat' AND ty.rb_type_no=rp.rp_cat) WHERE rp.rp_type=2 AND rp.prj_id= " + _project + (_task? " AND rp.pt_id = " + _task: "");
        var dataAdapter =new DataAdapter(_query);
        var data = await dataAdapter.getRecords();
        return data;		
	}
	openProvideEstimate			=	async(_project)	=>{
		const _return = await new Promise((resolve, rej) => {
			const div	=	document.createElement('div');
			document.body.appendChild(div);
			const FormEstimate = React.lazy(() => import('./FormEstimate'));
			ReactDOM.render(
				<Suspense fallback={<div>Loading... </div>}>
					<FormEstimate project={_project} callback={(obj)=>resolve(obj)} />
				</Suspense>
			, div);		
			
		});
	
		return _return;
	}

	openReceiveAndPayment		=	async(_project)	=>{
		const _return = await new Promise((resolve, rej) => {
			const div	=	document.createElement('div');
			document.body.appendChild(div);
			const FormReceive = React.lazy(() => import('./FormReceive'));
			ReactDOM.render(
				<Suspense fallback={<div>Loading... </div>}>
					<FormReceive project={_project} callback={(obj)=>resolve(obj)} />
				</Suspense>
			, div);		
			
		});
	
		return _return;
	}

	openLog		=	async(_type, _project)	=>{
		const _return = await new Promise((resolve, rej) => {
			const div	=	document.createElement('div');
			document.body.appendChild(div);
			const FormLog = React.lazy(() => import('./FormLog'));
			ReactDOM.render(
				<Suspense fallback={<div>Loading... </div>}>
					<FormLog type={_type} project={_project} callback={(obj)=>resolve(obj)} />
				</Suspense>
			, div);		
			
		});
	
		return _return;
	}

	openClock		=	async(_type, _project)	=>{
		const _return = await new Promise((resolve, rej) => {
			const div	=	document.createElement('div');
			document.body.appendChild(div);
			const FormLog = React.lazy(() => import('./FormLog'));
			ReactDOM.render(
				<Suspense fallback={<div>Loading... </div>}>
					<FormLog type={_type} project={_project} callback={(obj)=>resolve(obj)} />
				</Suspense>
			, div);		
			
		});
	
		return _return;
	}
	
	viewReceiveAndPayment		=	async(_project)	=>{
		const _return = await new Promise((resolve, rej) => {
			const div	=	document.createElement('div');
			document.body.appendChild(div);
			const ViewReceiveAndPayment = React.lazy(() => import('./ViewReceiveAndPayment'));
			//const ViewReceiveAndPayment = React.lazy(() => import('../../ViewReceiveAndPayment'));
			ReactDOM.render(
				<Suspense fallback={<div>Loading... </div>}>
					<Dialog title="" isOpen={true} isProgressing={false} notify="" >
						<ViewReceiveAndPayment project={_project} callback={(obj)=>resolve(obj)} />
					</Dialog>
				</Suspense>
			, div);		
			
		});
	
		return _return;
	}

	viewReceiveAndPaymentFilter		=	async(_projectId, _taskId)	=>{
		const _return = await new Promise((resolve, rej) => {
			const div	=	document.createElement('div');
			document.body.appendChild(div);
			const ViewReceiveAndPayment = React.lazy(() => import('./ViewReceiveAndPayment'));
			//const ViewReceiveAndPayment = React.lazy(() => import('../../ViewReceiveAndPayment'));
			ReactDOM.render(
				<Suspense fallback={<div>Loading... </div>}>
					<Dialog title="" isOpen={true} isProgressing={false} notify="" >
						<ViewReceiveAndPayment project={_projectId} callback={(obj)=>resolve(obj)} />
					</Dialog>
				</Suspense>
			, div);		
			
		});
	
		return _return;
	}

}
export default Invoice;