import React,{useState, Suspense} from "react";
import ReactDOM from 'react-dom';
import DataAdapter from "../adapter/DataAdapter";
import Dialog from "../component/Dialog";
import Base64 from 'Base64';

import { Card, CardBody, CardHeader, CardImg, CardLink, CardText, CardTitle, Col, Input, ListGroup, ListGroupItem, Row, Modal, ModalBody, ModalFooter, ModalHeader, Button, FormGroup, Label} from 'reactstrap';

class Drive {
    constructor(){

    }
    createFolder = async (_parent, _folderName) =>{

		let formData = new FormData();
			formData.append("parent", _parent);
			formData.append("name", _folderName);

		let requestOptions = {
			method: 'POST',
			headers: {
				'Authorization':'Basic ' + Base64.btoa('sizotech:IsKfs$m$SZV#4Eprtj7zSPbO')
			},
			body : formData
		};
		const response = await fetch( 'https://node.renobuilding.ca:3001' + '/folder', requestOptions)
			.then(response => {
			  return Promise.all([response.json(),response])
			})
			.then(([responseData,response]) => {
				return responseData;
				})
			.catch(error => {
				console.error('Error:', error);
			})
		return 	response;

    }
}
export default Drive;