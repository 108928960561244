import React, {useState, useEffect} from "react";
import { addDays } from 'date-fns';
import {isEqual} from '../../lib/ObjectUtil';
import Collapse from 'react-bootstrap/Collapse';

import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledButtonDropdown,
} from 'reactstrap';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';

import iconLoading from './loading.gif';
import iconOpen from 'assets/img/icon/openmenu.png';
import iconClose from 'assets/img/icon/closemenu.png';
import iconDelete from 'assets/img/icon/rubbish-bin.png';
import iconEdit from 'assets/img/icon/edit.png';
import iconAdd from 'assets/img/icon/add.png';
import iconCopy from 'assets/img/icon/copy.png';
import iconSubmmit from 'assets/img/icon/checkmark.png';
import iconAttachment from 'assets/img/icon/attachment.png';

import ComboBox from "./ComboBox";
//import {GridSub} from "./Grid";
import {GridSub} from "./GridSub";
import DataAdapter from "../../adapter/DataAdapter";

import DateTime from "../../lib/DateTime";

import './Grid.css';

var _filterValues		=	[];

export const Grid = (props) => {	
    const [dataSource, setDataSource]	= useState({});
    const [detailDataSource, setDetailDataSource]	= useState({});
    const [config, setConfig] 				= useState({});
    const [detailConfig, setDetailConfig]	= useState({});
	
    const [primaryKey, setPrimaryKey] = useState('');
    const [recordsOriginal, setRecordsOriginal] = useState([]);
    const [records, setRecords] = useState(null);
    const [columns, setColumns] = useState([]);
    const [fieldDataSource, setFieldDataSource] = useState([]);
    const [recordsDetail, setRecordsDetail] = useState(null);
    const [recordsClock, setRecordsClock] = useState(null);
    const [header, setHeader] = useState([]);
    const [hasRowNo, setHasRowNo] = useState(false);
    const [hasFilter, setHasFilter] = useState(false);
    const [hasPager, setHasPager] = useState(true);
    const [pageSize, setPageSize] 		= useState(50);
    const [currentPage, setCurrentPage] = useState(1);
    const [group, setGroup] = useState([]);
    const [filterValues, setFilterValues] = useState([]);
    const [aggregations, setAggregations] = useState([]);
    const [aggregation, setAggregation] = useState([]);
    const [aggregationIndex, setAggregationIndex] = useState(0);
    const [loading, setLoading] = useState(true);
    const [hasHeader, setHasHeader] = useState(true);
    const [canDelete, setCanDelete] = useState(false);
    const [canUpdate, setCanUpdate] 	= useState(false);
    const [canInsert, setCanInsert] 	= useState(false);
    const [canCopy, setCanCopy] 		= useState(false);
    const [isCollaps, setIsCollaps] = useState(props.collaps);
    const [isOpen, setIsOpen] = useState(new Array(1000).fill(false));
    const [rowClass, setRowClass] = useState('');
    const [actions, setActions] = useState([]);

    const [beforUpdateRecord, setBeforUpdateRecord] = useState({});
    const [newRecord, setNewRecord] = useState([]);
    const [initialized, setInitialized] = useState(true);
	
	const [selectionRange, setSelectionRange] = useState([
	  {
		startDate	:	new Date(),
		endDate		:	addDays(new Date(), 7),
		key			:	'selection'
	  }
	]);
	

	
	var _aggregation			=	[];
	var _aggregationFunction	=	[];
	var _aggregationIndex		=	[];

	var _props					=	[];

	useEffect(() => {
		if(props.config  && !isEqual(props.config, config)){
				setPrimaryKey(props.config.primaryKey);
				setColumns(props.config.columns);
				setRowClass(props.config.rowClass);
				
				async function fetchFieldDataSource(){
					var _fieldDataSource	=	[];
					for(var i=0; i< props.config.columns.length; i++){
						if(props.config.columns[i].type=='select'){
							var _query			=	props.config.columns[i].queryString;
							var dataAdapter		=	new DataAdapter(_query);
							var _response		=	await dataAdapter.getRecords();
							_fieldDataSource[i] = {
								type		:	'json',
								data		:	_response,
								valueField	:	props.config.columns[i].valueField,
								displayField:	props.config.columns[i].displayField,
							};
						}
					}
					setFieldDataSource(_fieldDataSource);
				}
				fetchFieldDataSource();			
		}
		if(props.detailConfig  && !isEqual(props.detailConfig, detailConfig)){
			setDetailConfig(props.detailConfig);
		}
		if(props.config  && props.config.aggregations != aggregations){
			setAggregations(props.config.aggregations);
		}
		if(props.config  && props.config.actions != actions){
			setActions(props.config.actions);
		}
		if(props.config  && props.config.hasFilter != hasFilter){
			setHasFilter(props.config.hasFilter);
		}
		if(props.config  && props.config.hasPager != hasPager){
			setHasPager(props.config.hasPager);
			if(!props.config.hasPager)setPageSize(100);
		}	
		if(props.config  && props.config.canDelete != canDelete){
			setCanDelete(props.config.canDelete);
		}
		if(props.config  && props.config.canUpdate != canUpdate){
			setCanUpdate(props.config.canUpdate);
		}		
		if(props.config  && props.config.canInsert != canInsert){
			setCanInsert(props.config.canInsert);
		}		
		if(props.config  && props.config.canCopy != canCopy){
			setCanCopy(props.config.canCopy);
		}		
		if(props.config  && props.config.group != group){
			setGroup(props.config.group);
		}
		if(props.dataSource  && !isEqual(props.dataSource, dataSource)){
			setDataSource(props.dataSource);
			setHeader(props.dataSource.fields);
			async function fetchData(){
				var _records	= await props.dataSource.getRecords();
				setRecordsOriginal(_records);
				setRecords(_records);
				setLoading(true);
				
				if(_records && _records.length<50)
					setPageSize(_records.length);
				
			}
			fetchData();
		}
		if(props.detailDataSource  && !isEqual(props.detailDataSource, detailDataSource)){
			setDetailDataSource(props.detailDataSource);
			async function fetchData(){
				var _records	= await props.detailDataSource.getRecords();
				//setRecordsDetail([{details : _records}, {details : _records}]);
				setRecordsDetail([{details : _records}]);
			}
			fetchData();
		}
		if(props.isSub){
				setRecordsOriginal(props.records);
				setRecords(props.records);
				if(props.records && props.records.length<50)
					setPageSize(props.records.length);
		}
    }, [props]);
	useEffect(() => {
		props.callback(_props);
	},[records]);
	const onDeleteHandler	= async (e, _primaryKey, _primaryValue) =>{
		/*
		var _recordsOriginal 	=	recordsOriginal;
		var indexOriginal				=	_recordsOriginal.findIndex(r=>r[_primaryKey]==_primaryValue);
		_recordsOriginal.splice(indexOriginal, 1);
		setRecordsOriginal(_recordsOriginal);
		*/
		var _recordsOriginal 	=	recordsOriginal;
		var _records			=	records;
		var indexOriginal		=	_recordsOriginal.findIndex(r=>r[_primaryKey]==_primaryValue);
		var index				=	_records.findIndex(r=>r[_primaryKey]==_primaryValue);
		if(window.confirm("Are you sure to delete the item #" + (index+1))){
			_recordsOriginal.splice(indexOriginal, 1);
			setRecordsOriginal(_recordsOriginal);
			
			_records.splice(index, 1);
			setRecords(_records);			
			setLoading(true);
			
			var _result = await dataSource.onDelete(e, _primaryKey, _primaryValue);
			props.callback({...{callbackType : 'notification'},...{response : [{message:'Record has been Deleted'}]}, ...{action : ''}});
		}
		
	}
	const onUpdateButtonClickHandler	=	async(e, _primaryKey, _record) =>{
		var _active	=	false;
		if(e.target.className != 'active'){
			e.target.className	=	"active";
			_active	=	true;
		}else{
			e.target.className	=	"";
			_active	=	false;
		}
		
		e.target.parentElement.parentElement.childNodes.forEach((el)=>{
			if(el.childNodes[0]){
				if(el.childNodes[0].nodeName!='IMG')
					if(_active)el.childNodes[0].className +=" update"; else el.childNodes[0].className = el.childNodes[0].className.replace("update","");
			}
		});
		
	}
	const onUpdateHandler	= async (e, _primaryKey, _primaryValue, _field, _value) =>{
		if(_primaryValue != ''){
			var prepareUpdate = dataSource.prepareUpdate.filter(pu=>pu.field==_field);
			
			if(prepareUpdate.length>0){
				var _record	=	records.filter(r=>r[_primaryKey]==_primaryValue)[0];
				var _parameters	=	[];
				prepareUpdate[0].parameters.map(p=>{ _parameters.push(_record[p]);})
				_value = prepareUpdate[0].prepare(_parameters);	
			}
			
			await dataSource.onUpdate(e, _primaryKey, _primaryValue, _field, _value, records.filter(r=>r[_primaryKey]==_primaryValue)[0]);
			props.callback({...{callbackType : 'notification'},...{response : [{message:'Record has been updated'}]}, ...{action : ''}});
			
		}
	}
	const onInsertHandler	= async (e, _primaryKey, _record) =>{
		console.log(e);
		dataSource.prepareInsert.map(pi=>{
			var _parameters	=	[];
			pi.parameters.map(p=>{ _parameters.push(_record[p]);})
			_record[pi.field] = pi.prepare(_parameters);	
		});
		
		columns.map(c=>{
			if(c.type=='formula')
				delete _record[c.id];
		});
		
		var _primaryValue = await dataSource.onInsert(e, _primaryKey, _record);
		_record[_primaryKey] = _primaryValue[0][_primaryKey];
		var _active	=	false;
		if(e.target.className != 'active'){
			e.target.className	=	"active";
			_active	=	true;
		}else{
			e.target.className	=	"";
			_active	=	false;
		}
		
		e.target.parentElement.parentElement.childNodes.forEach((el)=>{
			if(el.childNodes[0]){
				if(el.childNodes[0].nodeName!='IMG')
					if(_active)el.childNodes[0].className +=" update"; else el.childNodes[0].className = el.childNodes[0].className.replace("update","");
			}
		});
		//alert('Record has been inserted');
	}
	const onCopyHandler	= async (e, _primaryKey, _record) =>{
		var _recordtemp	=	JSON.parse(JSON.stringify(_record));
		
		var _records			=	records;
		var index				=	_records.findIndex(r=>r[_primaryKey]==_recordtemp[_primaryKey]);
		if(window.confirm("Are you sure to duplicate the item #" + (index+1))){
			if(dataSource.prepareCopy){
				dataSource.prepareCopy.map(pc=>{
					var _parameters	=	[];
					pc.parameters.map(p=>{ _parameters.push(_record[p]);})
					_record[pc.field] = pc.prepare(_parameters);	
				});
			}
			
			var _response = await dataSource.onCopy(e, _primaryKey, _recordtemp);
			_recordtemp[_primaryKey]	=	_response[0][_primaryKey];
			_records = [
				..._records.slice(0, index+1),
					_recordtemp,
				..._records.slice(index+1)
			];
			_records.fill(_recordtemp, index+1, index+1);
						
			setRecordsOriginal(_records);
			setRecords(_records);
			setLoading(!loading);
		}
	}

    const _getHeader  = (props) =>{
        var _header		= [];
        var _columns	= [];
		if(props.columns){
			props.columns.map(c=>_header.push(c.id));
		}
        else if(props.records[0]){
            _header= Object.keys(props.records[0]);
		}
		
        return _header;
    }
    const calculateAggregation=(_values)=>{
		var _a=[];
		var dateTime =new DateTime();
		if(loading && _values.length>0){		
			_aggregationIndex.map(_index=>{
					var _value 		= _values.filter(v=>v[0]==_index && v[1]);
					var _function	= _aggregationFunction.filter(v=>v[0]==_index)[0];
					if(_function[1] == 'sumtime'){
						const initialValue = '00:00';
						const sumWithInitial = _value.reduce(
						  (accumulator, currentValue) => dateTime.formatTime(dateTime.timestrToSec(accumulator) + dateTime.timestrToSec(currentValue[1])),
						  initialValue
						);
						
						if(sumWithInitial!='00:00'){
							setAggregationIndex(_index -1);
							_a=[..._a];
							_a[_index -1]=sumWithInitial;
						}
					}
					if(_function[1] == 'sum'){
						const initialValue = 0;
						const sumWithInitial = _value.reduce(
						  (accumulator, currentValue) => accumulator + currentValue[1]*1,
						  initialValue
						);
						
						if(sumWithInitial*1!=0){
							setAggregationIndex(_index -1);
							_a=[..._a];
							_a[_index -1]=sumWithInitial.toFixed(1);
						}
					}
					if(_function[1] == 'avg'){
						const 	initialValue 	= 0;
						var 	countValue 		= 0;
						const sumWithInitial = _value.reduce(
						  (accumulator, currentValue) => {if(currentValue[1]*1)countValue++; return accumulator + currentValue[1]*1;},
						  initialValue
						);
						
						if(sumWithInitial*1!=0){
							setAggregationIndex(_index -1);
							_a=[..._a];
							_a[_index -1]=(sumWithInitial/countValue).toFixed(1);
						}
					}
				
			});
			
			
			setAggregation(_a);
			setLoading(false);
		}
	}
	const updateItem = (_index, _field, _value) => {
		  if (_index !== -1) {
			let temporaryarrayOriginal	= recordsOriginal.slice();
			let temporaryarray 			= records.slice();
			temporaryarrayOriginal[_index][_field] 	= _value;
			temporaryarray[_index][_field] 			= _value;
			setRecordsOriginal(temporaryarrayOriginal);
			setRecords(temporaryarray);
		  } else {
			console.log('no match');
		  }
  }
  
	const numberWithCommas = (x) => {
		return (x?x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","):'');
	}
	const maskPhone = (p) => {
		return (p ? "(" + p.substring(0, 3) + ") " + p.substring(3, 6) + " - " + p.substring(6, 10) : "");
	}

	if(records)
	return(
		<>
			<div className="grid-wrapper" >
				<table className={"grid " + (props.className? props.className: "")} >
				<thead className="">
				{hasHeader?
					<tr>
						{recordsDetail?
						<th style={{width:'25px'}} ></th>:null}
						<th style={{width:'25px'}} ></th>
						{canUpdate?
						<th style={{width:'25px'}} ></th>:null}
						{canCopy?
						<th style={{width:'25px'}} ></th>:null}
						{canDelete?
						<th style={{width:'25px'}} ></th>:null}
						{hasRowNo?
						<th style={{width:'25px'}} >#</th>:null}
						{columns.map((column, columnKey)=>{
							if(!column.hidden)
							return (
								<th className={column.class? column.class: ""} style={{width:column.width}}  scope="col"  key={columnKey}>{column.caption? column.caption: column.id}</th>
							)
						})}
						{actions && actions.length>0?
						actions.map((action)=>{
							return(
							<th style={{width:'25px', cursor: 'pointer'}} ></th>								)
						})
						:null}						
					</tr>
				:null}
				{hasFilter?
					<tr>
						{recordsDetail?
						<th style={{width:'25px'}} ></th>:null}
						<th style={{width:'25px'}} ></th>
						{canUpdate?
						<th style={{width:'25px'}} ></th>:null}
						{canCopy?
						<th style={{width:'25px'}} ></th>:null}
						{canDelete?
						<th style={{width:'25px'}} ></th>:null}
						{hasRowNo? <th style={{width:'25px'}} ></th>:null}
						{columns.map((h,hKey)=>{
							if(!h.hidden){
								if(h.type=='checkbox' && h.canFilter){
									return (
										<th  scope="col"  key={hKey}>
										</th>
									)
								}
								if(h.type=='text' && h.canFilter){
									return (
										<th  scope="col"  key={hKey}>
											<input className="update" type="text" onKeyDown={(e)=>{
												e.persist();
												console.log(e)
												if(e.key == 'Enter' && e.target.value != ''){
													let temporaryarray = records;
													temporaryarray = temporaryarray.filter(t=>t[h.id] && t[h.id].toLowerCase().indexOf(e.target.value)!=-1);
													//temporaryarray = temporaryarray.filter(t=>t[h.id] && t[h.id].toLowerCase() == e.target.value);
													setRecords(temporaryarray);
													setLoading(true);
												}else if(e.key == 'Escape'){
													let temporaryarray = recordsOriginal;
													setRecords(temporaryarray);
													e.target.value	=	'';
												}
											}} />
										</th>
									)
								}
								if(h.type=='select' && h.canFilter){
									return (
										<th  scope="col"  key={hKey}>
											<ComboBox className="update" id="select_filter" onChange={(e)=>{
												e.persist(); 
												if(e.target.value != '' && e.target.value !=-1){
													let temporaryarray = records;
													temporaryarray = temporaryarray.filter(t=>t[h.id] && t[h.id] == e.target.value);
													setRecords(temporaryarray);
													_filterValues=[..._filterValues];
													_filterValues[hKey] = e.target.value;
													setLoading(true);
												}else{
													let temporaryarray = recordsOriginal;
													setRecords(temporaryarray);
													setLoading(true);
												}
												}}  
												dataSource={fieldDataSource[hKey]}
												queryString={h.queryString} valueField={h.valueField} displayField={h.displayField} />									
										</th>
									)
								}
								if(h.type=='date' && h.canFilter){
									return (
										<th  scope="col"  key={hKey}>
										  <UncontrolledButtonDropdown className=""  >
											<DropdownToggle caret color="light" >
											 Date Range
											</DropdownToggle>
											<DropdownMenu>
											  <DropdownItem header >
													<DateRangePicker 
													  onChange={item => {
														let temporaryarray = recordsOriginal;
														temporaryarray = temporaryarray.filter(t=>t[h.id] && t[h.id]>=item.selection.startDate.toISOString().split('T')[0] && t[h.id]<=item.selection.endDate.toISOString().split('T')[0]);
														setRecords(temporaryarray);
														setSelectionRange([item.selection]);
														setLoading(true);
													  }}
													  showSelectionPreview={true}
													  moveRangeOnFirstSelection={false}
													  ranges={selectionRange}
													  
													/>
											  
											  </DropdownItem>
											</DropdownMenu>
										  </UncontrolledButtonDropdown>
										
										</th>
									)
								}
								if(h.type=='time' && h.canFilter){
									return (
										<th  scope="col"  key={hKey}>
											<input className="" type="time" onBlur={(e)=>{
												if(e.target.value != ''){
													let temporaryarray = records;
													temporaryarray = temporaryarray.filter(t=>t[h.id] && t[h.id].toLowerCase().indexOf(e.target.value)!=-1);
													setRecords(temporaryarray);
													setLoading(true);
												}else{
													let temporaryarray = recordsOriginal;
													setRecords(temporaryarray);
												}
											}} />
										</th>
									)
								}
								if(h.type=='image' && h.canFilter){
									return (
										<th  scope="col"  key={hKey}>
										</th>
									)
								}
								if(!h.type || h.type=='' || h.type=='formula' || !h.canFilter){
									return (
										<th  scope="col"  key={hKey}>
										</th>
									)
								}
							}
						})}
						{actions && actions.length>0?
						actions.map((action)=>{
							return(
							<th style={{width:'25px', cursor: 'pointer'}} ></th>								)
						})
						:null}						
					</tr>
				:null}
				</thead>
				<tbody>
				
				{records?records.map((record,i)=>{
					if(i>=(currentPage -1)*pageSize && i<currentPage*pageSize || !hasPager){
					return(
					<>
						<tr key={i} id={group?record[group[0].parent]:''} className={eval(rowClass) + (record[primaryKey]==''? ' table-info':'') + (group?(records.findIndex(r=>r[primaryKey]==record[group[0].parent])>0? ' d-none': ' '):'')} >
							{recordsDetail?
							<td>
								<img onClick={(e)=>{var _isOpen = isOpen.map((c,y)=>{if(y==i)return !c; else return c;}); setIsOpen(_isOpen); }} src={isOpen[i]? iconClose : iconOpen} height={16} />
							</td>
							:null}
							<td>
								{group?eval(group[0].by)?
									<img onClick={(e)=>{var _isOpen = isOpen.map((c,y)=>{if(y==i)return !c; else return c;}); setIsOpen(_isOpen);if(_isOpen[i]){document.querySelectorAll("[id='" + record[primaryKey] + "']").forEach(e=>e.classList.remove("d-none"));}else{document.querySelectorAll("[id='" + record[primaryKey] + "']").forEach(e=>e.classList.add("d-none"));}}} src={isOpen[i]? iconClose : iconOpen} height={16} />
								:null:null}
							</td>
							{canUpdate?
							<th scope="row"><img height={16} src={records[i][primaryKey] || true? iconEdit : iconSubmmit} style={{cursor:'pointer'}} onClick={(e)=>{e.persist(); onUpdateButtonClickHandler(e, primaryKey, record);}} /></th>:null}
							{canCopy?
							<th scope="row"><img height={16} src={iconCopy} style={{cursor:'pointer'}} onClick={(e)=>{onCopyHandler(e, primaryKey, record)}}/></th>:null}
							{canDelete?
							<th scope="row"><img height={16} src={iconDelete} style={{cursor:'pointer'}} onClick={(e)=>{onDeleteHandler(e, primaryKey, record[primaryKey])}}/></th>:null}
							{hasRowNo?
							<td scope="row" >{i+1}</td>:null}
							{columns.map((column,j)=>{
								if(aggregations && aggregations.findIndex(a=>a.field==column.id) != -1){
									_aggregation.push([j,record[column.id]]);
									_aggregationFunction.push([j, aggregations.filter(a=>a.field==column.id)[0].func]);
									_aggregationIndex.push(j);
								}
								if(!column.hidden)
								return (
									<td key={j} style={{padding:'0px !important', cursor: (column.action?'pointer':'default'), textDecoration: (column.action?'underline':'auto') }} onClick={()=>{if(column.action){column.action(records[i]);}}}  >
										{column.type=='image'?
											record[column.id] && record[column.id]!=0?
												<a target="_blank" href={'https://lh3.googleusercontent.com/d/' + record[column.id] + '?authuser=1/view'} ><img className="" height={16} src={iconAttachment} /></a>
											:
											<label htmlFor={"formFileMultiple"  + records[i][primaryKey]}>
												<img src={iconAdd}  height={16}/>
												<input className="" style={{display: 'none'}}  type="file" id={"formFileMultiple"  + records[i][primaryKey]} onChange={(e)=>{e.persist(); updateItem(i, column.id, e.target.value); onUpdateHandler(e,primaryKey,records[i][primaryKey],column.id,e.target.value);}} accept="image/*" multiple data-original-title="upload photos" />
											</label>
											:null}
										{column.type=='text'?
											<input disabled={canUpdate?"":"true"} type="text" onChange={(e)=>{e.persist(); updateItem(i, column.id, e.target.value); setLoading(true); }} onBlur={(e)=>onUpdateHandler(e,primaryKey,records[i][primaryKey],column.id,e.target.value)} value={record[column.id]} title={record[column.id]}  className="" style={{fontSize:'11px', width: column.width}}  />
											:null}
										{column.type=='textarea'?
											<textarea disabled={canUpdate?"":"true"} onChange={(e)=>{e.persist(); updateItem(i, column.id, e.target.value); setLoading(true); }} onBlur={(e)=>onUpdateHandler(e,primaryKey,records[i][primaryKey],column.id,e.target.value)} title={record[column.id]}  className="" style={{fontSize:'11px'}} >{record[column.id]}</textarea>
												:null}
										{column.type=='select'?
											<ComboBox disabled={canUpdate?"":"true"} id={'select_' + column.id} onChange={(e)=>{e.persist(); updateItem(i, column.id, e.target.value); setLoading(true); onUpdateHandler(e,primaryKey,records[i][primaryKey],column.id,e.target.value);}}  
											dataSource={fieldDataSource[j]}
											queryString={column.queryString} valueField={column.valueField} displayField={column.displayField} defaultValue={record[column.id]} title={record[column.id]} className="" style={{fontSize:'11px'}} />
											:null}
										{column.type=='checkbox'?
											<input disabled={canUpdate?"":"true"} type="checkbox" onChange={(e)=>{e.persist(); updateItem(i, column.id, e.target.checked); setLoading(true); onUpdateHandler(e,primaryKey,records[i][primaryKey],column.id,e.target.checked?1:0);}}  checked={record[column.id]==1} className="" />
											:null}
										{column.type=='date'?
											<input disabled={canUpdate?"":"true"}  type="date" onChange={(e)=>{e.persist(); updateItem(i, column.id, e.target.value); setLoading(true); }} onBlur={(e)=>{onUpdateHandler(e,primaryKey,records[i][primaryKey],column.id,e.target.value)}} value={record[column.id]} className="" style={{fontSize:'11px'}}  />
											:null}
										{column.type=='time'?
											<input disabled={canUpdate?"":"true"} type="time" onChange={(e)=>{e.persist(); updateItem(i, column.id, e.target.value); setLoading(true); }} onBlur={(e)=>{onUpdateHandler(e,primaryKey,records[i][primaryKey],column.id,e.target.value)}} value={record[column.id]} className="" style={{fontSize:'11px'}}  />
											:null}
										{column.type=='datetime'?
											<input disabled={canUpdate?"":"true"} type="datetime" onChange={(e)=>{e.persist(); updateItem(i, column.id, e.target.value); setLoading(true); }} onBlur={(e)=>{onUpdateHandler(e,primaryKey,records[i][primaryKey],column.id,e.target.value)}} value={record[column.id]} className="" style={{fontSize:'11px'}}  />
											:null}
										{column.type=='phone'?
											<input disabled={canUpdate?"":"true"} type="text" onChange={(e)=>{e.persist(); updateItem(i, column.id, e.target.value); setLoading(true); }} onBlur={(e)=>onUpdateHandler(e,primaryKey,records[i][primaryKey],column.id,e.target.value)} value={maskPhone(record[column.id])} title={maskPhone(record[column.id])}  className="" style={{fontSize:'11px', width: column.width}}  />
											:null}
										{column.type=='formula'?
												eval(column.formula)
											:null}
										{!column.type?
											(column.prefix? column.prefix : '') + record[column.id]
											:null}
									</td>
								)
							})}
							{actions && actions.length>0?
							actions.map((action)=>{
								return(
								<th style={{width:'25px', cursor: 'pointer'}} ><a onClick={(e)=>{e.persist();action.callback(record);}} >{action.caption}</a></th>								)
							})
							:null}						
							</tr>
						{recordsDetail?
					  <Collapse in={isOpen[i]}>
						<tr>
							<td colSpan={columns.filter(c=>!c.hidden).length+4} className="" >
								{recordsDetail.map((detail, gridKey)=>{
									return(
										<div class="col-12">
										{/*
											<GridSub key={gridKey} className="table-sm" records={detail.details.filter(rd=>rd[primaryKey]==record[primaryKey])} hasHeader={true} collaps={false} />								
											*/}
											<Grid isSub={true} key={gridKey} config={detailConfig} records={detail.details.filter(rd=>rd[primaryKey]==record[primaryKey])} callback={(response)=>{console.log(response);}} />	
										</div>
									)
								})}
							</td>
						</tr>
					  </Collapse>
						:null}
					 </>
					)	
					}
					}):''
				}
				
				</tbody>
					<tfoot className="" >
						<tr>
							{recordsDetail?
							<th></th>:null}
							<th>
							{canInsert?
								<img height={16} src={iconAdd} style={{cursor:'pointer'}} onClick={(e)=>{
									e.persist();
									var _newRecords = {};
									columns.map((c,i)=>{if(_filterValues[i])_newRecords[c.id]=_filterValues[i]; else if(c.defaultValue=='same') _newRecords[c.id] = records[0][c.id]; else _newRecords[c.id]= (c.defaultValue?c.defaultValue:'');})
									var _records	=	JSON.parse(JSON.stringify(records));
										_records.push(_newRecords);
									//setNewRecord(_newRecords);
									setRecordsOriginal(_records);
									setRecords(_records);
									setCurrentPage(Math.ceil(_records.length/pageSize));
									onInsertHandler(e, primaryKey, _newRecords);
							}} />:''}
							</th>
							{canUpdate?
							<th></th>:null}
							{canDelete?
							<th></th>:null}
							{canCopy?
							<th></th>:null}
							{columns.map((h,i)=>{
								if(!h.hidden)
								{
									_props.push({"id" : h.id, "value" : aggregation[i-1]});
									return (
										<th key={i} >{h.prefix}{numberWithCommas(aggregation[i-1])}</th>
									)
								}
							})}
							{actions && actions.length>0?
							actions.map((action)=>{
								return(
								<th style={{width:'25px', cursor: 'pointer'}} ></th>								)
							})
							:null}						
						</tr>
						{hasPager?
						<tr>
							<th colSpan={columns.filter(c=>!c.hidden).length+4} >
								<input title="First" type="button" value="<<" onClick={(e)=>{e.persist();setCurrentPage(1);	setLoading(true);}} disabled={currentPage==1? true: false} />
								<input title="previous" type="button" value="<" onClick={(e)=>{e.persist();setCurrentPage(currentPage*1-1);setLoading(true);}} disabled={currentPage==1? true: false} />
								<input title="Current" type="text" size="1" value={currentPage} onChange={(e)=>{e.persist(); setCurrentPage(e.target.value);setLoading(true);}} style={{textAlign: 'center'}} />
								<input title="Next" type="button" value=">" onClick={(e)=>{e.persist();setCurrentPage(currentPage*1+1);setLoading(true);}} disabled={currentPage==Math.ceil(records.length/pageSize)? true: false} />
								<input title="Last" type="button" value=">>" onClick={(e)=>{e.persist();setCurrentPage(Math.ceil(records.length/pageSize));setLoading(true);}} disabled={currentPage==Math.ceil(records.length/pageSize)? true: false} />
								<span className="" > / {Math.ceil(records.length/pageSize)}</span>
								<input className="" title="Page Size" type="text" size="1" value={pageSize} onChange={(e)=>{e.persist();setPageSize(e.target.value);setLoading(true);}} style={{textAlign: 'center'}} />
							</th>
						</tr>
						:''}
					</tfoot>
				</table>
				{ calculateAggregation(_aggregation)}
			</div>
			<div className="grid-spinner" ><img src={iconLoading} /></div>
		</>
	)
	else
		return (<><div className="" >No data to display</div></>)
}

/*
const _dataSource = {
	type 		: 	'table',
	table		:	'',
	fields		:	[],
	getColumns	:	function(){
						var _columns	= [];
						this.fields.map(f=>_columns.push({id:f}));
						return _columns;
					},
	getRecords	:	async function(){
						var dataAdapter =new DataAdapter("");
						return await dataAdapter.getRecords();
					},
	prepareUpdate:	[],
	onUpdate	:	async function(e, _primaryKey, _primaryValue, _field, _value){},
	prepareInsert:	[],
	onInsert	:	async function(e, _primaryKey, _record){
						var _sql	=	"";
						var _fields	=	"";
						var _values	=	"";
						Object.keys(_record).forEach((_key)=>{
							if(_key != _primaryKey){
								_fields += 	_key + ",";
								_values +=	"'" + _record[_key] + "',";
							}
						})
						_sql = "INSERT INTO " + this.table + " (" + _fields.slice(0, -1) + ") VALUES (" + _values.slice(0, -1) + ");";
						var dataAdapter =new DataAdapter(_sql + "\r\nSELECT " + _primaryKey + " FROM " + this.table + " ORDER BY " + _primaryKey + " DESC LIMIT 1;");
						return await dataAdapter.getRecords();
					},
	onCopy	:	async function(e, _primaryKey, _record){
						var _sql	=	"";
						var _fields	=	"";
						var _values	=	"";
						Object.keys(_record).forEach((_key)=>{
							if(_key != _primaryKey){
								_fields += 	_key + ",";
								_values +=	"'" + _record[_key] + "',";
							}
						})
						_sql = "INSERT INTO " + this.table + " (" + _fields.slice(0, -1) + ") VALUES (" + _values.slice(0, -1) + ");";
						
						var dataAdapter =new DataAdapter(_sql + "\r\nSELECT " + _primaryKey + " FROM " + this.table + " ORDER BY " + _primaryKey + " DESC LIMIT 1;");
						return await dataAdapter.getRecords();
						
					},
	onDelete	:	async function(e, _primaryKey, _primaryValue){
						if(_primaryValue){
							var dataAdapter =new DataAdapter("DELETE FROM " + this.table + " WHERE " + _primaryKey + " = '" + _primaryValue + "';");
							return await dataAdapter.getRecords();
						}else
						{
						}
					}
};
const _gridConfig	=	{
	primaryKey	:	'',
	hasFilter	:	true,
	canDelete	:	true,
	canUpdate	:	true,
	canInsert	:	true,
	canCopy		:	true,
	aggregations:	[{field:'',func:'[sum|sumhours|avg]'}],
	columns		: [],
	rowClass	:	"",

};

*/

