// Assets
import iconPhone from 'assets/img/icon/phone-color.png';
import iconEmail from 'assets/img/icon/email-color.png';
import iconT from 'assets/img/icon/letter-t.png';
import iconH from 'assets/img/icon/letter-h.png';
import iconThumbsUp from 'assets/img/icon/thumbs-up.png';
import iconHourGlass from 'assets/img/icon/hour-glass.png';
import iconClockIn from 'assets/img/icon/clock-in.png';
import iconClockOut from 'assets/img/icon/clock-out.png';
import iconRubbishBin from 'assets/img/icon/rubbish-bin.png';
import iconHightPerformance from 'assets/img/icon/triangle-green.png';
import iconMiddlePerformance from 'assets/img/icon/triangle-yellow.png';
import iconLowPerformance from 'assets/img/icon/triangle-red.png';
import iconDuration from 'assets/img/icon/duration.png';
import iconDeadline from 'assets/img/icon/deadline.png';
import iconLocation from 'assets/img/icon/location.png';
import iconSchedule from 'assets/img/icon/schedule.png';
import iconDrawing from 'assets/img/icon/design.png';
import iconPermit from 'assets/img/icon/permit.png';
import iconModel from 'assets/img/icon/hasmodel.png';
import iconAdd from 'assets/img/icon/add.png';
import iconAddNote from 'assets/img/icon/addnote.png';
import iconDays from 'assets/img/icon/days.png';
import iconOpenMenu from 'assets/img/icon/openmenu.png';
import iconCloseMenu from 'assets/img/icon/closemenu.png';
import iconProvideEstimate from 'assets/img/icon/provicequote.png';
import iconPayment from 'assets/img/icon/payment.png';
import iconAlarm from 'assets/img/icon/alarm.png';
import iconCamera from 'assets/img/icon/camera.png';
import iconExchange from 'assets/img/icon/exchange.png';
import iconClock from 'assets/img/icon/clock.png';
import iconPurchase from 'assets/img/icon/purchase.png';
import iconMenu		 from 'assets/img/icon/menu.png';

import React, {useState} from 'react';
import {Progress,Input, Modal, ModalBody, ModalFooter, ModalHeader, Badge, Button, ButtonGroup, ButtonToolbar, Card, CardBody, CardHeader, ListGroup, ListGroupItem, CardLink,CardText,CardTitle, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledButtonDropdown, CardFooter } from 'reactstrap';
import Accordion from 'react-bootstrap/Accordion';
import LayoutUtil from '../lib/LayoutUtil';
import Avatar from '../../components/Avatar';
import withBadge from '../../hocs/withBadge';
import { Cookies } from 'react-cookie';

import Task		from "../model/Tasks/Task";
import TaskDetail		from "../model/TaskDetails/TaskDetail";
import Invoice		from "../model/Invoices/Invoice";

const colorMap = {};
const selectedColors = {};
const cookie =new Cookies();

const isNumber = function isNumber(value){
   return typeof value === 'number' && isFinite(value) && value != 'null';
}

const numberWithCommas = (x) => {
    return (x?x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","):'');
}

const generateColor = (alfa) => {
  let randomColorString = "#";
  const arrayOfColorFunctions = "0123456789abcdef";
  for (let x = 0; x < 6; x++) {
    let index = Math.floor(Math.random() * 16);
    let value = arrayOfColorFunctions[index];

    randomColorString += value;
  }
  return randomColorString + alfa;
};

const newColorFind = (id, alfa) => {
  // If already generated and assigned, return
  if (colorMap[id]) return colorMap[id];

  // Generate new random color
  let newColor;

  do {
    newColor = generateColor(alfa);
  } while(selectedColors[newColor]);

  // Found a new random, unassigned color
  colorMap[id] = newColor;
  selectedColors[newColor] = true;

  // Return next new color
  return newColor;
}

const hasAccess = (__type) => {
	var _result	= false;
	var person_role	=	cookie.get('v-person-role');
		if (person_role * 1 === 0) { //ADMIN
			if(__type=='budget'){
				_result	=	true;
			}
		}
		if (person_role * 1 === 1 || person_role * 1 === 11) { //MANAGER & PM
			if(__type=='budget'){
				_result	=	true;
			}
		}
		if (person_role * 1 === 2) { //Marketting Specialist
		}
		if (person_role * 1 === 3) { //EMPLOYEE
		}
		if (person_role * 1 === 5) { //Verifier
		}
		if (person_role * 1 === 6) { //Staff
		}
		if (person_role * 1 === 7) { //TRADE PERSON
		}
		if (person_role * 1 === 8) { //CLIENT
		}
		if (person_role * 1 === 9) { //GUEST
		}
	
	return _result;
}

export const Filter		= (props) =>{
	var projects = props.projects;
	var tempValue	=	[];
	var displayField	=	props.displayField;
	var valueField		=	props.valueField;
	if(!projects)
		return null;
	return(
		<>
			<select id={props.valueField} onChange={(e)=>props.onFilterChange(e)} value={props.defaultValue} className="w-100" >
				<option value="-1" style={{color:'gray'}} > {props.text}</option>
				{projects.map(project=>{
					return(
						<>
							{ tempValue.findIndex((v)=> v==project[displayField]) == -1 && props.filter.findIndex(i=>i==project["prj_status_id"]*1) !=-1?
								<>
									<option value={project[valueField]} >{project[displayField]}</option>
									{tempValue.push(project[displayField]) }
								</>
							:''}
						</>
					)
				})}
			</select>
		</>
	);
}

export const Grid		= (props) =>{
	var projects	=	props.projects;
	projects = projects.sort((a,b) => a[props.colId] - b[props.colId])
	var tempValue	=	[];
	return (
		<>
			<Row>
				{projects.map(project=>{							
					return(
						<>
							{ tempValue.findIndex((v)=> v==project[props.col]) == -1 ?
								<Col  lg={3} md={3} sm={12} xs={12}  className="border-right p-1 m-0"  >
										<LeadLabel col={props.col} project={project} />
										<Lead colId={props.colId} isCollapse={props.isCollapse} callback={props.callback} projects={projects} status={project[props.colId]} onActionHanlder={(e)=>{props.onActionHanlder(e)}} onStatusHanlder={(e)=>{props.onStatusHanlder(e)}} drag={(e)=>{props.drag(e)}}   />
										<span className="d-none">{tempValue.push(project[props.col]) }</span>
								</Col>
							:''}
						</>
					)
				})}
			</Row>
		</>		
	)
}

export const LeadLabel	= (props) =>{	
	return <div className="border-top rounded-top p-1" style={{ backgroundColor:'lightgray',color:'black'}}>
				{props.project[props.col]}
				<span className="mr-2 float-right">
					<img height="25px" src={iconMenu} style={{cursor:'pointer'}} onClick={(e)=>{
						var layoutUtil	=new LayoutUtil();
							layoutUtil.openProjectMenu(props.project);
					}} />
				</span>
				{/*
				<span className="mr-2 float-right">
					<img height="25px" src={iconDrawing} style={{cursor:'pointer'}} onClick={(e)=>{
						var project =new Project();
							project.viewDrive(props.project.prj_folder_id);
					}} />
				</span>
				<span className="mr-2 float-right">
					<img title="Logs" height="25px" src={iconCamera} style={{cursor:'pointer'}} onClick={(e)=>{
						var invoice =new Invoice();
							invoice.openLog(1, props.project);
					}} />
				</span>
				<span className="mr-2 float-right">
					<img title="Clocks" height="25px" src={iconClock} style={{cursor:'pointer'}} onClick={(e)=>{
						var taskDetail =new TaskDetail();
							taskDetail.openClockInOut(props.project.prj_id);
					}} />
				</span>
				<span className="mr-2 float-right">
					<img title="Purchases" height="25px" src={iconPurchase} style={{cursor:'pointer'}} onClick={(e)=>{
						var invoice =new Invoice();
							invoice.openLog(2, props.project);
					}} />
				</span>
				<span className="mr-3 float-right">
					<img title="" height="25px" src={iconExchange} style={{cursor:'pointer'}} onClick={(e)=>{
						var invoice =new Invoice();
							invoice.openReceiveAndPayment(props.project);
					}} />
				</span>
				*/}
			</div>			
}

export const Lead		= (props) =>{
	var projects 		= props.projects;
	var _status 		= props.status;
	var task_priority_color	=	'light';
	
	var UnitProject	=	[];
	if(!projects)
		return null;
	
	return(
		<>
			{projects.map(project=>{
				if(UnitProject.findIndex(i=>i*1 == project.prj_id*1) == -1){
					var date0 	=new Date(project.prj_posted_date_full);
					var date1 	=new Date();

					const diffTime0 = (date1 - date0);
					UnitProject.push(project.prj_id);
					return(
					<>
					{project[props.colId] == _status?
						<>
							<Accordion defaultActiveKey="-1" flush className="p-0 m-0" >
								<Accordion.Item eventKey="0" className="p-0" >
									<Accordion.Header className="p-0 text-right" style={{backgroundColor: newColorFind(project.category,20)}} >
										<small>
											<b>{project.prj_id}</b>&nbsp;&nbsp;
											<small>{project.prj_title}</small><br />
											<small>{project.prj_address}, {project.prj_city}, {project.prj_postal_code}</small>
											<br/>
											<small><b>{project.prj_posted_date}</b></small> |
											<small className="ml-1">
												<img className="d-none" src={iconDays} width={13} title="Duration" />
												<b> {Math.ceil(diffTime0 / (1000 * 60 * 60 * 24))} Day(s)</b>{'	'}
											</small> 
											{hasAccess('budget')?
											<>|<small className="ml-2" ><b>${ numberWithCommas(project.prj_budget)}</b></small>{' '}</>:''}
											<br/>
											<i>{project.name}{' '}{project.lastname}</i>
										</small>
									</Accordion.Header>
									<Accordion.Body>
											<div id={project.prj_id? project.prj_id: 0} draggable="false" onDragStart={(event)=>props.drag(event)} >
												<Card>
													<CardBody style={{backgroundColor: newColorFind(project.category,20)}}>
														<CardTitle className="text-capitalize">
															<>
																<b title="Project Type" >{project.category_desc}</b>
																<Button 
																	className="float-right pl-1 pr-1 pt-0 pb-0 m-1"
																	outline
																	size="sm"
																	color="dark"
																	href={"/#/ViewProject/" + project.prj_id }
																	target={'_blank'}
																><small>{project.prj_id}</small></Button>
																	{/*
																<span className="float-right btn btn-primary btn-sm p-0">
																	<a style={{color: 'black'}} target={'_blank'} href={"/#/ViewProject/" + project.prj_id } >{project.prj_id}</a>
																</span>
																	*/}
																<small className="float-right mr-1" >
																	<i 
																	data-prj-id={project.prj_id? project.prj_id: 0} 
																	data-prj-status={project.prj_status? project.prj_status: 0} 
																	data-prj-department={project.prj_department? project.prj_department: 0} 									
																	style={{cursor:'pointer'}} color="dark" 
																	onClick={(e)=>props.onStatusHanlder(e)} 
																	title='Project Status'
																	>
																		{project.prj_status}
																	</i>
																</small>
																<br/>
																<span>{project.prj_title}</span>
																{hasAccess('budget')?
																<span className="float-right" ><small>${ numberWithCommas(project.prj_budget)}</small></span>:''}
															</>
															<br/>
															<span><a target={'_blank'} href={'https://maps.google.com?q=' + project.prj_address + ',' + project.prj_city }><img src={iconLocation} height='16' className="mr-1" /></a></span>
															<small>{project.prj_address}, {project.prj_city}, {project.prj_postal_code}</small>
															{/*
														{project.refered_by_person_id*1==5?
															<span>| <a target={'_blank'} href={'https://trustedpros.ca/members_contractor_search_for_lead_results_view.asp?job_id=' + (project.refered_by_prj_id?project.refered_by_prj_id.replace('HCA',''):'') }><img src={iconT} height='16' /></a></span>
														:''}{'	'}
														{project.refered_by_person_id*1==16?
															<span>| <img src={iconH} height='16' /></span>
														:''}{'	'}
															*/}
															<br/>
														<span className="float-right mr-1">
															<img src={iconDays} width={13} title="Duration" />
															<b> {Math.ceil(diffTime0 / (1000 * 60 * 60 * 24))} Days</b>{'	'}
														</span>
														<span className="float-right mr-1">{project.prj_posted_date}</span>
														{/*
															<h4 style={{display:'inline'}} ><Badge 
																	data-prj-id={project.prj_id? project.prj_id: 0} 
																	data-prj-status={project.prj_status? project.prj_status: 0} 
																	data-prj-department={project.prj_department? project.prj_department: 0} 
																	onClick={(e)=>props.onActionHanlder(e)}
																	href="javascript:(0);" color="dark" className="float-right mr-1">
																	{project.prj_id}
															</Badge></h4>
														*/}
														</CardTitle>										
													</CardBody>
													<CardFooter  style={{backgroundColor: newColorFind(project.category,80)}} >
														<Accordion defaultActiveKey={props.isCollapse} flush>
															<Accordion.Item eventKey="0">
																<Accordion.Header className="p-0 text-right" >
																{/*
																	<HorizontalAvatarList
																	  avatars={projects.filter(p=>p.prj_id==project.prj_id)}
																	  avatarProps={{ size: 40 }}
																	  reversed
																	/>
																*/}
																	<small>Tasks</small>
																</Accordion.Header>
																<Accordion.Body>
																	<TaskList isSubTask={false} callback={props.callback} projects={projects} project={project.prj_id} onActionHanlder={(e)=>{props.onActionHanlder(e)}} />
																	<div className={'border rounded p-1 mt-1'} >
																		<img src={iconAdd} width={16} title="Assign To" 
																			style={{cursor:'pointer'}}
																			data-project-id={project.prj_id} 
																			onClick={(e)=>{
																				var _task	=new Task();
																				_task.openAssignTask(project.prj_id).then(response => {
																					props.callback(response);
																				})
																			}}
																		/>
																	</div>
																</Accordion.Body>
															</Accordion.Item>
														</Accordion>									
													</CardFooter>
													<CardFooter style={{backgroundColor: newColorFind(project.category,80)}} >
														{project.name}{' '}{project.lastname}{'	'}
														{project.person_tel?
															<img style={{cursor:'pointer'}} src={iconPhone} height='16' title={project.person_tel} onClick={()=>{window.location='tel:' + project.person_tel}} />
														:''}
														{'	'}
														{project.email?
															<img style={{cursor:'pointer'}} src={iconEmail} height='16' title={project.email} onClick={()=>{window.location='mailto:' + project.email}}/>
														:''}
														{'	'}									
														{project.has_drawings*1==1?
															<img className="float-right mr-1" src={iconDrawing} height='16' title="Has Drawing" />
														:''}
														{project.has_permit*1==1?
															<img className="float-right mr-1" src={iconPermit} height='16' title="Has Permit" />
														:''}
														{project.has_model*1==1?
															<img className="float-right mr-1" src={iconModel} height='16' title="Has Model" />
														:''}
													</CardFooter>
												</Card>
											</div>
										</Accordion.Body>
									</Accordion.Item>
								</Accordion>									
						</>					
					:''}
					</>
					)				
				}
			})}
		</>
	)	
}

export const TaskList	= (props) =>{
	const [isOpen, setIsOpen] 	= useState(false);
	
	var options = {
		year: "numeric",
		month: "2-digit",
		day: "2-digit",
		hour:"2-digit",
		minute:"2-digit"
	};
	
	var today = new Date();
	var date = today.getFullYear() + '-' + (today.getMonth() <= 9 ? '0' + (today.getMonth() + 1) : today.getMonth() + 1) + '-' + (today.getDate() <= 9 ? '0' + today.getDate() : today.getDate());
	var task_priority_color	=	'';
	var card_priority_color	=	'';
	var _performance;
	var _performance_color;
	var date0, date1, date2;
	const getSource	=(_id)=>{
		var _src = require("assets/img/users/avetar.jpg");
		try{
			_src = require('assets/img/users/' + _id + '.jpg');
		}catch(err){}
		return _src;
	}
	
	
	const AvatarWithBadge = withBadge({
		position: 'bottom-left',
		/*color: 'warning',*/
	})(Avatar);
	
	const formatDate = (_date) => {
		
		var options = {
			year: "numeric",
			month: "2-digit",
			day: "2-digit",
			hour:"2-digit",
			minute:"2-digit"
		};
		/*
		var date = (new Date( _date+ ' UTC')).toLocaleString("en", options);
        //const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        //const seconds = String(date.getSeconds()).padStart(2, '0');

        //return `${month}/${day} ${hours}:${minutes}`;
		*/
		return _date;
    }
	
	{/*
	if(props.projects)
		props.projects.map(p=>{
			return(<li>{p.receiver_id}</li>);
		});
	*/}
	var _project	=	props.project;
	return (
		<div className="p-1 mb-1 rounded" >
		{props.projects.filter(project=>project.prj_id==_project && (!project.parent || props.isSubTask)).map(project=>{				
				_performance	= Math.round(100*(project.percent_done/100)/(project.real_hours/60/project.estimate_hours))>100? 100: Math.round(100*(project.percent_done/100)/(project.real_hours/60/project.estimate_hours));
				
				date0 	=new Date(project.start_date_full);
				date1 	=new Date();
				//date2	=new Date(project.expected_end_date);
				date2	=new Date(project.end_date_full);

				const diffTime0 = (date1 - date0);
				const diffDays0 = Math.ceil(diffTime0 / (1000 * 60 * 60 * 24)); 

				const diffTime = (date2 - date1);
				const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 

				const diffTime1 = (date2 - date0);
				const diffDays1 = Math.ceil(diffTime1 / (1000 * 60 * 60 * 24));
			
				const min	=	0;
				const slider=	1;
				const max	= 	(diffDays<0? diffDays0+slider: diffDays1+slider);
				const left	=	(diffDays<0? diffDays1: diffDays0);
				const right	=	(diffDays<0? max+1-left: diffDays);

				const Pmax		=	100;
				const Pleft		=	left*100/max;
				const Pslider	=	1;
				const Pright	=	right*100/max;
				var		color	=	(project.task_status_id*1==7? 'light': (project.task_status_id*1==8? 'warning': (project.task_status_id*1==9? 'info': (project.task_status_id*1==10? 'success': (project.task_status_id*1==17? 'dark': 'light')))))
				var		bgcolor	=	(project.task_status_id*1==7? 'bg-light text-dark': (project.task_status_id*1==8? 'status-start text-dark': (project.task_status_id*1==9? 'status-progress text-dark': (project.task_status_id*1==10? 'status-done text-dark': (project.task_status_id*1==11? 'bg-dark text-light': 'bg-light text-dark')))))
				//var		bgcolor	=	'bg-light text-dark';
		return(
		<>
			<div className={'border rounded p-1 ' + bgcolor + (project.parent*1>0?' ml-2':'') } >
			<Avatar color={'warning'} size={32} className='float-left' src={getSource(project.receiver_id)} title={project.receiver} />
				<Button 
					className="float-right pl-1 pr-1 pt-0 pb-0 m-1"
					outline
					size="sm"
					color="dark"
					data-task-id={project.pt_id?project.pt_id:0}
					data-project-id={project.prj_id? project.prj_id : 0}
					onClick={(e)=>{
						var _task	=new Task();
						_task.ViewTask(project).then(response=>{
							props.callback(response);					
						});
					}}
				><small>{project.pt_id}</small></Button>
					{/*
				<span style={{cursor:'pointer'}} > 
					<small className="float-right" >{project.task_status}</small>
				</span>
					*/}
					
					{/*
				<img src={iconDuration} width={16} title="Days You have Worked On" />
				{'	'}<small>{diffDays0}</small>
				<img src={iconDeadline} width={16} title="Dys To Deadline"  />
				{'	'}<small>{diffDays}</small>{'	'}

				<img style={{cursor: 'pointer'}} src={iconSchedule} width={16} title="Estimate Hours" />
				<small>{project.estimate_hours? project.estimate_hours : '-'}</small>{'	'}
				
				<img src={iconHourGlass} width={13} title="Real Minutes" />
				<small>{project.real_hours?project.real_hours*1 : 0} ′</small>{'	'}

				<img src={iconThumbsUp} width={16} title="Percent Done" />
				<small>{project.percent_done?project.percent_done*1 : 0}</small>{'	'}
				
				{!_performance || _performance<60?
					<img src={iconLowPerformance} width={13} title="Performance" />
				:''}
				{_performance>=60 && _performance<80?
					<img src={iconMiddlePerformance} width={13} title="Performance" />
				:''}
				{_performance>=80?
					<img src={iconHightPerformance} width={13} title="Performance" />
				:''}
				<small>{_performance? _performance: 0}</small>
				*/}
				<small
					className="ml-2"
					color={color}
					style={{cursor:'pointer'}}
					data-task-id={project.pt_id?project.pt_id:0} 
					onClick={(e)=>{
						var _task	=new Task();
						_task.viewTaskUpdateForm(project).then(response => {
							props.callback(response);
						})
					}}
					title='Task Type'
				>{project.task_type}</small>
				<small className="p-0 d-block" style={{clear:'both'}} title={project.task}  >
					<Avatar size={16}  src={getSource(project.sender_id)} title='Sender' className="float-left" />
					{project.task.substring(0, 40) + '...'}
				</small>
				<img className="ml-2" src={iconAddNote} width={24} title="Add Note" 
						style={{cursor:'pointer'}}
						data-task-id={project.pt_id?project.pt_id:0} 
						onClick={(e)=>{
							var _task	=new Task();
							_task.openAddNote(project).then(response => {
								props.callback(response);
							})
						}}
					/>
				<img className="ml-2" src={iconAlarm} width={24} title="Add Alarm" 
						style={{cursor:'pointer'}}
						data-task-id={project.pt_id?project.pt_id:0} 
						onClick={(e)=>{
							var _task	=new Task();
							_task.openAddNote(project).then(response => {
								props.callback(response);
							})
						}}
					/>
				<img className="ml-2" src={iconProvideEstimate} width={24} title="Add Estimate/Quote" 
						style={{cursor:'pointer'}}
						onClick={(e)=>{
							var _invoice	=new Invoice();
							_invoice.openProvideEstimate(project).then(response => {
								props.callback(response);
							})
						}}
				/>
				<img className="ml-2" src={iconPayment} width={24} title="Add Payment" 
						style={{cursor:'pointer'}}
						data-task-id={project.pt_id?project.pt_id:0} 
						onClick={(e)=>{
							var _invoice	=new Invoice();
							_invoice.openReceiveAndPayment(project).then(response => {
								props.callback(response);
							})
						}}
					/>
					<small className="d-none" title={project.clock_list.split('---').join('\n#\n').replaceAll('<br/>','\n')}><b>Clocks {project.clock_count}</b></small>{'	'}
					<small className="d-none" title={project.note_list.split('---').join('\n#\n').replaceAll('<br/>','\n')}><b>Comments {project.note_count}</b></small>{'	'}
				<hr className="p-0 m-0" />
				<span>
					<small className="float-right ml-1 " >
						{project.task_status_id*1==10?
							<>
								<img src={iconDays} width={13} title="Days have Worked On" />
								{'	'}<b>{diffDays1<0?0:diffDays1}</b>
							</>
						:
						<>
							<img src={iconDays} width={13} title="Days have Worked On" />
							{'	'}<b>{diffDays0<0?0:diffDays0}</b>
						</>
						}
					</small>
					<small className="float-right" >{formatDate(project.start_date)}</small> &nbsp; 
					<img src={iconHourGlass} width={13} title="Real Minutes"
						onClick={(e)=>{
							var _taskDetail	=new TaskDetail();
							_taskDetail.ViewTaskDetail(project).then(response => {
								props.callback(response);
							})							
						}}
					/>
					<small>{project.real_hours? Math.floor(project.real_hours*1/60) + ':' + Math.floor(project.real_hours*1%60) : ''} <sup>hrs</sup></small>{'	'} {/* hh:mm */}
				</span>
			</div>
			{props.projects.filter(p=>p.parent==project.pt_id).length>0?
			<Accordion defaultActiveKey={props.isCollapse} flush>
				<Accordion.Item eventKey="0">
					<Accordion.Header className="p-0 text-right" >
						<small>Sub Tasks</small>
					</Accordion.Header>
					<Accordion.Body>
						<TaskList isSubTask={true} callback={props.callback} projects={props.projects.filter(p=>p.parent==project.pt_id)} project={project.prj_id} onActionHanlder={(e)=>{props.onActionHanlder(e)}} />
					</Accordion.Body>
				</Accordion.Item>
			</Accordion>:''}									
		</>
		)})}
		</div>
		);
}

