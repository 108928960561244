import iconAdd from 'assets/img/icon/add.png';

import React, {useState, useEffect} from 'react';
import TaskDetail from '../TaskDetails/TaskDetail';
import Task			from '../Tasks/Task';
import ComboBox from "../../component/ComboBox";
import { Row, Col, Input, Card, CardHeader, CardBody, Modal, ModalHeader, ModalBody, Alert, FormGroup, Label, Button } from 'reactstrap';

import {Grid} from '../_Components/Grid-v1';

import NotificationSystem from 'react-notification-system';
import { NOTIFICATION_SYSTEM_STYLE } from 'utils/constants';
const notificationSystem = React.createRef();
const addNotification = (_MESSAGE_, _TYPE_) => {
		const notification = notificationSystem.current;
		notification.addNotification({message: _MESSAGE_, level: _TYPE_});
	};

function ViewTaskPerson(props){
	const [projectId,	setProjectId]		= useState(0);
	const [taskId,	setTaskId]				= useState(0);
	const [taskDetail	,	setTaskDetail]	= useState([]);
	const [reload	,	setReload]			= useState(false);

	useEffect(()=>{
		if(props.projectId != projectId){	
			setProjectId(props.projectId);
		}
		if(props.projectId != projectId){			
			setTaskId(props.taskId);
			var task =new Task();
			task.selectByParent(props.taskId, -1).then(data=>{
					if(data){
						setTaskDetail(data);
						setReload(false);
					}					
				});

		}
		if(reload){	
			var task =new Task();
			task.selectByParent(props.taskId, -1).then(data=>{
					if(data){
						setTaskDetail(data);
						setReload(false);
					}					
				});
		}
    },[props, reload]);

    return(
        <>
			<div className="p-2" >
				<Row>
					<Col>
						<img className="mb-1" height="32" onClick={async (e)=>{ var _taskDetail	=new TaskDetail(); var obj = await _taskDetail.openFormSubTask(projectId, taskId);if(obj.callbackType=='taskdetail'){setReload(true);}}} src={iconAdd} style={{cursor:'pointer'}} />New Assign
					</Col>
				</Row>
				<Row>
					<Col>
						<Grid records={taskDetail} loading={reload} hasHeader={true} onUpdateHandler={(e, primaryKey)=>{addNotification("Updated!","info")}} onDeleteHandler={(e, primaryKey)=>{var taskDetail=new TaskDetail(); taskDetail.deleteById(primaryKey);setReload(true); addNotification("Deleted!","info"); }} 
						rowClass={"record['task_status-']==10? 'table-success': (record['task_status-']==9? 'table-info': (record['task_status-']==8? 'table-warning': ''))"}
						/>
					</Col>
				</Row>
			</div>
			<NotificationSystem ref={notificationSystem} style={NOTIFICATION_SYSTEM_STYLE}/>			
        </>
    )
}
export default ViewTaskPerson;
