import React, {useState, useEffect } from "react";
import DataAdapter from "../../adapter/DataAdapter";
import Page from 'components/Page';
import TaskDetail from '../TaskDetails/TaskDetail';
import {Label, Input, Modal, ModalBody, ModalFooter, ModalHeader, Card, CardBody, CardHeader,
  CardSubtitle,
  CardText,
 Col, Row, Table, Button, ButtonGroup, Form, FormGroup} from 'reactstrap';
import VtxComboBox from "../../component/VtxComboBox";
import { Grid } from "../_Components/Grid";
import Person from "../Persons/Person";
import { Cookies } from 'react-cookie';
import ComboBox from "../../component/ComboBox";

import NotificationSystem from 'react-notification-system';
import { NOTIFICATION_SYSTEM_STYLE } from 'utils/constants';

const cookie =new Cookies();
const notificationSystem = React.createRef();
const addNotification = (_MESSAGE_, _TYPE_) => {
		const notification = notificationSystem.current;
		notification.addNotification({message: _MESSAGE_, level: _TYPE_});
	};
const __PERSON__ 		= cookie.get("v-person-id");
const __PERSON_ROLE__	=	cookie.get("v-person-role");


function ViewReceiveAndPayment (props){
	
	var date = new Date();
	/* Daily 
	var _fromdate = date.toISOString().split('T')[0];
	var _todate = date.toISOString().split('T')[0];
	*/
	
	/* Monthly*/
	var _fromdate = (new Date(date.getFullYear(), date.getMonth(), 1)).toISOString().split('T')[0];
	var _todate = (new Date(date.getFullYear(), date.getMonth() + 1, 0)).toISOString().split('T')[0];
	
	var curr = new Date;
	var firstday = new Date(curr.setDate(curr.getDate() - curr.getDay()));
	var lastday = new Date(curr.setDate(curr.getDate() - curr.getDay()+6));
		
	/* Weekly
	var _fromdate = (new Date(date.setDate(date.getDate() - date.getDay()+1))).toISOString().split('T')[0];
	var _todate = (new Date(date.setDate(date.getDate() - date.getDay()+6))).toISOString().split('T')[0];
	*/
	const [records, setRecords] = useState([]);
	const [recordsFiltered, setRecordsFiltered] 	= useState([]);
	const [filterYear, setFilterYear]	 			= useState(-1);
	const [filterPerson, setFilterPerson] 			= useState(-1);
	const [filterFromDate, setFilterFromDate]		= useState(_fromdate);
	const [filterToDate, setFilterToDate]			= useState(_todate);
	const [reload	,	setReload]					= useState(true);
	const [reloadGrid	,	setReloadGrid]			= useState(true);

	const [taskType, setTaskType] = useState(-1);
	const [type, setType] = useState(-1);
	const [status, setStatus] = useState(10);
	const [department, setDepartment] = useState(-1);
	const [projectId, setProjectId] = useState(-1);
	const [taskId, setTaskId] = useState(-1);
	const [personId, setPersonId] = useState(-1);
    const [gridConfig, setGridConfig] 	= useState({});
    const [dataSource, setDataSource] 	= useState({});
	

	const loadGrid = (_projectId, _taskId, _type, _taskType) => {
		const _dataSource = {
			type 		: 	'table',
			table		:	'rb_receive_n_payments',
			fields		:	[],
			getColumns	:	function(){
								var _columns	= [];
								this.fields.map(f=>_columns.push({id:f}));
								return _columns;
							},
			getRecords	:	async function(){
								var _sql	=	"SELECT rnp.rp_id, rnp.prj_id, rnp.pt_id, rnp.ptd_id, rnp.rp_date,IF(rnp.rp_type=1,rnp.amount,amount*-1) AS amount, rnp.item_hst ,rnp.rp_desc,rnp.rp_type,rnp.image_id FROM rb_receive_n_payments rnp LEFT JOIN rb_project_tasks pt ON pt.pt_id = rnp.pt_id  WHERE " + (_projectId!=-1? " rnp.prj_id=" + _projectId: "") + (_taskId!=-1? " AND rnp.pt_id=" + _taskId: "") + (_type!=-1? " AND rnp.rp_type=" + _type: "") + (_taskType!=-1? " AND pt.task_type=" + _taskType: "") + " ORDER BY rp_date";
								var dataAdapter =new DataAdapter(_sql);
								return await dataAdapter.getRecords();
							},
			prepareUpdate:	[],
			onUpdate	:	async function(e, _primaryKey, _primaryValue, _field, _value){
								if(_primaryValue){
									var dataAdapter =new DataAdapter("UPDATE " + this.table + " SET " + _field + " = '" + _value + "' WHERE " + _primaryKey + " = '" + _primaryValue + "';");
									return await dataAdapter.getRecords();
								}else
								{
								}
							},
			prepareInsert:	[
				{field:'Clock_in'	, parameters: ['start_date','Clock_in'], prepare:(_parameter)=>{ var _UTC = (new Date(new Date(_parameter[0] + ' ' + _parameter[1]).toUTCString()).toISOString()); return (_UTC.split('T')[0] + ' ' + _UTC.split('T')[1].slice(0, 5)); }},
				{field:'Clock_out'	, parameters: ['start_date','Clock_out'], prepare:(_parameter)=>{var _UTC = (new Date(new Date(_parameter[0] + ' ' + _parameter[1]).toUTCString()).toISOString()); return (_UTC.split('T')[0] + ' ' + _UTC.split('T')[1].slice(0, 5)); }},
			],
			onInsert	:	async function(e, _primaryKey, _record){
								var _sql	=	"";
								var _fields	=	"";
								var _values	=	"";
								Object.keys(_record).forEach((_key)=>{
									if(_key != _primaryKey){
										_fields += 	_key + ",";
										_values +=	"'" + _record[_key] + "',";
									}
								})
								_sql = "INSERT INTO " + this.table + " (" + _fields.slice(0, -1) + ") VALUES (" + _values.slice(0, -1) + ");";
								var dataAdapter =new DataAdapter(_sql + "\r\nSELECT " + _primaryKey + " FROM " + this.table + " ORDER BY " + _primaryKey + " DESC LIMIT 1;");
								return await dataAdapter.getRecords();
							},
			onCopy	:	async function(e, _primaryKey, _record){
								var _sql	=	"";
								var _fields	=	"";
								var _values	=	"";
								Object.keys(_record).forEach((_key)=>{
									if(_key != _primaryKey){
										_fields += 	_key + ",";
										_values +=	"'" + _record[_key] + "',";
									}
								})
								_sql = "INSERT INTO " + this.table + " (" + _fields.slice(0, -1) + ") VALUES (" + _values.slice(0, -1) + ");";
								
								var dataAdapter =new DataAdapter(_sql + "\r\nSELECT " + _primaryKey + " FROM " + this.table + " ORDER BY " + _primaryKey + " DESC LIMIT 1;");
								return await dataAdapter.getRecords();
								
							},							
			onDelete	:	async function(e, _primaryKey, _primaryValue){
								if(_primaryValue){
									var dataAdapter =new DataAdapter("DELETE FROM " + this.table + " WHERE " + _primaryKey + " = '" + _primaryValue + "';");
									return await dataAdapter.getRecords();
								}else
								{
								}
							}
		};
		const _gridConfig	=	{
			primaryKey	:	'rp_id',
			hasFilter	:	false,
			hasPager	:	false,
			canDelete	:	true,
			canUpdate	:	true,
			canCopy		:	true,
			canInsert	:	false,
			aggregations:	[{field:'amount',func:'sum'}],
			columns		: [
				{id:'rp_id', caption:'Id', type:'text'},
				{id:'prj_id', caption:'Project', type:'select', queryString:'SELECT prj_id,prj_address,prj_name, prj_title FROM rb_projects WHERE prj_status IN (9,10,11) ORDER BY prj_id DESC', valueField:'prj_id', displayField:'prj_id,prj_address'},
				{id:'pt_id', caption:'Activity', type:'select', queryString:"SELECT pt_id, item_no,prj_id,task FROM rb_project_tasks WHERE task_type IN (SELECT rb_type_no FROM rb_types WHERE rb_type='task_type' AND rb_type_type_id=4) AND prj_id=" + projectId + " /*AND sort_id<>0 AND task_status IN (9,10) AND task_type IN (122)*/ ORDER BY item_no,sort_id", valueField:'pt_id', displayField:'item_no,task', width:'200px'},
				{id:'rp_date', caption:'Date', type:'date'},
				{id:'amount', caption:'Amount', type:'text', prefix:'$'},
				{id:'item_hst', caption:'HST', type:'text', prefix:'$'},
				{id:'rp_desc', caption:'Desc.', type:'text'},
				{id:'image_id', caption:'Attach', type:'image', hidden: true},
			],
			rowClass	:	"",

		};

		setGridConfig(_gridConfig);
		setDataSource(_dataSource);

	}

	useEffect(() => {
		if(props.project){
			console.log(props.project)
			setProjectId(props.project.prj_id);
			setTaskId(props.project.pt_id);
			setType(props.project.pt_type);
			loadGrid(props.project.prj_id, props.project.pt_id, -1, -1);
		}
    }, []);
	
	useEffect(() => {
		//loadGrid(projectId, taskId, type, taskType);
    }, [filterFromDate, filterToDate, projectId]);

	const onFilterChange 		= (e) =>{
		e.persist();
		
		//var _value		=	e.target.attributes["value"].nodeValue;
		var _value		=	e.target.value;
		var _valueFiled	=	e.target.attributes["id"].nodeValue;
		var _recordsFiltered;
		
		if(_value*1 == -1){
			_recordsFiltered = records.filter(r=>r['Date-'] >= _fromdate || filterYear==-1);
			if(e.target.id=='Person'){
				setFilterPerson(_value);
			}
		}else{
			if(e.target.id=='Person'){
				setFilterPerson(_value);
				_recordsFiltered = records.filter(r=>(r['Date-'] >= filterFromDate && r['Date-'] <= filterToDate   ));
			}
			
			var valueField	=	_valueFiled;
			setRecordsFiltered(_recordsFiltered.filter(r=>r[valueField]==_value || _value==-1));
		}
	}
	const onFilterDateChange 	= (_fromdate, _todate) =>{
		//setRecordsFiltered(records.filter(r=>r['Date'] >= _fromdate || filterYear==-1));
		setRecordsFiltered(records.filter(r=>(r['Date-'] >= _fromdate && r['Date-'] <= _todate   )));
	}

	const onFromDateChange	=	(e) =>{
		e.persist();
		setFilterFromDate(e.target.value);
		var _recordsFiltered = records.filter(r=>(r['Date-'] >= e.target.value && r['Date-'] <= filterToDate   ));
		setRecordsFiltered(_recordsFiltered);
	}
	const onToDateChange	=	(e) =>{
		e.persist();
		setFilterToDate(e.target.value);
		var _recordsFiltered = records.filter(r=>(r['Date-'] >= filterFromDate && r['Date-'] <= e.target.value   ));
		setRecordsFiltered(_recordsFiltered);
	}

	return(
		<>
			<Page className="DashboardPage" title="" >
			<Row className="p-1">
				<Col md={3} sm={3} xs={12} >
					<ComboBox title="Type" onChange={async (e)=>{
						e.persist();
						//setType(e.target.value);
						//loadGrid(projectId, taskId, e.target.value, taskType);
					}} table="rb_types" filterField="rb_type" filterValue={'rp_type'} valueField="rb_type_no" displayField="rb_type_desc"  orderby="rb_type_no" defaultValue={type} />
				</Col>
				<Col md={3} sm={3} xs={12} >
					<ComboBox title="Task Type" onChange={async (e)=>{
						e.persist();
						//setTaskType(e.target.value);
						//loadGrid(projectId, taskId, type, e.target.value);
					}} table="rb_types" filterField="rb_type" filterValue={"task_type' AND rb_type_type_id=4 AND 'x'='x"} valueField="rb_type_no" displayField="rb_type_desc"  orderby="rb_type_no" />
				</Col>
				<Col md={3} sm={3} xs={12} >
					<ComboBox title="Projects" onChange={async (e)=>{
						e.persist();
						//loadGrid(e.target.value, taskId, type, taskType);
						//setProjectId(e.target.value);
					}} table="rb_projects" filterField="prj_status" filterValue={status} valueField="prj_id" displayField="prj_id,prj_address"  orderby="prj_id" defaultValue={projectId} />
				</Col>
				<Col md={3} sm={3} xs={12} >
					<ComboBox title="Tasks" onChange={async (e)=>{
						e.persist();
						//loadGrid(projectId, e.target.value, type, taskType);
						//setTaskId(e.target.value);
					}} table="rb_project_tasks" filterField="prj_id" filterValue={projectId} valueField="pt_id" displayField="item_no,task"   defaultValue={taskId} orderby="item_no" />
				</Col>
			</Row>
				<Row>					
					<Col lg={12} md={12} sm={12} xs={12} >
						<Grid config={gridConfig} dataSource={dataSource} callback={(callBack)=>{if(callBack.response)addNotification(callBack.response[0].message,"info");}} />
					</Col>
				</Row>
			</Page>
			<NotificationSystem ref={notificationSystem} style={NOTIFICATION_SYSTEM_STYLE}/>			
		</>
	);
}
export default ViewReceiveAndPayment;
