import React from 'react';
import Base64 from 'Base64';
import NotificationSystem from 'react-notification-system';
import { Cookies } from 'react-cookie';

import VtxGrid from './component/VtxGrid';
import DataAdapter from './adapter/DataAdapter.js';

import { Card, CardBody, CardHeader, CardImg, CardLink, CardText, CardTitle, Col, Input, ListGroup, ListGroupItem, Row, Modal, ModalBody, ModalFooter, ModalHeader, Button, FormGroup, Label} from 'reactstrap';
import { NOTIFICATION_SYSTEM_STYLE } from 'utils/constants';


const cookie =new Cookies();

var __PROJECT_ID__;
var __PROJECT_TASK_ID__;

class ViewProjectTaskDetails extends React.Component {
	notificationSystem = React.createRef();

	constructor(props){
		super(props);
		
		if(props.match){
			__PROJECT_ID__ 		= this.props.match.params.projectId;
			__PROJECT_TASK_ID__ = this.props.match.params.taskId;
		  }else{
			__PROJECT_ID__		= this.props.projectId;
			__PROJECT_TASK_ID__ = this.props.taskId;
		  }
				
		this.state = {
			clockModal		:	false,
			editFormModal	:	false,
			taskDetails		:	[],
			taskDetailIndex	:	10000,
			note			:	'',
			loading			:	true,
			clockIn			:	false,
		};
		this.loadData();
	}

	loadData = () =>{
		var dataAdapter = new DataAdapter("select ptd.ptd_id, ptd.pt_id, ptd.prj_id,ptd.task_type,ptd.task_status,DATE_FORMAT(CONVERT_TZ(ptd.Clock_in,@@session.time_zone,'-04:00'),'%d %M %H:%i') AS `Clock_in`, ptd.Clock_in AS `Clock_in_full`,DATE_FORMAT(CONVERT_TZ(ptd.Clock_out,@@session.time_zone,'-04:00'),'%d %M %H:%i') AS `Clock_out`, ptd.Clock_out AS `Clock_out_full`, ptd.real_hours, ptd.percent_done from rb_project_task_details ptd inner join rb_types ty on ty.rb_type_no = ptd.task_type and ty.rb_type='task_type' where ptd.pt_id = " + __PROJECT_TASK_ID__ + " AND ptd.task_type=98 order by ptd.Clock_in desc;")
								.getRecords()
								.then((data)=>{
									this.setState({taskDetails : data, loading	:	false});
									if(data.find(i=>i.task_status*1==9))
										this.setState({clockIn : true});
								});		
	}
	componentWillReceiveProps(nextProps) {
		if(this.state.loading){
			this.loadData();
		}
	}
	componentDidUpdate(prevProps, prevState) {
		if(this.state.loading){
			this.loadData();
		}
	}
	
    addNotification (_MESSAGE_) {
		const notification = this.notificationSystem.current;
		notification.addNotification({message: _MESSAGE_, level: 'success'});
	};

	runQuery=(query, message)=>{
		let formData = new FormData();
		formData.append("query", query);
		let requestOptions = {method: 'POST',headers: {'Authorization':'Basic ' + Base64.btoa('sizotech:IsKfs$m$SZV#4Eprtj7zSPbO')},body : formData};
		fetch('https://renobuilding.ca/wp-json/reno/v1/query/' , requestOptions)
			.then(response => {
				return Promise.all([response.json(),response])
			})
			.then(([responseData,response]) => {
				this.addNotification(message);
					
			})
			.catch(error => {
				console.error('Error:', error);
			})		
	}
	getDate=()=>{
		var today = new Date();
		
		var date = today.toISOString().split('T')[0];
		return date;			 
	}
	getTime=()=>{
		var today = new Date();
		var time = today.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit", hour12: false }); //today.toISOString().split('T')[1].slice(0,5);
		return time;			 
	}
	onTimerStart=()=>{
		this.setState({time	:	this.getTime()});
	}

	onEditButtonHandler	=	(e)	=>{
		e.persist();
		if(this.state.editFormModal){
			this.runQuery("UPDATE rb_project_task_details SET note='" + e.target.value + "' WHERE ptd_id =" + e.target.attributes[0].values ,"Task Detail Updated");
			this.setState({editFormModal: false});
		}else{
			this.setState({editFormModal: true});
		}
	}
	onDeleteButtonHandler	=	(e)	=>{
		e.persist();
		var taskDetails	=	this.state.taskDetails;
		var index		=	taskDetails.findIndex(td=>td.ptd_id== e.target.attributes["data-ptd"].nodeValue);
		taskDetails.splice(index, 1);
		this.setState({	taskDetails	:	taskDetails});
		this.runQuery("DELETE FROM rb_project_task_details WHERE ptd_id =" + e.target.attributes["data-ptd"].nodeValue ,"Task Detail Deleted");
	}

	onNoteChange = (e) =>{

	}

	onClockInHandler=(e)=>{
		e.persist();

		if(this.state.clockModal){
			//this.setState({loading: true});
			var _UTC_ = new Date(new Date(this.state.date + ' ' + this.state.time).toUTCString()).toISOString().split('T')[0] + ' ' + new Date(new Date(this.state.date + ' ' + this.state.time).toUTCString()).toISOString().split('T')[1].slice(0, 5);
			var	sql ="INSERT INTO rb_project_task_details ( pt_id, prj_id, task_status, task_type, sender_id, receiver_id, Clock_in ) values " +
					"(" + __PROJECT_TASK_ID__ + ", " + __PROJECT_ID__ + ", 9 , 98 , " + cookie.get('v-person-id') + "," + cookie.get('v-person-id') + ", '" + _UTC_ + "')" ;
					
			this.runQuery( sql, "Clocked In Successfully", "success");
			
			var taskDetails	=	this.state.taskDetails;
			taskDetails.push({
				ptd_id			:	this.state.taskDetailIndex, 
				pt_id			:	__PROJECT_TASK_ID__, 
				prj_id			:	__PROJECT_ID__,
				task_type		:	98,
				task_status		:	9,
				Clock_in		:	_UTC_,
				Clock_in_full	:	_UTC_,
				Clock_out		:	null,
				Clock_out_full	:	null, 
				real_hours		:	0, 
				percent_done	:	0,				
			});
			
			this.setState({	taskDetails	:	taskDetails, taskDetailIndex : this.state.taskDetailIndex+1});
			this.setState({clockIn	:	true, clockModal: false});
			//this.onTimerStart();
		}else{
			this.setState({clockModal: true, date: this.getDate(), time: this.getTime(), clockIn : false});
		}
	}
	onClockOutHandler=(e)=>{
		e.persist();
		if(this.state.clockModal){
			//this.setState({loading: true});
			var _UTC_ = new Date(new Date(this.state.date + ' ' + this.state.time).toUTCString()).toISOString().split('T')[0] + ' ' + new Date(new Date(this.state.date + ' ' + this.state.time).toUTCString()).toISOString().split('T')[1].slice(0, 5);
			var sql	=	"UPDATE rb_project_task_details SET percent_done='" + this.state.action_percent + "',note='" + this.state.action_note + "', task_status = 10, Clock_out = '" + _UTC_ + "', real_hours = TIMESTAMPDIFF(MINUTE, Clock_in, '" + _UTC_ + "') " +
			"WHERE task_status=9 AND pt_id=" + __PROJECT_TASK_ID__ + " AND task_type=98 AND sender_id=" + cookie.get('v-person-id') + ";";
			
			this.runQuery(sql, "Clock Out Successfully", "error");
			var taskDetails	=	this.state.taskDetails;
			var index		=	taskDetails.findIndex(td=>td.task_status== 9);
			taskDetails[index].Clock_out	=	_UTC_;
			taskDetails[index].real_hours	=	Math.ceil(((new Date(taskDetails[index].Clock_out)) - (new Date(taskDetails[index].Clock_in)))/ (1000 * 60));
			taskDetails[index].percent_done	=	this.state.action_percent;
			taskDetails[index].task_status	=	10;
			this.setState({	taskDetails	:	taskDetails});
			this.setState({clockIn	:	false, clockModal: false});
			this.onCalculateHandler();
		}else{
			this.setState({clockModal: true, date: this.getDate(), time: this.getTime(), clockIn : true});
		}
	}
	onCalculateHandler=()=>{
		var sql	=	"UPDATE rb_project_tasks SET real_hours = (" +
					"SELECT sum(TIMESTAMPDIFF(MINUTE, Clock_in, Clock_out)) FROM `rb_project_task_details`" +
					"WHERE pt_id = " + __PROJECT_TASK_ID__ + " AND task_status=10 AND task_type=98 AND sender_id=" + cookie.get('v-person-id') + "), percent_done = (select sum(percent_done) from rb_project_task_details WHERE pt_id = " + __PROJECT_TASK_ID__ + " AND task_status=10 AND task_type=98 AND sender_id=" + cookie.get('v-person-id') + ") where pt_id = " + __PROJECT_TASK_ID__  + ";";
		//console.log(sql)
		this.runQuery(sql, "Real hours calculated", "success");
		var dataAdapter =new DataAdapter("SELECT * FROM rb_project_tasks WHERE pt_id=" + __PROJECT_TASK_ID__);
			dataAdapter.getRecords()
						.then(data=>{
							this.setState({task : data[0]});
						})
						.catch(error=>{
							console.error('Error:', error);
						})

	}
	
	render(){
		return (
		<>
			<ListGroup flush>
				<ListGroupItem>
				{!this.state.clockIn?
					<button type="button" className="btn btn-success btn-sm float-left" onClick={(event)=>{this.onClockInHandler(event)}} >Clock In</button>
				:''}
				</ListGroupItem>
			</ListGroup>
			<div>
				<div className="table-responsive" >
					<table className="table table-dark table-striped">
					<thead>
						<tr>
							<th scope="col"></th>
							<th scope="col">CLOCK IN</th>
							<th scope="col">CLOCK OUT</th>
							<th scope="col">Duration</th>					  
							<th scope="col"></th>
						</tr>
					</thead>
					<tbody>
						{this.state.taskDetails?this.state.taskDetails.map(ptd=>{
						
							return(
								<>
									<tr>
										<td>
										{ptd.Clock_out=='' || ptd.Clock_out==null?
											<button type="button" className="btn btn-danger btn-sm float-right" onClick={(event)=>{this.onClockOutHandler(event)}} >Clock Out</button>
										:''}
										</td>
										<td>{ptd.Clock_in}</td>
										<td>{ptd.Clock_out}</td>
										<td>{Math.floor(ptd.real_hours/60) + ':' + Math.floor(ptd.real_hours%60)}</td>
										<td>
											<button data-ptd={ptd.ptd_id} data-pt={ptd.pt_id} data-prj={ptd.prj_id} type="button" className="btn btn-outline-secondary btn-sm mr-1 d-none" onClick={(e)=>this.onEditButtonHandler(e)} >Edit</button>
											<button data-ptd={ptd.ptd_id} data-pt={ptd.pt_id} data-prj={ptd.prj_id} type="button" className="btn btn-outline-secondary btn-sm" onClick={(e)=>this.onDeleteButtonHandler(e)} >Delele</button>
										</td>
									</tr>
								</>
							)
						}):''}
					</tbody>
					</table>
				</div>
				<NotificationSystem ref={this.notificationSystem} style={NOTIFICATION_SYSTEM_STYLE}/>
				{this.editForm()}
				{this.clockForm()}
			</div>
		</>
		);
	}

	editForm =() =>{
		return (
			<>
				<Modal isOpen={this.state.editFormModal} >
                  <ModalHeader>
					<Row>
						<Col>
							Update Task Details
						</Col>
						<Col>
							<Button className="float-right" color="primary" onClick={(e)=>{this.setState({editFormModal:false})}} >X</Button>{' '}
						</Col>
					</Row>
				  </ModalHeader>
                  <ModalBody>
					  <textarea style={{width:'100%', height: '200'}} onChange={(e)=>{this.setState({note:e.target.value});}} onBlur={this.onNoteChange} value={this.state.note} />					
                  </ModalBody>
                  <ModalFooter>
						<>
							<Button color="secondary" onClick={(e)=>{this.onClickNoteButtonHandler(e);}} >Save</Button>{' '}
						</>
                  </ModalFooter>
                </Modal>				
			</>
		);
	}
	clockForm = () => {
		return (
			<>
				<Modal isOpen={this.state.clockModal} >
					<ModalHeader>
						<Row>
							<Col>
								<span>{!this.state.clockIn? 'Clock In' : 'Clock Out'}</span>
							</Col>
							<Col>
								<Button className="float-right" color="primary" onClick={(e)=>{this.setState({clockModal:false})}} >X</Button>{' '}
							</Col>
						</Row>
					</ModalHeader>
					<ModalBody>
						<Label>Date</Label>
						<Input type="date" onChange={(v)=>this.setState({date:v.target.value})} value={this.state.date} />
						<Label>Time</Label>
						<Input type="time" onChange={(v)=>this.setState({time : v.target.value})} value={this.state.time} />
							{this.state.clockIn?
							<>
								<Label>Progress (%)</Label>
								<Input type="text" onChange={(v)=>this.setState({action_percent:v.target.value})} value={this.state.action_percent} />
								<Label>Note</Label>
								<Input type="textarea" onChange={(v)=>this.setState({action_note:v.target.value})} value={this.state.action_note}></Input>
							</>:''}
					</ModalBody>
					<ModalFooter>
					{!this.state.clockIn?
						<Button color="success" onClick={(e)=>{this.onClockInHandler(e)}} >Clock In</Button>
						:
						<Button color="danger" onClick={(e)=>{this.onClockOutHandler(e)}} >Clock Out</Button>
					}
					</ModalFooter>
				</Modal>
			</>
		);
	}

}

export default ViewProjectTaskDetails;