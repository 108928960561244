import React from 'react';
import Page from 'components/Page';
import DataAdapter from "../../adapter/DataAdapter";
import {
	Button,
	Card,
	CardBody,
	CardHeader,
	Col,
	Form,
	FormFeedback,
	FormGroup,
	FormText,
	Input,
	Label,
	Row,
	Modal, ModalBody, ModalFooter, ModalHeader
  } from 'reactstrap';
  import Base64 from 'Base64';
  import { Cookies } from 'react-cookie';
  import NotificationSystem from 'react-notification-system';
  import { NOTIFICATION_SYSTEM_STYLE } from 'utils/constants';
 import ComboBox from "../../component/ComboBox";

	const cookie =new Cookies();
	const notificationSystem = React.createRef();
	const addNotification = (_MESSAGE_, _TYPE_) => {
		const notification = notificationSystem.current;
		notification.addNotification({
		  message: _MESSAGE_,
		  level: _TYPE_
		});
	};	
  
  
class FormSubTask extends React.Component {
	notificationSystem = React.createRef();
	
	constructor(props){
		super(props);

		var today = new Date();
		var date = today.toISOString().split('T')[0];
		var _date = new Date(date);
		_date.setDate(_date.getDate()+1);
		_date = (new Date(_date)).toISOString().split('T')[0];
		this.state = {
			project				: 	props.projectId,
			parent				: 	props.taskId,
			task_type			:	92,
			task				: 	'',
			task_description	: 	'',
			receiver_type		:	8,
			receiver_id			:	cookie.get('v-person-id'),
			create_date			:	date,
			expected_start_date	:	date,
			expected_end_date	:	_date,
			estimate_hours		:	1,
			isOpen				:	true,
			isProgressing		:	false,
			loading				:	false,
			
		};

	}
	/*
	componentWillUpdate(next_props, next_state){
        if (this.props.projectId != next_props.projectId) {
			console.log(next_props.projectId)
			this.setState({project : next_props.projectId});
		}
	}
	*/
	
	componentWillReceiveProps(nextProps) {
	  if (nextProps.projectId !== this.state.project) {
		this.setState({ project: nextProps.projectId });
	  }
	  if (nextProps.taskId !== this.state.parent) {
		this.setState({ parent: nextProps.taskId });
	  }
	}

    addNotification (_MESSAGE_, _TYPE_) {
		const notification = this.notificationSystem.current;
		notification.addNotification({message: _MESSAGE_, level: _TYPE_});
	};	

	runQuery = (query, message) => {
		let formData = new FormData();
		formData.append("query", query);
		let requestOptions = { method: 'POST', headers: { 'Authorization': 'Basic ' + Base64.btoa('sizotech:IsKfs$m$SZV#4Eprtj7zSPbO') }, body: formData };
		fetch('https://renobuilding.ca/wp-json/reno/v1/query/', requestOptions)
			.then(response => {
				return Promise.all([response.json(), response])
			})
			.then(([responseData, response]) => {
				this.addNotification(message, "success");

			})
			.catch(error => {
				console.error('Error:', error);
			})
	}
	
	onSubmitHandler = async (e) =>{
			this.setState({isProgressing : true});
			//var _query	= "INSERT INTO rb_project_task_details (prj_id, pt_id, task_type, task, task_status, sender_id, receiver_id, priority, create_date, expected_start_date,expected_end_date,estimate_hours, task_description) VALUES (" + 			this.state.project + "," + this.state.parent + "," + this.state.task_type + ",'" + this.state.task + "',8," + cookie.get('v-person-id') + "," + this.state.receiver_id + ",1,'" + this.state.create_date + "','" + this.state.expected_start_date + "','" + 			this.state.expected_end_date + "'," + this.state.estimate_hours + ",'" + this.state.task_description + "');\r\nSELECT * FROM rb_project_task_details ORDER BY ptd_id DESC LIMIT 1;";
			var _query	= "INSERT INTO rb_project_tasks (prj_id, parent, task_type, task, task_status, sender_id, receiver_id, priority, create_date, expected_start_date,expected_end_date,estimate_hours, task_description) VALUES (" +  this.state.project + "," + this.state.parent + "," + this.state.task_type + ",'" + this.state.task + "',8," + cookie.get('v-person-id') + "," + this.state.receiver_id + ",1,'" + this.state.create_date + "','" + this.state.expected_start_date + "','" + 			this.state.expected_end_date + "'," + this.state.estimate_hours + ",'" + this.state.task_description + "');\r\nSELECT * FROM rb_project_task_details ORDER BY ptd_id DESC LIMIT 1;";
			var dataAdapter =new DataAdapter(_query);
			var response = await dataAdapter.getRecords();
			if(response){
				//this.addNotification("Task Saved", "info");
				this.props.callback({...{callbackType : 'taskdetail'},...{response : response}, ...{action : 'close'}});
				this.setState({isProgressing : false});
				this.setState({isOpen	:	true});				
			};
	}

	render(){
		return (
		<>
			<Col xl={12} lg={12} md={12}>
				<Card>
					<CardBody>
					<Form>
						<FormGroup row className="d-none">
							<Label for="project" sm={2}>Project</Label>
							<Col sm={10} >
								<ComboBox onChange={(e)=>{this.setState({project : e.target.value}) }} table="rb_projects" valueField="prj_id" displayField="prj_id" filterField="prj_id" filterValue={this.state.project} defaultValue={this.state.project}  />
							</Col>
						</FormGroup>
						<FormGroup row className="d-none">
							<Label for="task" sm={2}>TASK </Label>
							<Col sm={10} >
								<ComboBox onChange={(e)=>{this.setState({parent : e.target.value}) }} table="rb_project_tasks" valueField="pt_id" displayField="pt_id" filterField="pt_id" filterValue={this.state.parent} defaultValue={this.state.parent}  />
							</Col>
						</FormGroup>
						<FormGroup row >
							<Label for="receiver_type" sm={2}>Receiver Type</Label>
							<Col sm={4} >
								<ComboBox onChange={(e)=>{this.setState({receiver_type : e.target.value}) }} table="rb_types" valueField="rb_type_no" displayField="rb_type_desc" filterField="rb_type" filterValue="person_type" orderby="rb_type_desc" defaultValue={this.state.receiver_type} />
							</Col>
							<Label for="receiver_id" sm={2}>Receiver</Label>
							<Col sm={4} >
								<ComboBox onChange={(e)=>{this.setState({receiver_id : e.target.value}) }} table="rb_person" valueField="person_id" displayField="name,lastname,person_desc,person_tel" filterField="person_type" filterValue={ this.state.receiver_type + "' AND if(user_expire_date is not null,user_expire_date>now(),true) AND 'x'='x"} defaultValue={this.state.receiver_id} orderby="name" />
							</Col>
						</FormGroup>
						<FormGroup row >
							<Label for="task_type" sm={2}>Task Type</Label>
							<Col sm={10} >
								<ComboBox onChange={(e)=>{this.setState({task_type : e.target.value}) }} table="rb_types" valueField="rb_type_no" displayField="rb_type_desc" filterField="rb_type" filterValue="task_type" orderby="rb_type_desc" defaultValue={this.state.task_type} />
							</Col>
						</FormGroup>
						<FormGroup row >
							<Label for="task" sm={2}>TASK</Label>
							<Col sm={10} >
								<Input
									type="text"
									name="task"
									id="task"
									placeholder=""
									onChange={(e)=>{this.setState({task	:	e.target.value})}}
									defaultValue={this.state.task}
								/>
							</Col>
						</FormGroup>
						<FormGroup row >
							<Label for="task_description" md={2}>Description</Label>
							<Col md={10} >
								<Input
									type="textarea"
									name="task_description"
									id="task_description"
									placeholder=""
									onChange={(e)=>{this.setState({task_description : e.target.value})}}
									value={this.state.task_description}
								/>
							</Col>
						</FormGroup>
						<FormGroup row >
							<Label for="expected_start_date" md={2}>Expected Start</Label>
							<Col md={4} >
								<Input
									type="date"
									name="expected_start_date"
									id="expected_start_date"
									placeholder=""
									defaultValue={this.state.expected_start_date}
									onChange={(e)=>{this.setState({expected_start_date : e.target.value});}}
								/>
							</Col>
							<Label for="expected_end_date" md={2}>Expected End</Label>
							<Col md={4} >
								<Input
									type="date"
									name="expected_end_date"
									id="expected_end_date"
									placeholder=""
									defaultValue={this.state.expected_end_date}
									onChange={(e)=>{this.setState({expected_end_date : e.target.value});}}
								/>
							</Col>
						</FormGroup>
						<FormGroup row className="d-none" >
							<Label for="estimate_hours" md={2}>Estimate Hours</Label>
							<Col md={10} >
								<Input
									type="number"
									name="estimate_hours"
									id="estimate_hours"
									placeholder=""
									onChange={(e)=>{this.setState({estimate_hours : e.target.value})}}
									value={this.state.estimate_hours}
								/>
							</Col>
						</FormGroup>
						<FormGroup row>
							<Col>
								<Button className="float-right" onClick={this.onSubmitHandler} >Submit</Button>
							</Col>
						</FormGroup>
					</Form>
					</CardBody>
				</Card>
			</Col>
			<NotificationSystem ref={this.notificationSystem} style={NOTIFICATION_SYSTEM_STYLE} />
		</>
				);
	}
}

export default FormSubTask;