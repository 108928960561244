import React,{Suspense} from "react";
import ReactDOM from 'react-dom';
import DataAdapter from "../../adapter/DataAdapter";
import Dialog from "../../component/Dialog";

import { Card, CardBody, CardHeader, CardImg, CardLink, CardText, CardTitle, Col, Input, ListGroup, ListGroupItem, Row, Modal, ModalBody, ModalFooter, ModalHeader, Button, FormGroup, Label} from 'reactstrap';

var isModal	=	false;

class Project {
    constructor(props){
		isModal	=	(props && props.isModal? props.isModal: false);

    }

    selectById = async (_id) =>{
        const _query    =   "select * from rb_projects where prj_id = " + _id;
        var dataAdapter =new DataAdapter(_query);
        var data = await dataAdapter.getRecords();
        return data;
    }

    getFolderId = async (_id) =>{
        const _query    =   "select prj_folder_id from rb_projects where prj_id = " + _id;
        var dataAdapter =new DataAdapter(_query);
        var data = await dataAdapter.getRecords();
        return data[0].prj_folder_id;
    }

    viewById = async (_id) =>{
        const _query    =   "SELECT ty.rb_type_desc as `prj_status_desc`,prj.*, person.*, pr.name as contact FROM rb_projects prj LEFT JOIN rb_types ty ON (ty.rb_type_no = prj.prj_status AND ty.rb_type='prj_status') LEFT JOIN rb_person person ON prj.client_id = person.person_id LEFT JOIN rb_person pr ON prj.contact_name = pr.person_id WHERE prj.prj_id=" + _id;
        var dataAdapter =new DataAdapter(_query);
        var data = await dataAdapter.getRecords();
        return data;
    }

    getClientId  = async (_id)=>{
        const _query    =   "SELECT client_id from rb_projects where prj_id = " + _id;
        var dataAdapter =new DataAdapter(_query);
        var data = await dataAdapter.getRecords();
        return data;
    }
	
	editProjectForm	=	(_project)	=>{
		const div	=	document.createElement('div');
		document.body.appendChild(div);
		const EditProjectForm = React.lazy(() => import('./EditProjectForm'));
		ReactDOM.render(
			<Suspense fallback={<div>Loading... </div>}>
				<EditProjectForm project={_project} />
			</Suspense>
		, div);		
	}

	viewProjectPlan	=	async(_project)	=>{
		const _return = await new Promise((resolve, rej) => {
			const div	=	document.createElement('div');
			document.body.appendChild(div);
			const FormScopeOfWorkManager = React.lazy(() => import('../Scope/FormScopeOfWorkManager'));
			ReactDOM.render(
				<Suspense fallback={<div>Loading... </div>}>
					<FormScopeOfWorkManager projectId={_project} taskType={'between 120 And 128'} callback={(obj)=>resolve(obj)} />
				</Suspense>
			, div);					
		});
	}

	ViewProjectOfPerson	=	async()	=>{
		const _return = await new Promise((resolve, rej) => {
			const div	=	document.createElement('div');
			document.body.appendChild(div);
			const ViewProjectOfPerson = React.lazy(() => import('../../ViewProjectOfPerson'));
			ReactDOM.render(
				<Suspense fallback={<div>Loading... </div>}>
					<Dialog title="Active Projects" isOpen={true} isProgressing={false} notify="" >
						<ViewProjectOfPerson status="0,1,2,3,4,5,6,7,8,9,10" callback={(obj)=>resolve(obj)} />
					</Dialog>
				</Suspense>
			, div);					
		});
	}

	viewDrive	=	async(_parent)	=>{
		const _return = await new Promise((resolve, rej) => {
			const div	=	document.createElement('div');
			document.body.appendChild(div);
			const DriveGrid = React.lazy(() => import('../../ViewDrive/DriveGrid'));
			ReactDOM.render(
				<Suspense fallback={<div>Loading... </div>}>
					<Dialog title="Project Drive" isOpen={true} isProgressing={false} notify="" >
						<DriveGrid parent={_parent} onChildrenChange={()=>{}} callback={(obj)=>resolve(obj)} />
					</Dialog>
				</Suspense>
			, div);					
		});
	}

	viewGantt	=	async(_projectId)	=>{
		const _return = await new Promise((resolve, rej) => {
			const div	=	document.createElement('div');
			document.body.appendChild(div);
			const ViewGantt = React.lazy(() => import('./ViewGantt'));
			ReactDOM.render(
				<Suspense fallback={<div>Loading... </div>}>
					<Dialog title="Project Gantt" isOpen={true} isProgressing={false} notify="" >
						<ViewGantt projectId={_projectId} callback={(obj)=>resolve(obj)} />
					</Dialog>
				</Suspense>
			, div);					
		});
	}

	provideEstimate	=	async(_projectId)	=>{
		const _return = await new Promise((resolve, rej) => {
			const div	=	document.createElement('div');
			document.body.appendChild(div);
			const FormEstimate = React.lazy(() => import('../Estimate/FormEstimate'));
			ReactDOM.render(
				<Suspense fallback={<div>Loading... </div>}>
						<FormEstimate projectId={_projectId} callback={(obj)=>resolve(obj)} />
				</Suspense>
			, div);					
		});
	}

	provideQuote	=	async(_projectId)	=>{
		const _return = await new Promise((resolve, rej) => {
			const div	=	document.createElement('div');
			document.body.appendChild(div);
			const FormQuote = React.lazy(() => import('../Quote/FormQuote'));
			ReactDOM.render(
				<Suspense fallback={<div>Loading... </div>}>
					<Dialog title="provide Quote" isOpen={true} isProgressing={false} notify="" >
						<FormQuote projectId={_projectId} callback={(obj)=>resolve(obj)} />
					</Dialog>
				</Suspense>
			, div);					
		});
	}

	provideInvoice	=	async(_projectId)	=>{
		const _return = await new Promise((resolve, rej) => {
			const div	=	document.createElement('div');
			document.body.appendChild(div);
			const FormInvoice = React.lazy(() => import('../Invoice/FormInvoice'));
			ReactDOM.render(
				<Suspense fallback={<div>Loading... </div>}>
						<FormInvoice projectId={_projectId} callback={(obj)=>resolve(obj)} />
				</Suspense>
			, div);					
		});
	}

	provideReport	=	async(_projectId)	=>{
		const _return = await new Promise((resolve, rej) => {
			const div	=	document.createElement('div');
			document.body.appendChild(div);
			const FormReport = React.lazy(() => import('./FormReport'));
			ReactDOM.render(
				<Suspense fallback={<div>Loading... </div>}>
					<Dialog title="Project Report" isOpen={true} isProgressing={false} notify="" >
						<FormReport projectId={_projectId} callback={(obj)=>resolve(obj)} />
					</Dialog>
				</Suspense>
			, document.getElementById('main'));					
		});
	}
	
}
export default Project;