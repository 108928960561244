import React, {useState, useEffect} from 'react';
import TaskDetail from './TaskDetail';
import TaskDetailCard from './TaskDetailCard';
import {Grid} from '../_Components/Grid-v1';

function ViewTaskDetailByProject(props){
	const [taskDetail, setTaskDetail] = useState([]);
	const [reload, setReload] = useState(false);

	useEffect(()=>{
        var taskDetail =new TaskDetail();
        taskDetail.selectByProject(props.projectId, props.taskType, props.personId).then(data=>{
            if(data)
                setTaskDetail(data);    
        })
    },[props, reload]);

    return(
        <>
		<Grid records={taskDetail} />
			{/*
        {taskDetail.map(t=>{
            return(
                <>	
                    <TaskDetailCard taskDetail={t} reload={()=>{setReload(!reload)}} />                    
                </>
            )
        })}
			*/}
        </>
    )
}
export default ViewTaskDetailByProject;