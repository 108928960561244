// Assest
import iconAttach from 'assets/img/icon/attachment.png';
import iconPhone from 'assets/img/icons8-phone-50.png';
import iconEmail from 'assets/img/icon/email-color.png';
import iconCopy from 'assets/img/icon/copy.png';
import iconGoogleDrive from 'assets/img/icon/google-drive.png';

import React, {useState, useEffect} from "react";
import Task from "./Task";


// Third-party modules
import Base64 from 'Base64';
import QRCode from "react-qr-code";
import { Cookies } from 'react-cookie';
import { EditorState, ContentState, convertFromHTML, convertToRaw } from 'draft-js';
import 'draft-js/dist/Draft.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Switch from "react-switch";

// Framework modules
import Page from 'components/Page';
import { Badge, Card, CardBody, CardHeader, CardImg, CardLink, CardText, CardTitle, Col, Input, ListGroup, ListGroupItem, Row, Modal, ModalBody, ModalFooter, ModalHeader, Button, ButtonGroup, FormGroup, Label } from 'reactstrap';
import NotificationSystem from 'react-notification-system';
import { NOTIFICATION_SYSTEM_STYLE } from 'utils/constants';
import DataAdapter from "../../adapter/DataAdapter";
import ComboBox from "../../component/ComboBox";
// Vortex 
import DateTime from '../../lib/DateTime.js';
import TaskDetail from '../TaskDetails/TaskDetail.js';

// Constants
const cookie = new Cookies();
const notificationSystem = React.createRef();
const addNotification = (_MESSAGE_, _TYPE_) => {
	const notification = notificationSystem.current;
	notification.addNotification({
	  message: _MESSAGE_,
	  level: _TYPE_
	});
};	

const _BACKEND_URL_ = "https://node.renobuilding.ca:3001";


function TaskAction(props){
    const [task, setTask] = useState(0);
    const [note, setNote]   =   useState('');
    const [context, setContext]   =   useState('');
    const [messsage, setMessage]   =   useState('');
    const [messsageEmail, setMessageEmail]   =   useState('');
    const [receiver, setReceiver]   =   useState(null);
    const [client, setClient]       =   useState(-1);
	const [reload, setReload] = useState(false);
	const [templateId, setTemplateId] = useState(-1);
	const [personType, setPersonType] = useState(8);
	const [personTrade, setPersonTrade] = useState(-1);
	const [taskType, setTaskType] = useState(-1);
	const [notification, setNotification] = useState([]);
	const [attachments, setAttachments] = useState('');
	const [signature, setSignature] = useState(false);

	useEffect(()=>{
		if(context)
			fillTemplateTags(context);
		}
	,[signature])

    const onCheckboxBtnClick=(selected)=>{
        const index = notification.indexOf(selected);
        if (index < 0) {
            notification.push(selected);
        } else {
            notification.splice(index, 1);
        }
        setNotification([...notification]);
      }
    
	const onClockInHandler=(e)=>{
		e.persist();

		var _UTC_ = (new Date()).toISOString().split('T')[0] + ' ' + (new Date()).toISOString().split('T')[1].slice(0, 5);
		var	sql ="INSERT INTO rb_project_task_details ( pt_id, prj_id, task_status, task_type, sender_id, receiver_id, Clock_in, start_date ) values " +
				"(" + props.taskId + ", " + props.projectId + ", 9 , 98 , " + cookie.get('v-person-id') + "," + cookie.get('v-person-id') + ", '" + _UTC_ + "','" + _UTC_ + "')" ;
		runQuery( sql, "Clocked In", "success");
	}
	const onClockOutHandler=(e)=>{
		e.persist();
		var _UTC_ = (new Date()).toISOString().split('T')[0] + ' ' + (new Date()).toISOString().split('T')[1].slice(0, 5);
		var sql	=	"UPDATE rb_project_task_details SET percent_done=0,note='', task_status = 10, Clock_out = '" + _UTC_ + "', real_hours = TIMESTAMPDIFF(MINUTE, Clock_in, '" + _UTC_ + "') " +
		"WHERE task_status=9 AND pt_id=" + props.taskId + " AND task_type=98 AND sender_id=" + cookie.get('v-person-id') + ";";
		runQuery(sql, "Clock Out", "error");
	}
	const runQuery=(query, message, _type)=>{
		let formData = new FormData();
		formData.append("query", query);
		let requestOptions = {method: 'POST',headers: {'Authorization':'Basic ' + Base64.btoa('sizotech:IsKfs$m$SZV#4Eprtj7zSPbO')},body : formData};
		fetch('https://renobuilding.ca/wp-json/reno/v1/query/' , requestOptions)
			.then(response => {
				return Promise.all([response.json(),response])
			})
			.then(([responseData,response]) => {
				console.log(responseData);
				addNotification(message, _type);
					
			})
			.catch(error => {
				console.error('Error:', error);
			})		
	}
	const onChangeTaskStatus = (e) =>{
		e.persist();
		var task =new Task();
		task.changeStatus(e.target.attributes["data-id"].nodeValue, e.target.attributes["data-status-id"].nodeValue);
		props.onChangeTaskStatus(e);
		addNotification("Task status changed", "info");
	}
	const onSendSMSHandler = (_text, _receiver) => {
		if (receiver._receiver.receiver_tel) {
			let formData = new FormData();

			formData.append('receiver', _receiver);
			formData.append('text', _text);
			const options = {
				method: "POST",
				headers: {
					'Authorization': 'Basic ' + Base64.btoa('sizotech:IsKfs$m$SZV#4Eprtj7zSPbO')
				},
				body: formData
			};

			fetch('https://node.renobuilding.ca:3001/sms', options)
				.then(response => response.json())
				.then(response => {
					addNotification('SMS has been sent to ' + receiver._receiver.receiver_tel, 'success');


				})
				.catch(err => console.error(err));

				// this.runQuery("INSERT INTO rb_project_task_details (pt_id,prj_id,sender_id, receiver_id, task, task_type, task_description, note, start_date) values (" + __PROJECT_TASK_ID__ + "," + __PROJECT_ID__ + "," + cookie.get("v-person-id") + "," + this.state.receiver_id + ",'SMS', 111,'" + this.state.othernotes + "','', now())","SMS log inserted");

			} else {
			this.addNotification("Receiver have no tel")
		}

	}
    const getToken = () => {
		//return Base64.btoa(this.state.projects.client_id) + '-' + this.state.projects.client_id + '-' + Date.now();
		return receiver._receiver.receiver_id + '-' + receiver._receiver.receiver_id + '-' + Date.now();
	}

	const assignTo = (e) =>{
			
			var expected_end_date = (new Date()).toISOString().split('T')[0];
			var sql	=	"INSERT INTO rb_project_tasks (parent, prj_id,sender_id, receiver_id, start_date, task_status,task_type,task,task_description,expected_end_date,estimate_hours) VALUES (" + props.taskId + "," + props.projectId + "," + cookie.get("v-person-id") + "," + receiver._receiver.receiver_id + ",now(), 8," + taskType + ",'" + props.task.task + "','" + messsage + "','" + expected_end_date + "',1);";
			
			runQuery( sql, "The task assign to " + receiver._receiver.receiver_name, "info");
	}

	const onSendEmailHandler = () => {

        var _msg_formated   = '<div style="background-color:#fff;color:#000;padding:50px;"><div style="float:left;"><img style="max-height:50px;" src="https://renobuilding.ca/wp-content/uploads/2020/12/logo-website.png" /></div><div style="padding: 1px;margin-left: 60px;" ><h5>BUILDING INSPIRING SPACES</h5><h6>A Luxurious Way of Canadian Style Living</h6></div><div style="clear:both;"><p>SUBJECT: <b>[your-subject]</b></p><hr/><div style="PADDING-LEFT: 50PX;"><div style="border-left: 1px solid white;padding: 5px;">[your-message]</div></div></div><hr/><div style="color: gray;"><p>Privacy notice:<br/>The information transmitted is for the use of the intended recipient onlyand may contain confidential and/or legally privileged material. Any review,re-transmission, disclosure dissemination or other use of, or taking of any actionin reliance upon, this information by persons or entities other than the intendedrecipient is prohibited and may result in claim for damages. If you have receivedthis e-mail in error, please notify our office and delete all copies of this transmissiontogether with any attachments. </p><p>Address Unit C, 650 Srigley St., Newmarket L3Y1W9 <br/>E-mail info@renobuilding.ca<br/>Phone +1 (416) 821 3733<br/><i>This e-mail was sent from VORTEX on RenoBuilding (http://renobuilding.ca)</i></p></div>';
        


		let formData = new FormData();
		formData.append("from", 'info@renobuilding.ca');
		formData.append("bcc", 'projects@renobuilding.ca');
		formData.append("to", receiver._receiver.receiver_email);
		formData.append("subject", 'RE : Prj' + props.projectId + ' - ' );
		formData.append("attach", attachments.slice(0, -1));
		// formData.append("text", messsage.replace('\n','<br/>'));
		formData.append("text", _msg_formated.replace('[your-message]', messsageEmail.replaceAll('\n','<br/>')).replace('[your-subject]', 'Prj #' + props.projectId));
		let requestOptions = { method: 'POST', headers: { 'Authorization': 'Basic ' + Base64.btoa('sizotech:IsKfs$m$SZV#4Eprtj7zSPbO') }, body: formData };
		fetch('https://node.renobuilding.ca:3001/message', requestOptions)
			.then(response => {
				return Promise.all([response.json(), response])
			})
			.then(([responseData, response]) => {
				addNotification('E-Mail has been sent to ' + receiver._receiver.receiver_email, 'success');

			})
			.catch(error => {
				console.error('Error:', error);
			})
			// this.runQuery("INSERT INTO rb_project_task_details (pt_id,prj_id,sender_id, receiver_id, task, task_type, task_description, note, start_date) values (" + __PROJECT_TASK_ID__ + "," + __PROJECT_ID__ + "," + cookie.get("v-person-id") + "," + this.state.receiver_id + ",'Email',111,'" + this.state.othernotes + "', '', now())","Call log inserted");


	}
	const onTemplateIdFieldChnageHandler = (e) => {
		var __template_id = e.target.value;
        setTemplateId(__template_id);

		var dataAdapter = new DataAdapter("select * from rb_templates where template_id = " + __template_id);
		dataAdapter.getRecords().then((data) => {
			if(data[0]){
				setContext(data[0].task_desc);
				fillTemplateTags(data[0].task_desc);
			}
		})

	}
	const fillTemplateTags	=	(_templateContext)	=>{
		console.log(signature);
			setMessage( fillTemplateTagsForSMS(_templateContext));
			setMessageEmail( fillTemplateTagsForEmail(_templateContext));
	}
	const fillTemplateTagsForSMS		= 	(_templateContext)	=>{
		 return fillTemplateTagsForSignature( _templateContext.replaceAll('\'','\\\'')
					.replaceAll('[RECEIVER_NAME]'			, receiver._receiver.receiver_name)
					.replaceAll('[RECEIVER_USERNAME]'		, receiver._receiver.receiver_name.toLowerCase())
					.replaceAll('[RECEIVER_PASSWORD]'		, receiver._receiver.receiver_tel.slice(-4))

					// .replaceAll('[CLIENT_NAME]', this.state.receiver_name)
					// .replaceAll('[CLIENT_PASS]', this.state.receiver_tel.slice(-4))

					.replaceAll('[PROJECT_CODE]'			, props.projectId)
					.replaceAll('[PROJECT_TITLE]'			, '')
					.replaceAll('[PROJECT_TOKEN]'			, global.config.url + "/#/ViewProject/" + props.projectId + "/" + props.taskId + "?prj_id=" + props.projectId + "&token=" + getToken() )
					.replaceAll('[PROJECT_LINK]'			, global.config.url + "/#/ViewProject/" + props.projectId + "/" + props.taskId + "?prj_id=" + props.projectId + "&token=" + getToken() )
					.replaceAll('[PROJECT_ESTIMATE_LINK]'	, global.config.url + "/#/ViewProjectTaskNote/" + props.projectId + "/" + receiver._receiver.receiver_id + "/3" )
					.replaceAll('[PROJECT_QUOTE_LINK]'		, global.config.url + "/#/ViewProjectTaskNote/" + props.projectId + "/" + receiver._receiver.receiver_id + "/6" ) 
					, signature);
			
	}
	const fillTemplateTagsForEmail		= 	(_templateContext)	=>{
		 return fillTemplateTagsForSignature( _templateContext.replaceAll('\'','\\\'')

					.replaceAll('[RECEIVER_NAME]'			, receiver._receiver.receiver_name)
					.replaceAll('[RECEIVER_USERNAME]'		, receiver._receiver.receiver_name.toLowerCase())
					.replaceAll('[RECEIVER_PASSWORD]'		, receiver._receiver.receiver_tel.slice(-4))

					// .replaceAll('[CLIENT_NAME]', this.state.receiver_name)
					// .replaceAll('[CLIENT_PASS]', this.state.receiver_tel.slice(-4))

					.replaceAll('[PROJECT_CODE]'			, props.projectId)
					.replaceAll('[PROJECT_TITLE]'			, '')
					.replaceAll('[PROJECT_TOKEN]'			, '<a href="' + global.config.url + "/#/ViewProject/" + props.projectId + "/" + props.taskId + "?prj_id=" + props.projectId + "&token=" + getToken() + '">here</a>')
					.replaceAll('[PROJECT_LINK]'			, '<a href="' + global.config.url + "/#/ViewProject/" + props.projectId + "/" + props.taskId + "?prj_id=" + props.projectId + "&token=" + getToken() + '">here</a>')
					.replaceAll('[PROJECT_ESTIMATE_LINK]'	, '<a href="' + global.config.url + "/#/ViewProjectTaskNote/" + props.projectId + "/" + receiver._receiver.receiver_id + "/3" + '">here</a>')
					.replaceAll('[PROJECT_QUOTE_LINK]'		, '<a href="' + global.config.url + "/#/ViewProjectTaskNote/" + props.projectId + "/" + receiver._receiver.receiver_id + "/6" + '">here</a>')
				, signature);
	}
	const fillTemplateTagsForSignature	=	(_templateContext, _signature)	=>{
		var _signatureContext	=	'[USER_NAME] ,[USER_DESC]\n[USER_TITLE]\nRENO BUILDING CANADA, INC.\nTELL  | [OFFICE_TEL]\n[OFFICE_INFO_EMAIL]';
		
			_signatureContext	=	_signatureContext
					.replaceAll('[USER_NAME]'				, cookie.get('v-name-signature') + ' ' + cookie.get('v-lastname-signature'))
					.replaceAll('[USER_DESC]'				, cookie.get('v-person-desc'))
					.replaceAll('[USER_TITLE]'				, cookie.get('v-title-signature'))
					.replaceAll('[USER_CELL]'				, cookie.get('v-cell-signature'))
					.replaceAll('[USER_TEL]'				, cookie.get('v-tel-signature'))
					.replaceAll('[USER_EMAIL]'				, cookie.get('v-email-signature'))
					.replaceAll('[USER_ORGANIZATION_TEL]'	, cookie.get('v-tel-organization'))
					.replaceAll('[USER_ORGANIZATION_EMAIL]'	, cookie.get('v-email-organization'))

					.replaceAll('[OFFICE_TEL]'				, "(416) 821-3733")
					.replaceAll('[OFFICE_INFO_EMAIL]'		, "info@renobuilding.ca")
					.replaceAll('[OFFICE_PROJECT_EMAIL]'	, "projects@renobuilding.ca");
					
		if(_signature)
			_templateContext	= _templateContext.replaceAll('[SIGNATURE]', _signatureContext);
		else
			_templateContext	= _templateContext.replaceAll('[SIGNATURE]', '');
			
		return _templateContext;
	}
    return(
        <>
		
        <ListGroup flush>
            <ListGroupItem>
                <>
                <Label>Assign To</Label><br />
                {/* <button type="button" className="btn btn-success btn-sm" onClick={(event) =>{onClockInHandler(event)} } >Clock In</button>{'	'}
                <button type="button" className="btn btn-danger btn-sm" onClick={(event) => {onClockOutHandler(event)}} >Clock Out</button>{'	'} */}
                Person Type : 
                <ComboBox onChange={(e)=>{setPersonType(e.target.value); setPersonTrade(-1);}} table="rb_types" filterField="rb_type" filterValue={'person_type'} valueField="rb_type_no" displayField="rb_type_desc" defaultValue={personType} orderby="rb_type_desc" />
                {personType*1 == 2 ? 
                    <>
                        Trade : 
                        <ComboBox onChange={(e)=>{setPersonTrade(e.target.value)}} table="rb_types" filterField="rb_type" filterValue={'person_trade'} valueField="rb_type_no" displayField="rb_type_desc" defaultValue={personTrade} orderby="rb_type_desc" />
                    </>
                :''}
                Person : &nbsp;&nbsp;
                {receiver? 
                    <small>
                        <Badge
                                data-project-id={props.projectId}
                                onClick={(e) => { this.quickEditeProject(e) }}
                                href="javascript:(0);" className="mr-1">
                                Edit
                        </Badge>
                    </small>
                :''}
                <br />
                <ComboBox onChange={(e) => { 
                (new DataAdapter("select * from rb_person where person_id = " + e.target.value )).getRecords().then(data=>{
                    var _receiver = {

                    receiver_id			:	data[0].person_id,
                    receiver_name		:	data[0].name,
                    receiver_lastname	:	data[0].lastname,
                    receiver_postal_code:	data[0].postal_code,
                    receiver_address	:	data[0].address,
                    receiver_city		:	data[0].city,
                    receiver_tel		:	data[0].person_tel,
                    receiver_email		:	data[0].email,
                    receiver_type		:	data[0].person_type,
                    };

                    setReceiver(prevState => { 
                        return {...prevState, _receiver};
                    });

                })

            }} table="rb_person" filterField="person_type" filterValue={personType + '\' AND if(user_expire_date is not null,user_expire_date>now(),true) AND \'x\'=\'x' + (personTrade*1 != -1? "' and person_trade='" + personTrade :'')} valueField="person_id" displayField="name,person_desc" orderby="name" />{'	'}
                    {receiver?
                        <small>
                            <b>Tel : </b> {receiver._receiver.receiver_tel} &nbsp;
                            {receiver._receiver.receiver_tel ? <img style={{cursor:'grab'}} height={'32'} src={iconPhone} onClick={(e) => {addNotification('Calling ...', 'info'); window.open('tel:' + receiver._receiver.receiver_tel, '_self');
                        var _taskDetail = {
                            pt_id		:	props.taskId, 
                            prj_id		:	props.projectId, 
                            task_type	:	117, 
                            task_status	:	10, 
                            sender_id	:	cookie.get("v-person-id"), 
                            receiver_id	:	cookie.get("v-person-id"), 
                            note		:	note, 
                            start_date	:	(new DateTime()).getUTC(), 
                            end_date	:	(new DateTime()).getUTC()
                        };
                        var taskDetail =new TaskDetail(); 
                        taskDetail.insert(_taskDetail).then(data=>{
                            addNotification('Call logged', 'info');
                            setReload(!reload);
                        });

                      }} /> 
                            : ''}
                        &nbsp;&nbsp;&nbsp;
                            <b>e-Mail : </b> {receiver._receiver.receiver_email} &nbsp;&nbsp;&nbsp;&nbsp; 
                        </small>
                    :''}
                    <br/>
					Task Type :
					<ComboBox onChange={(event) => { setTaskType(event.target.value) }} table="rb_types" filterField="rb_type" filterValue={"task_type' and rb_type_type_id='20"} valueField="rb_type_no" displayField="rb_type_desc" orderby="rb_type_desc" />{'	'}

                    <Button color="secondary" className="btn-block" onClick={(e)=>{assignTo(e); addNotification('Assigning a task ...', 'info');}} ><small>Assign</small></Button>{' '}

                </>
            </ListGroupItem>
            {receiver?
            <ListGroupItem>

                <Label>Notification by</Label> 
                    <br />
                    <ButtonGroup size="sm" >
                        <Button
                            color="primary"
                            onClick={() => onCheckboxBtnClick(1)}
                            active={notification.includes(1)}
                        >
                            SMS
                        </Button>
                        <Button
                            color="primary"
                            onClick={() => onCheckboxBtnClick(2)}
                            active={notification.includes(2)}
                        >
                            Email
                        </Button>
                    </ButtonGroup>
                    <br />

                {notification.includes(1) || notification.includes(2)?
                    <>
                        <Label>Message Tempates</Label> &nbsp;&nbsp;&nbsp;&nbsp;
                        <small>
                            <Badge
                                    data-project-id={props.projectId}
                                    onClick={(e) => { this.quickEditeProject(e) }}
                                    href="javascript:(0);" className="mr-1">
                                    Edit
                            </Badge>
                        </small>
                        <br />
                        <ComboBox onChange={onTemplateIdFieldChnageHandler} table="rb_templates" valueField="template_id" displayField="template_name" filterField="person_type" filterValue={personType} order="template_name" />														
                        <br />
                        <Label>Message Text</Label>
                        <img className='float-right mr-1' src={iconCopy} height={16} 
                            onClick={async (event) => { await navigator.clipboard.writeText(note); addNotification("Copied!", "info") }}
                            />

                        <label  className='float-right mr-1' htmlFor={"formFileMultiple"}>
                            <img src={iconAttach} height={16} 
                                />
                            <input className="form-control" style={{display: 'none'}} onChange={(event)=>{
								var files = event.target.files;
								for(var i=0; i < files.length ; i++){
									console.log(files[i]);
									const formData = new FormData()
									formData.append('myfile', files[i]);
								
									//fetch(_BACKEND_URL_ + '/file?project=' + this.state.projects.prj_folder_id, {
									fetch(_BACKEND_URL_ + '/attach?project=' + props.projectId, {
											method: 'POST',
									body: formData
									})
									.then(response => response.json())
									.then(data => {
										console.log(data)
										setAttachments(attachments + data.data.attach + ',');
										addNotification(data.data.name + ' File(s) Uploaded', "info");
										//window.location.reload();	
									})
									.catch(error => {
									console.error(error)
									});
								}
								
							}}  type="file" id={"formFileMultiple"}  accept="image/*" multiple data-original-title="upload photos" />
                        </label>
                        <img className='float-right mr-1' src={iconGoogleDrive} height={16} 
                            onClick={(event) => { addNotification("This module is not installed!", "info") }}
                            />
						
						<p>
						<small>{attachments}</small>
						</p>

                        <textarea style={{ width: '100%', height: '250px' }} onChange={(e) => {e.persist();  setMessage(e.target.value)}} value={messsage} />						
							<Switch height={18} width={34} onChange={()=>{setSignature(!signature)}} checked={signature} />{'	'}
						 <label>
							<small>Signature</small>
						 </label>						
                    <Row>
                        <Col>
                        <Button color="secondary" className="btn-block" onClick={(e)=>{
                            if(notification.includes(1)){
                                addNotification('Sending SMS ...', 'info'); 
                                onSendSMSHandler(messsage, receiver._receiver.receiver_tel);  
                                var _taskDetail = {
                                    pt_id		:	props.taskId, 
                                    prj_id		:	props.projectId, 
                                    task_type	:	116, 
                                    task_status	:	10, 
                                    sender_id	:	cookie.get("v-person-id"), 
                                    receiver_id	:	cookie.get("v-person-id"), 
                                    note		:	messsage, 
                                    start_date	:	(new DateTime()).getUTC(), 
                                    end_date	:	(new DateTime()).getUTC()
                                };
                                var taskDetail =new TaskDetail(); 
                                taskDetail.insert(_taskDetail).then(data=>{
                                    addNotification('SMS logged', 'info');
                                    setReload(!reload);
                                });
        
                            }
                            if(notification.includes(2)){
                                addNotification('Sending an email ...', 'info'); 
                                onSendEmailHandler(e);
                                var _taskDetail = {
                                    pt_id		:	props.taskId, 
                                    prj_id		:	props.projectId, 
                                    task_type	:	118, 
                                    task_status	:	10, 
                                    sender_id	:	cookie.get("v-person-id"), 
                                    receiver_id	:	cookie.get("v-person-id"), 
                                    note		:	messsage, 
                                    start_date	:	(new DateTime()).getUTC(), 
                                    end_date	:	(new DateTime()).getUTC()
                                };
                                var taskDetail =new TaskDetail(); 
                                taskDetail.insert(_taskDetail).then(data=>{
                                    addNotification('Email logged', 'info');
                                    setReload(!reload);
                                });

                            }
                            }} ><small>Send</small></Button>{' '}

                        </Col>
                    </Row>
                    </>

                :''}

            </ListGroupItem>
        :''}
        </ListGroup>

		<NotificationSystem ref={notificationSystem} style={NOTIFICATION_SYSTEM_STYLE}/>
    </>																		



    )
}

export default TaskAction;