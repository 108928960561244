class DateTime {
    constructor(){

    }
    getUTC =()=>{
        return new Date(new Date(this.getDate() + ' ' + this.getTime()).toUTCString()).toISOString().split('T')[0] + ' ' + new Date(new Date(this.getDate() + ' ' + this.getTime()).toUTCString()).toISOString().split('T')[1].slice(0, 5);
    }
    getDate=()=>{
		var today = new Date();		
		var date = today.toISOString().split('T')[0];
		return date;			 
	}
	getTime=()=>{
		var today = new Date();
		var time = today.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit", second: "2-digit", hour12: false }); //today.toISOString().split('T')[1].slice(0,5);
		return time;			 
	}

	// Convert a time in hh:mm format to minutes
	timeToMins = (time) => {
	  var b = time.split(':');
	  return b[0]*60 + +b[1];
	}

	// Convert minutes to a time in format hh:mm
	// Returned value is in range 00  to 24 hrs
	timeFromMins = (mins) => {
	  const z = (n)=>{return (n<10? '0':'') + n;}
	  var h = (mins/60 |0) % 24;
	  var m = mins % 60;
	  return z(h) + ':' + z(m);
	}

	// Add two times in hh:mm format
	addTimes = (t0, t1) => {
	  return this.timeFromMins(this.timeToMins(t0) + this.timeToMins(t1));
	}


	timestrToSec = (timestr) => {
	  var parts = timestr.split(":");
	  return (parts[0] * 3600) + (parts[1] * 60);
	}

	pad = (num)=>{
	  if(num < 10) {
		return "0" + num;
	  } else {
		return "" + num;
	  }
	}

	formatTime = (seconds)=>{
	  return [this.pad(Math.floor(seconds/3600)),
			  this.pad(Math.floor(seconds/60)%60)
			  ].join(":");
	}

}
export default DateTime;