function Store(initialState = {}) {
  this.state = initialState;
  const getState = ()=>{
	  return 'test';
  }
}
Store.prototype.mergeState = function(partialState) {
  Object.assign(this.state, partialState);
};

export default Store;