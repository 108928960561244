import React from 'react';

import 'jqwidgets-scripts/jqwidgets/styles/jqx.base.css';

import JqxGrid, { IGridProps, jqx } from 'jqwidgets-scripts/jqwidgets-react-tsx/jqxgrid';
import * as ReactDOM from 'react-dom';
import JqxButton from 'jqwidgets-scripts/jqwidgets-react-tsx/jqxbuttons';
import DataAdapter from '../adapter/DataAdapter';

const cellClass = (row, columnfield, value) => {
	var result = '';
	if(columnfield=='Payment_s'){
			result = 'payment';
		}
	
	if(columnfield=='Received_s'){
			result = 'received';
		}
	
	return result;
};

var view = require('vortex/config/view.json');

class VtxGrid extends React.Component {
	constructor(props){
		super(props);
		this.myGrid = React.createRef();
		this.addGroup = this.addGroup.bind(this);

		const data = '[{"NO": ""}]';
		const data_ = '[{"prj_status": 12, "rb_type_no": "12", "rb_type_desc":"-"}]';
		const source =
		{
			datafields: [
				{ name: 'NO', type: 'string' },
			],
			datatype: 'json',
			localdata: data
		};

		const source_ =
		{
			datafields: [
				{ name: 'prj_status', type: 'string' },
				{ name: 'rb_type_no', type: 'string' },
				{ name: 'rb_type_desc', type: 'string' }
			],
			datatype: 'json',
			localdata: data_
		};


		this.state = {
					width:'100%',
					columns: [
						{ text: 'NO', datafield: 'CompanyName'},
					],
					source: new jqx.dataAdapter(source),
					source_: new jqx.dataAdapter(source_, {autoBind:true}),
				};
	}

	componentDidMount(){
		this.myGrid.current.updatebounddata('data');
	}
	componentWillReceiveProps(nextProps) {
		if(JSON.stringify(this.props)!==JSON.stringify(nextProps)){
			const [datafields, columns] = this.getColumns(nextProps.data);
			const source =
			{
				datafields: datafields,
				datatype: 'json',
				localdata: nextProps.data
			};
			this.setState({ 
						columns: columns,
						source: new jqx.dataAdapter(source),
			 });
			//this.addGroup();		
		}
	}

	onCellendedit=(e)=> {
		if(this.props.onCellEdit)
			this.props.onCellEdit(e);
	}	
	onRowselect=(e)=> {
		if(this.props.onRowselect)
			this.props.onRowselect(e);
	}	

	getColumns = (data)=>{
		var datafields = [];
		var columns = [];
		if(this.props.view){
			view.map(v=>{
				if(v.name==this.props.view)
					v.fields.map(f=>{
						datafields.push({ name: f.name, type: 'string'});
						if(f.columntype=='combobox'){
							columns.push({
								cellclassname: cellClass, 
								text: f.label, 
								datafield: f.name,
								columntype: f.columntype,
								createeditor:(row, column, editor)=>{
									var dataAdapter =new DataAdapter(f.createeditor.query);
									dataAdapter.getRecords().then((data)=>{
										var source = {
											datafields: [
												{ name: f.createeditor.valueMember},
												{ name: f.createeditor.displayMember }
											],
											datatype: 'json',
											localdata: data
										};
										editor.jqxComboBox({ autoDropDownHeight: true, source: new jqx.dataAdapter(source), displayMember : f.createeditor.displayMember, valueMember: f.createeditor.valueMember, promptText: 'Please Choose:' });
									})
								},
								values: { source: this.state.source_.records , name: 'rb_type_desc' } 
								
							});			
						}else{
							columns.push({
								cellclassname: cellClass, 
								text: f.label, 
								datafield: f.name,
								filtertype: 'range',
								cellsformat: 'd'
							});			
						}
					})
					console.log(this.state.source_.records);
				})
		}else{
			if(!data.keys || data.length==0)
				return [datafields, columns];
			var keys = Object.keys(data[0]);
			for(var key of keys){
				if(key.indexOf("_h") == -1){
					datafields.push({ name: key, type: 'string'});
					columns.push({
						cellclassname: cellClass, 
						text: key.replace("_s",""), 
						datafield: key, 
						aggregates: [key.indexOf("_s") != -1?'sum':'']
					});	
				}
			}
		}
		console.log(columns)
		return [datafields, columns];
	}

	addfilter = (field, filtergroup)=>{
		this.myGrid.current.addfilter(field, filtergroup);
		this.myGrid.current.applyfilters();            

	}

	addGroup = () => {
		//this.myGrid.current.addgroup('aspect');
	  }	
	render(){
		return(
			<>
				<JqxGrid 
					ref={this.myGrid}
					theme={this.props.theme? this.props.theme : 'vortex'}
					columnsresize={true}
					autorowheight={true} 
					autoheight={true}
					width={this.state.width} 
					source={this.state.source} 
					columns={this.state.columns}
					sortable={true} 
					altrows={true}
					enabletooltips={true} 
					editable={this.props.editable? this.state.editable : true}
					editmode={'dblclick'}
					selectionmode={'singlerow'}

					autoshowfiltericon={true}
					filterable={true} 
					filtermode={this.state.filtermode? this.state.filtermode: 'default'}
					showfilterrow={true}

					groupable={this.props.groupable? this.props.groupable : true} 
					showaggregates={false} 
					showgroupaggregates={false}
					showstatusbar={false} 

					pageable={true} 
					pagesizeoptions={['5', '10', '50', '100','500']}
					pagermode={'default'}
					pagerbuttonscount={10}
					pagesize={20}

					showtoolbar={false}
					onCellendedit={this.onCellendedit}		
					onRowselect={this.onRowselect} 		
				 />
			</>
	
		)
	}

}

export default VtxGrid;