// Assets
import iconIsProgressing from 'assets/img/isprogressing.gif';
import iconRubbishBin from 'assets/img/icon/rubbish-bin.png';
import iconUser from 'assets/img/icon/avetar.jpg';
import iconTime from 'assets/img/icon/duration.png';

import React, {useState, useEffect} from "react";
import ReactHtmlParser from 'react-html-parser'; 

import {Progress,Input, Modal, ModalBody, ModalFooter, ModalHeader, Badge, Button, ButtonGroup, ButtonToolbar, Card, CardBody, CardHeader, CardImg, ListGroup, ListGroupItem, CardLink,CardText,CardTitle, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledButtonDropdown, CardFooter } from 'reactstrap';
import Task from '../Tasks/Task';
import TaskDetail from './TaskDetail';
import Invoice from '../Invoices/Invoice';
import Avatar from '../../../components/Avatar';
import DataAdapter from "../../adapter/DataAdapter";
import { Cookies } from 'react-cookie';

import NotificationSystem from 'react-notification-system';
import { NOTIFICATION_SYSTEM_STYLE } from 'utils/constants';

const cookie =new Cookies();

const __TASK_TYPE_NOTE__    	=   37;
const __TASK_TYPE_PURCHACE__	=   83;
const __TASK_TYPE_RM__    		=   93;
const __TASK_TYPE_CLOCK__   	=   98;
const __TASK_TYPE_TIMESHEET__   =   122;
const __TASK_TYPE_ALARM__    	=   113;
const __TASK_TYPE_SMS__     	=   116;
const __TASK_TYPE_CALL__    	=   117;
const __TASK_TYPE_EMAIL__   	=   118;
const __TASK_TYPE_REFERTODO__   =   92;


const getSource	=(_id)=>{
	var _src = require("assets/img/users/avetar.jpg");
	try{
		_src = require('assets/img/users/' + _id + '.jpg');
	}catch(err){}
	return _src;
}
const notificationSystem = React.createRef();
const addNotification = (_MESSAGE_, _TYPE_) => {
	const notification = notificationSystem.current;
	notification.addNotification({
	  message: _MESSAGE_,
	  level: _TYPE_
	});
};	


const onChangeTaskStatus = async (_id, _status) =>{
	//setIsProgressing(true);
	const _taskDetail	=new TaskDetail();
	const response = await _taskDetail.changeStatus(_id, _status);
	if(response){
		//setIsProgressing(false);
		//addNotification("Task status changed", "info");
		//props.callback({...{callbackType:'status', ...{response : response}}});
	};

}

function TaskDetailCard(props){
	const [isProgressing, setIsProgressing] = useState(false);
	const [status, setStatus] = useState(-1);
	const [description, setDescription] = useState(props.taskDetail.task_description);
	const callback	=	(response)	=>{
		setIsProgressing(false);
	}
	useEffect(()=>{
		//setDescription();
		setStatus(props.taskDetail.task_status)
	}, []);
    return(
        <>
            {props.taskDetail && props.taskDetail.task_type_id*1 == __TASK_TYPE_REFERTODO__  ?
            <div>
                <Card>
                    <CardBody>
                        <CardTitle className="text-capitalize">
							<Row>
								<Col>
										<div className='float-right' >
											<Badge className='float-right' color="info" title="" >{props.taskDetail.receiver_id}</Badge><br/>
											<small>{props.taskDetail.start_date}</small> <br />
										</div>
										<Badge color="info" title="" >{props.taskDetail.task_type}</Badge><br />
										<small>{props.taskDetail.task}</small><br />
										<small><a>{props.taskDetail.task_description}</a></small>
								</Col>
							</Row>
                        </CardTitle>
                    </CardBody>
                </Card>
                <hr />
            </div>
           :''}		   
            {props.taskDetail && props.taskDetail.task_type_id*1 == __TASK_TYPE_NOTE__  ?
            <div>
				<Row>
					<Col>
						<small>{props.taskDetail.start_date}</small> &nbsp;&nbsp;
						  <ButtonGroup size="sm" className="float-right"  >
							  {props.taskDetail.sender_id==cookie.get("v-person-id")? <Badge style={{cursor:'pointer'}} role="button" color="dark" className="ml-1" onClick={(e)=>{setIsProgressing(true);(new TaskDetail()).deleteById(props.taskDetail.ptd_id).then((response)=>{callback(response);props.callback(response);})}} >Delete</Badge>:''}
							  {props.taskDetail.task_status*1==8? <Badge color="warning" className="ml-1" >UnRead</Badge>:<Badge style={{cursor:'pointer'}} color="light" onClick={(e)=>{setIsProgressing(true);(new TaskDetail()).changeStatus(props.taskDetail.ptd_id , 8).then((response)=>{callback(response);props.callback(response);});}} >UnRead</Badge>}
							  {props.taskDetail.task_status*1==9? <Badge color="info" className="ml-1" >Read</Badge>:<Badge style={{cursor:'pointer'}} color="light" onClick={(e)=>{setIsProgressing(true);(new TaskDetail()).changeStatus(props.taskDetail.ptd_id , 9).then((response)=>{callback(response);props.callback(response);});}} >Read</Badge>}
							  {props.taskDetail.task_status*1==10? <Badge color="success" className="ml-1 d-none" >Done</Badge>:<Badge style={{cursor:'pointer'}} color="light" onClick={(e)=>{setIsProgressing(true);(new TaskDetail()).changeStatus(props.taskDetail.ptd_id , 10).then((response)=>{callback(response);props.callback(response);});}} >Done</Badge>}
							  {props.taskDetail.task_status*1==11? <Badge color="dark" className="ml-1 d-none" >Closed</Badge>:<Badge style={{cursor:'pointer'}} color="light" onClick={(e)=>{setIsProgressing(true);(new TaskDetail()).changeStatus(props.taskDetail.ptd_id , 11).then((response)=>{callback(response);props.callback(response);});}} >Closed</Badge>}
						  </ButtonGroup><br/>
							<div className='float-left' >
								<Avatar src={getSource(props.taskDetail.sender_id)} />&nbsp;&nbsp;
								<small>{props.taskDetail.note}</small>
								<small>{props.taskDetail.attachments && props.taskDetail.attachments!='undefined' && props.taskDetail.attachments!='null' && props.taskDetail.attachments!='0'?<a href={'https://drive.google.com/open?id=' + props.taskDetail.attachments  } target="_blank"><img className="border border-secondary rounded m-1 p-1" height={100} src={'https://drive.google.com/thumbnail?id=' + props.taskDetail.attachments + '&sz=w100' } /></a>:''}</small>
							</div>
							<div className='float-right' >
								<Avatar src={getSource(props.taskDetail.receiver_id)} />&nbsp;&nbsp;
							</div>
					</Col>
				</Row>
                <hr />
            </div>
           :''}		   
            {props.taskDetail && props.taskDetail.task_type_id*1 == __TASK_TYPE_ALARM__  ?
            <div>
				<Row>
					<Col>
						<small>{props.taskDetail.start_date}</small> &nbsp;&nbsp;
						  <ButtonGroup size="sm"  className="float-right" >
							  {props.taskDetail.sender_id==cookie.get("v-person-id")? <Badge style={{cursor:'pointer'}} role="button" color="dark" className="ml-1" onClick={(e)=>{setIsProgressing(true);(new TaskDetail()).deleteById(props.taskDetail.ptd_id).then((response)=>{callback(response);props.callback(response);})}} >Delete</Badge>:''}
							  {props.taskDetail.task_status*1==8? <Badge color="warning" className="ml-1" >To Schedule</Badge>:<Badge style={{cursor:'pointer'}} color="light" onClick={(e)=>{setIsProgressing(true);(new TaskDetail()).changeStatus(props.taskDetail.ptd_id , 8).then((response)=>{callback(response);props.callback(response);});}} >To Schedule</Badge>}
							  {props.taskDetail.task_status*1==9? <Badge color="info" className="ml-1" >Scheduled</Badge>:<Badge style={{cursor:'pointer'}} color="light" onClick={(e)=>{setIsProgressing(true);(new TaskDetail()).changeStatus(props.taskDetail.ptd_id , 9).then((response)=>{callback(response);props.callback(response);});}} >Scheduled</Badge>}
							  {props.taskDetail.task_status*1==10? <Badge color="success" className="ml-1" >Done</Badge>:<Badge style={{cursor:'pointer'}} color="light" onClick={(e)=>{setIsProgressing(true);(new TaskDetail()).changeStatus(props.taskDetail.ptd_id , 10).then((response)=>{callback(response);props.callback(response);});}} >Done</Badge>}
							  {props.taskDetail.task_status*1==11? <Badge color="dark" className="ml-1" >Closed</Badge>:<Badge style={{cursor:'pointer'}} color="light" onClick={(e)=>{setIsProgressing(true);(new TaskDetail()).changeStatus(props.taskDetail.ptd_id , 11).then((response)=>{callback(response);props.callback(response);});}} >Closed</Badge>}
						  </ButtonGroup><br/>
							<div className='float-left' >
								<Avatar src={getSource(props.taskDetail.sender_id)} />&nbsp;&nbsp;
								<small>{props.taskDetail.note}</small>
								<small>{props.taskDetail.expected_start_date}</small>
							</div>
							<div className='float-right' >
								<Avatar src={getSource(props.taskDetail.receiver_id)} />&nbsp;&nbsp;
							</div>
					</Col>
				</Row>
                <br />
            </div>
           :''}		   
            {props.taskDetail && (props.taskDetail.task_type_id*1 == __TASK_TYPE_RM__ )   ?			
            <div>
				<Card className="flex-row">
					<CardImg
					  className="card-img-left"
					  src={'https://drive.google.com/uc?export=view&id=' + props.taskDetail.attachments}
					  style={{ width: 'auto', height: 150 }}
					/>
					<CardBody>
					  <CardTitle>{props.taskDetail.task_type}</CardTitle>
					  <CardText>
						<Input type="textarea" onChange={(e)=>{setDescription(e.target.value)}} value={description} />
						<br />
						<Button className="btn-sm" onClick={(e)=>{
							e.persist();
							var _query	= "UPDATE rb_project_task_details SET task_description = '" + description + "' WHERE ptd_id = " + props.taskDetail.ptd_id;
							
							var dataAdapter = new DataAdapter(_query);
								dataAdapter.getRecords()
								.then((data)=>{
									addNotification("Record has been updated!", "info");
								});
							
						}} >Update</Button>
					  </CardText>
					</CardBody>
					<CardFooter>
						<Avatar src={getSource(props.taskDetail.sender_id)} /><br/>
						{props.taskDetail.sender}<br/>
						<img height={16} src={iconTime} /><small>{props.taskDetail.start_date}</small>
					</CardFooter>
				</Card>
            </div>
           :''}
            {props.taskDetail && ( props.taskDetail.task_type_id*1 == __TASK_TYPE_PURCHACE__)   ?
            <div>
                <Card>
                    <CardBody>
                        <CardTitle className="text-capitalize">
							<Row>
								<Col>
									<img src={'https://drive.google.com/uc?export=view&id=' + props.taskDetail.attachments + '/'} />
										<div className='float-right' >
											<Badge className='float-right' color="danger" title="" >{props.taskDetail.sender}</Badge><br/>
											<small>{props.taskDetail.start_date}</small> <br />
										</div>
										<Badge color="danger" title="" >{props.taskDetail.task_type}</Badge><br />
										<small>{props.taskDetail.task}</small><br/>
										<small>{props.taskDetail.task_description}</small>
										<a target="_blank" href={'https://drive.google.com/open?id=' + props.taskDetail.attachments} >View</a>
										<br/>
					                  <Button onClick={(e)=>{	var _invoice	=new Invoice();_invoice.openReceiveAndPayment(); }} >Convert to RnP</Button>

								</Col>
							</Row>
                        </CardTitle>
                    </CardBody>
                </Card>
                <br />
            </div>
           :''}
            {props.taskDetail && props.taskDetail.task_type_id*1 == __TASK_TYPE_SMS__  ?
            <div>
                <Card>
                    <CardBody>
                        <CardTitle className="text-capitalize">
                                <>
                                    <div className='float-right' >
                                        <Badge className='float-right' color="success" title="" >{props.taskDetail.sender}</Badge><br/>
                                        <small>{props.taskDetail.start_date}</small> <br />
                                        <a href='javascript:void(0)' onClick={(e)=>{
                                            e.persist();
                                            var taskDetail =new TaskDetail();
                                            if(window.confirm("Are you sure?"))
                                                taskDetail.deleteById(e.target.attributes["data-id"].nodeValue).then(data=>{
                                                    props.reload();
                                                });
                                            }} ><img data-id={props.taskDetail.ptd_id} src={iconRubbishBin} width={16} /></a>
                                    </div>
									<Badge color="success" title="" >{props.taskDetail.task_type}</Badge><br />
                                    <small>{props.taskDetail.note}</small> 
									<hr/>
									<h6>PROJECT DETAIL</h6>
                                    <small><b>{props.taskDetail.prj_id} - {props.taskDetail.prj_title}</b></small><br/>
                                    <small>{props.taskDetail.prj_address}</small>									
                                </>
                        </CardTitle>
                    </CardBody>
                </Card>
                <br />
            </div>
           :''}
            {props.taskDetail && props.taskDetail.task_type_id*1 == __TASK_TYPE_CALL__  ?
            <div>
                <Card>
                    <CardBody>
                        <CardTitle>
                                <>
                                    <div className='float-right' >
                                        <Badge className='float-right' color="light" title="" >{props.taskDetail.sender}</Badge><br/>
                                        <small>{props.taskDetail.start_date}</small> <br />
                                        <a href='javascript:void(0)' onClick={(e)=>{
                                            e.persist();
                                            var taskDetail =new TaskDetail();
                                            if(window.confirm("Are you sure?"))
                                                taskDetail.deleteById(e.target.attributes["data-id"].nodeValue).then(data=>{
                                                    props.reload();
                                                });
                                            }} ><img data-id={props.taskDetail.ptd_id} src={iconRubbishBin} width={16} /></a>
                                    </div>
									<Badge color="light" title="" >{props.taskDetail.task_type}</Badge><br />                                    
									<div> <small>{ ReactHtmlParser (props.taskDetail.note) } </small></div>
									<hr/>
									<h6>PROJECT DETAIL</h6>
                                    <small><b>{props.taskDetail.prj_id} - {props.taskDetail.prj_title}</b></small><br/>
                                    <small>{props.taskDetail.prj_address}</small>									
                                </>
                        </CardTitle>
                    </CardBody>
                </Card>
                <br />
            </div>
           :''}
            {props.taskDetail && props.taskDetail.task_type_id*1 == __TASK_TYPE_EMAIL__  ?
            <div>
                <Card>
                    <CardBody>
                        <CardTitle className="text-capitalize">
                                <>
                                    <div className='float-right' >
                                        <Badge className='float-right' color="warning" title="" >{props.taskDetail.sender}</Badge><br/>
                                        <small>{props.taskDetail.start_date}</small> <br />
                                        <a href='javascript:void(0)' onClick={(e)=>{
                                            e.persist();
                                            var taskDetail =new TaskDetail();
                                            if(window.confirm("Are you sure?"))
                                                taskDetail.deleteById(e.target.attributes["data-id"].nodeValue).then(data=>{
                                                    props.reload();
                                                });
                                            }} ><img data-id={props.taskDetail.ptd_id} src={iconRubbishBin} width={16} /></a>
                                    </div>
									<Badge color="warning" title="" >{props.taskDetail.task_type}</Badge><br />
                                    <small>{props.taskDetail.note}</small> 
									<hr/>
									<h6>PROJECT DETAIL</h6>
                                    <small><b>{props.taskDetail.prj_id} - {props.taskDetail.prj_title}</b></small><br/>
                                    <small>{props.taskDetail.prj_address}</small>									
                                </>
                        </CardTitle>
                    </CardBody>
                </Card>
                <br />
            </div>
           :''}
            {props.taskDetail && props.taskDetail.task_type_id*1 == __TASK_TYPE_CLOCK__  ?
            <div>
                <Card>
                    <CardBody>
                        <CardTitle className="text-capitalize">
                                <>
                                    <div className='float-right' >
                                        <Badge className='float-right' color="dark" title="" >{props.taskDetail.sender}</Badge><br/>
                                        <small>{props.taskDetail.start_date}</small>{'      '} <br />
                                        <a href='javascript:void(0)' onClick={(e)=>{
                                            e.persist();
                                            var taskDetail =new TaskDetail();
                                            if(window.confirm("Are you sure?"))
                                                taskDetail.deleteById(e.target.attributes["data-id"].nodeValue).then(data=>{
                                                    props.reload();
                                                });
                                            }} ><img data-id={props.taskDetail.ptd_id} src={iconRubbishBin} width={16} /></a>
                                    </div>
									<Badge color="dark" title="" >{props.taskDetail.task_type}</Badge><br />
                                    <small>{props.taskDetail.Clock_in} - {props.taskDetail.Clock_out? props.taskDetail.Clock_out: ' ... '}</small> <br /> 
                                    <small>{props.taskDetail.note}</small> 
									<hr/>
									<h6>PROJECT DETAIL</h6>
                                    <small><b>{props.taskDetail.prj_id} - {props.taskDetail.prj_title}</b></small><br/>
                                    <small>{props.taskDetail.prj_address}</small>
									
                                </>
                        </CardTitle>
                    </CardBody>
                </Card>
                <br />
            </div>
           :''}
            {props.taskDetail && props.taskDetail.task_type_id*1 == __TASK_TYPE_TIMESHEET__  ?
            <div>
                <Card>
                    <CardBody>
                        <CardTitle className="text-capitalize">
                                <>
                                    <div className='float-right' >
                                        <Badge className='float-right' color="dark" title="" >{props.taskDetail.sender}</Badge><br/>
                                        <small>{props.taskDetail.start_date}</small>{'      '} <br />
                                        <a href='javascript:void(0)' onClick={(e)=>{
                                            e.persist();
                                            var taskDetail =new TaskDetail();
                                            if(window.confirm("Are you sure?"))
                                                taskDetail.deleteById(e.target.attributes["data-id"].nodeValue).then(data=>{
                                                    props.reload();
                                                });
                                            }} ><img data-id={props.taskDetail.ptd_id} src={iconRubbishBin} width={16} /></a>
                                    </div>
									<Badge color="dark" title="" >{props.taskDetail.task_type}</Badge><br />
                                    <small>{props.taskDetail.Clock_in} - {props.taskDetail.Clock_out? props.taskDetail.Clock_out: ' ... '}</small> <br /> 
                                    <small>{props.taskDetail.note}</small> 
									
                                </>
                        </CardTitle>
                    </CardBody>
                </Card>
                <br />
            </div>
           :''}

		   {isProgressing? <img height="32" src={iconIsProgressing} />:''}
			<NotificationSystem ref={notificationSystem} style={NOTIFICATION_SYSTEM_STYLE}/>
        </>
    )
}
export default TaskDetailCard;