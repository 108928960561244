export const init = {	
	rowClass		:	"",
	collaps			:	true,
	primaryKey		:	'Col1',
	hasHeader		:	true,
	hasPager		:	true,
	pageSize		:	5,
	canDelete		:	true,
	canUpdate		:	true,
	aggregations	:	['Col2'],
	columns			:	[
		{id:'Col1'}, 
		{id:'Col2', type:'text'}, 
		{id:'Col3', type:'select', queryString :'SELECT prj.prj_id, prj.prj_code, prj.prj_title FROM rb_projects prj WHERE prj.prj_status=9 AND prj.prj_department=12 ORDER BY prj.prj_code', valueField:'prj_id', displayField:'prj_title'}, 
		{id:'status', type:'checkbox'},
		{id:'date', type:'date'},
		{id:'time', type:'time'},
		],
	records:[
		{Col1:'11', 'Col2':12, 'Col3':825, status:true},
		{Col1:'21', 'Col2':22, 'Col3':818, status:false},
		{Col1:'31', 'Col2':32, 'Col3':779, status:true},
		{Col1:'11', 'Col2':12, 'Col3':825, status:true},
		{Col1:'21', 'Col2':22, 'Col3':818, status:false},
		{Col1:'31', 'Col2':32, 'Col3':779, status:true},
		{Col1:'11', 'Col2':12, 'Col3':825, status:true},
		{Col1:'21', 'Col2':22, 'Col3':818, status:false},
		{Col1:'31', 'Col2':32, 'Col3':779, status:true},
		{Col1:'11', 'Col2':12, 'Col3':825, status:true},
		{Col1:'21', 'Col2':22, 'Col3':818, status:false},
		{Col1:'31', 'Col2':32, 'Col3':779, status:true},
		{Col1:'11', 'Col2':12, 'Col3':825, status:true},
		{Col1:'21', 'Col2':22, 'Col3':818, status:false},
		{Col1:'31', 'Col2':32, 'Col3':779, status:true},
		{Col1:'11', 'Col2':12, 'Col3':825, status:true},
		{Col1:'21', 'Col2':22, 'Col3':818, status:false},
		{Col1:'31', 'Col2':32, 'Col3':779, status:true},
		{Col1:'11', 'Col2':12, 'Col3':825, status:true},
		{Col1:'21', 'Col2':22, 'Col3':818, status:false},
		{Col1:'31', 'Col2':32, 'Col3':779, status:true},
	],
	detailCollections:[
		{details:[
				{Col1:'11', Col2:'12', Col3:'13'},
				{Col1:'21', Col2:'22', Col3:'23'},
				{Col1:'31', Col2:'32', Col3:'33'},
		]},
		{details:[
				{Col1:'11', Col2:'-', Col3:'-'},
				{Col1:'21', Col2:'-', Col3:'-'},
				{Col1:'31', Col2:'-', Col3:'3-3'},
		]},
		
	]
};