import React,{useState, Suspense} from "react";
import ReactDOM from 'react-dom';
import DataAdapter from "../../adapter/DataAdapter";
import Dialog from "../../component/Dialog";

import { Card, CardBody, CardHeader, CardImg, CardLink, CardText, CardTitle, Col, Input, ListGroup, ListGroupItem, Row, Modal, ModalBody, ModalFooter, ModalHeader, Button, FormGroup, Label} from 'reactstrap';

class Task {
    constructor(){

    }
    selectById = async (_id) =>{
        const _query    =   "select * from rb_project_tasks where pt_id = " + _id;
        var dataAdapter =new DataAdapter(_query);
        var data = await dataAdapter.getRecords();
        return data;
    }
    selectByParent = async (_id, _type) =>{		
        //const _query    =   "select pt.pt_id as `Id`, pt.prj_id as `prj_id-`, pt.task as `Task`, pt.task_description as `task_description-`, pt.task_status as `task_status-`, ty.rb_type_desc as `Type`, pt.sender_id as `sender_id-`, pt.receiver_id as `receiver_id-`, pt.estimate_cost AS `Est. Cost`, sum(ptd.real_cost) AS `Rl. Cost`, pt.task_weight as `Weight` from rb_project_tasks pt left join rb_project_task_details ptd on ptd.pt_id = pt.pt_id inner join rb_types ty on (ty.rb_type_no=pt.task_type and ty.rb_type='task_type') where pt.parent = " + _id + " group by pt.pt_id;";
        const _query    =   "select pt.* from rb_project_tasks pt where pt.parent = " + _id + " ;";
        var dataAdapter =new DataAdapter(_query);
        var data = await dataAdapter.getRecords();
        return data;
    }
    selectByTaskType = async (_prj_id, _task_type) =>{		
        const _query    =   "select pt.pt_id as `Id`, rnp.amount AS `Rl. Cost+`, rnp.rp_cat, rnp.rp_date, rnp.rp_desc, rnp.bank_id, rnp.person_id, rnp.is_done, rnp.image_id from rb_project_tasks pt left join rb_receive_n_payments rnp on rnp.pt_id = pt.pt_id inner join rb_types ty on (ty.rb_type_no=pt.task_type and ty.rb_type='task_type') where pt.prj_id >= " + _prj_id + " AND pt.task_type " + _task_type ;
        var dataAdapter =new DataAdapter(_query);
        var data = await dataAdapter.getRecords();
        return data;
    }
    selectByTaskTypeGroup = async (_prj_id, _task_type) =>{		
        const _query    =   "select pt.pt_id as `Id`, pt.prj_id as `prj_id`, pt.task_type AS `TypeId`, pt.task as `Task`, pt.task_description as `task_description`, pt.task_status as `task_status`, ty.rb_type_desc as `Type`, pt.sender_id as `sender_id`, pt.receiver_id as `receiver_id`, pt.estimate_cost AS `Est. Cost`, sum(rnp.amount) AS `Rl. Cost`, pt.task_weight as `Weight` from rb_project_tasks pt left join rb_receive_n_payments rnp on rnp.pt_id = pt.pt_id inner join rb_types ty on (ty.rb_type_no=pt.task_type and ty.rb_type='task_type') where pt.prj_id = " + _prj_id + " AND pt.task_type " + _task_type + " group by pt.pt_id;";
        //const _query    =   "select pt.pt_id as `Id`, pt.prj_id as `prj_id-`, pt.task as `Task`, pt.task_description as `task_description-`, pt.task_status as `task_status-`, ty.rb_type_desc as `Type`, pt.sender_id as `sender_id-`, pt.receiver_id as `receiver_id-`, pt.estimate_cost AS `Est. Cost+`, rnp.amount AS `Rl. Cost+`, rnp.rp_cat, rnp.rp_date, rnp.rp_desc, rnp.bank_id, rnp.person_id, rnp.is_done, pt.task_weight as `Weight` from rb_project_tasks pt left join rb_receive_n_payments rnp on rnp.pt_id = pt.pt_id inner join rb_types ty on (ty.rb_type_no=pt.task_type and ty.rb_type='task_type') where pt.prj_id >= " + _prj_id + " AND pt.task_type " + _task_type ;
        var dataAdapter =new DataAdapter(_query);
        var data = await dataAdapter.getRecords();
        return data;
    }
    viewById = async (_id) =>{
        const _query    =   "select pt_id, ty.rb_type_desc as task_type, task, task_description, tyy.rb_type_desc as task_status, sender_id, note from rb_project_tasks pt inner join rb_types ty on (ty.rb_type_no=pt.task_type and ty.rb_type = 'task_type') inner join rb_types  tyy on (tyy.rb_type_no=pt.task_status and tyy.rb_type='task_status') where pt_id = " + _id;
        var dataAdapter =new DataAdapter(_query);
        var data = await dataAdapter.getRecords();
        return data;
    }

    update = async (_id, _field, _value) =>{
        const _query    =   "UPDATE rb_project_tasks SET " + _field + " = '" + _value + "' WHERE pt_id=" + _id + ";\r\nSELECT pt.pt_id, pt.prj_id, pt.task_type, ty.rb_type_desc AS task_type_desc, pt.task_status, tyy.rb_type_desc AS task_status_desc FROM rb_project_tasks pt INNER JOIN rb_types ty ON (ty.rb_type='task_type' AND ty.rb_type_no = pt.task_type) INNER JOIN rb_types tyy ON (tyy.rb_type='task_status' AND tyy.rb_type_no = pt.task_status) WHERE pt_id = " + _id + ";";
        var dataAdapter =new DataAdapter(_query);
        var data = await dataAdapter.getRecords();
        return data;
    }

    changeStatus = async (_id, _status) =>{
        const _query    =   "UPDATE rb_project_tasks SET task_status=" + _status + ", end_date=now() where pt_id = " + _id + ";\r\nSELECT pt.pt_id, pt.prj_id, pt.task_type, ty.rb_type_desc AS task_type_desc, pt.task_status, tyy.rb_type_desc AS task_status_desc FROM rb_project_tasks pt INNER JOIN rb_types ty ON (ty.rb_type='task_type' AND ty.rb_type_no = pt.task_type) INNER JOIN rb_types tyy ON (tyy.rb_type='task_status' AND tyy.rb_type_no = pt.task_status) WHERE pt_id = " + _id + ";";
        var dataAdapter =new DataAdapter(_query);
        var data = await dataAdapter.getRecords();
        return data;
    }
    changeType			=	async (_id, _type) =>{
        const _query    =   "UPDATE rb_project_tasks SET task_type=" + _type + " where pt_id = " + _id + ";\r\nSELECT pt.pt_id, pt.prj_id, pt.task_type, ty.rb_type_desc AS task_type_desc, pt.task_status, tyy.rb_type_desc AS task_status_desc FROM rb_project_tasks pt INNER JOIN rb_types ty ON (ty.rb_type='task_type' AND ty.rb_type_no = pt.task_type) INNER JOIN rb_types tyy ON (tyy.rb_type='task_status' AND tyy.rb_type_no = pt.task_status) WHERE pt_id = " + _id + ";";
       var dataAdapter =new DataAdapter(_query);
		var data = await dataAdapter.getRecords();
        return data;
    }
	
	openQuickTaskForm	=	async(_project)	=>{
		const _return = await new Promise((resolve, rej) => {
			const div	=	document.createElement('div');
			document.body.appendChild(div);
			const FormQuickTask = React.lazy(() => import('./FormQuickTask'));
			ReactDOM.render(
				<Suspense fallback={<div>Loading... </div>}>
						<FormQuickTask projectId={_project} callback={(obj)=>resolve(obj)} />
				</Suspense>
			, div);					
		});
	}
	
	openAssignTask		=	async(_project)	=>{
		const _return = await new Promise((resolve, rej) => {
			const div	=	document.createElement('div');
			document.body.appendChild(div);
			const FormAssignTask = React.lazy(() => import('./FormAssignTask'));
			ReactDOM.render(
				<Suspense fallback={<div>Loading... </div>}>
						<FormAssignTask project={_project} callback={(obj)=>{resolve(obj);}} />
				</Suspense>
			, div);		
			
		});
	
		return _return;
	}

	openScopeOfWorks	=	async(_project, _parent, _receiver)	=>{
		const _return = await new Promise((resolve, rej) => {
			const div	=	document.createElement('div');
			document.body.appendChild(div);
			const FormScopeOfWorks = React.lazy(() => import('./FormScopeOfWorks'));
			ReactDOM.render(
				<Suspense fallback={<div>Loading... </div>}>
						<FormScopeOfWorks project={_project} parent={_parent} receiver={_receiver} callback={(obj)=>{resolve(obj);}} />
				</Suspense>
			, div);		
			
		});
	
		return _return;
	}

	openAddNote		=	async(_project)	=>{
		const _return = await new Promise((resolve, rej) => {
			const div	=	document.createElement('div');
			document.body.appendChild(div);
			const FormAddNote = React.lazy(() => import('./FormAddNote'));
			ReactDOM.render(
				<Suspense fallback={<div>Loading... </div>}>
					<FormAddNote project={_project} callback={(obj)=>resolve(obj)} />
				</Suspense>
			, div);		
			
		});
	
		return _return;
	}
	
	openAlarm		=	async(_project)	=>{
		const _return = await new Promise((resolve, rej) => {
			const div	=	document.createElement('div');
			document.body.appendChild(div);
			const FormAlarm = React.lazy(() => import('./FormAlarm'));
			ReactDOM.render(
				<Suspense fallback={<div>Loading... </div>}>
					<FormAlarm project={_project} callback={(obj)=>resolve(obj)} />
				</Suspense>
			, div);		
			
		});
	
		return _return;
	}
	
	ViewTask		=	async (_task)	=>{
		var isProgressing = false;
		const callback	=	(response)	=>{
			isProgressing = true;
			//alert(response);
		}
		const _return = await new Promise((resolve, rej) => {
			const div	=	document.createElement('div');
			document.body.appendChild(div);
			const ViewTask = React.lazy(() => import('./ViewTask'));
			ReactDOM.render(
				<Suspense fallback={<div>Loading... </div>}>
					<Dialog title={"Project " + _task.prj_id } isOpen={true} isProgressing={isProgressing} notify="" >
						<ViewTask project={_task} projectId={_task.prj_id} taskId={_task.pt_id} callback={(response)=>{callback(response);resolve(response);}} />
					</Dialog>
				</Suspense>
			, div);		
		});
	
		return _return;
	}
	viewTaskUpdateForm	= async	(_task)	=>{
		
		const _return = await new Promise((resolve, rej) => {
			const div	=	document.createElement('div');
			document.body.appendChild(div);
			const FormTaskUpdate = React.lazy(() => import('./FormTaskUpdate'));
			ReactDOM.render(
				<Suspense fallback={<div>Loading... </div>}>
					<FormTaskUpdate task={_task} callback={(obj)=>resolve(obj)} />
				</Suspense>
			, div);
		});
	
		return _return;
	}	

    clockIn = async () =>{
    }
    clockOut = async (senderId, taskId) =>{
		const _return = await new Promise((resolve, rej) => {
			const div	=	document.createElement('div');
			document.body.appendChild(div);
			const FormClockOut = React.lazy(() => import('../TaskDetails/FormClockOut'));
			ReactDOM.render(
				<Suspense fallback={<div>Loading... </div>}>
					<FormClockOut senderId={senderId} taskId={taskId} callback={(obj)=>resolve(obj)} />
				</Suspense>
			, div);
		});
	
		return _return;
    }

	ViewTaskOfPerson	= async	()	=>{
		
		const _return = await new Promise((resolve, rej) => {
			const div	=	document.createElement('div');
			document.body.appendChild(div);
			const ViewTaskOfPerson = React.lazy(() => import('../../ViewTaskOfPerson'));
			ReactDOM.render(
				<Suspense fallback={<div>Loading... </div>}>
					<Dialog title="Active Tasks" isOpen={true} isProgressing={false} notify="" >
						<ViewTaskOfPerson status="1,2,3,4,5,6,7,8,9,10" callback={(obj)=>resolve(obj)} />
					</Dialog>
				</Suspense>
			, div);
		});
	
		return _return;
	}	
	ViewTaskOfGantt	= async	(_task)	=>{
		
		const _return = await new Promise((resolve, rej) => {
			const div	=	document.createElement('div');
			document.body.appendChild(div);
			const FormGanttTask = React.lazy(() => import('./FormGanttTask'));
			ReactDOM.render(
				<Suspense fallback={<div>Loading... </div>}>
						<FormGanttTask task={_task} callback={(obj)=>resolve(obj)} />
				</Suspense>
			, div);
		});
	
		return _return;
	}
	
	ViewEstimate	= async	(_projectId)	=>{
		
		const _return = await new Promise((resolve, rej) => {
			const div	=	document.createElement('div');
			document.body.appendChild(div);
			const ViewEstimate = React.lazy(() => import('../Estimate/ViewEstimate'));
			ReactDOM.render(
				<Suspense fallback={<div>Loading... </div>}>
						<ViewEstimate projectId={_projectId} />
				</Suspense>
			, div);
		});
	
		return _return;
	}	

	ViewScopeOfWork	= async	(_projectId)	=>{
		
		const _return = await new Promise((resolve, rej) => {
			const div	=	document.createElement('div');
			document.body.appendChild(div);
			const ViewEstimate = React.lazy(() => import('../Estimate/ViewEstimate'));
			ReactDOM.render(
				<Suspense fallback={<div>Loading... </div>}>
						<ViewEstimate projectId={_projectId} />
				</Suspense>
			, div);
		});
	
		return _return;
	}	

	ViewInvoice	= async	(_projectId)	=>{
		
		const _return = await new Promise((resolve, rej) => {
			const div	=	document.createElement('div');
			document.body.appendChild(div);
			const ViewInvoice = React.lazy(() => import('../Invoice/ViewInvoice'));
			ReactDOM.render(
				<Suspense fallback={<div>Loading... </div>}>
						<ViewInvoice projectId={_projectId} />
				</Suspense>
			, div);
		});
	
		return _return;
	}	

	ViewBill	= async	(_projectId)	=>{
		
		const _return = await new Promise((resolve, rej) => {
			const div	=	document.createElement('div');
			document.body.appendChild(div);
			const ViewBillManager = React.lazy(() => import('../Accounting/ViewBillManager'));
			ReactDOM.render(
				<Suspense fallback={<div>Loading... </div>}>
						<ViewBillManager projectId={_projectId} />
				</Suspense>
			, div);
		});
	
		return _return;
	}	

	ViewBillDetail	= async	(_projectId)	=>{
		
		const _return = await new Promise((resolve, rej) => {
			const div	=	document.createElement('div');
			document.body.appendChild(div);
			const ViewBillDetail = React.lazy(() => import('../Accounting/ViewBillDetail'));
			ReactDOM.render(
				<Suspense fallback={<div>Loading... </div>}>
						<ViewBillDetail projectId={_projectId} />
				</Suspense>
			, div);
		});
	
		return _return;
	}	

}
export default Task;