// Assets
import Base64 from 'Base64';
import loadingGif from 'assets/img/line-loading.gif';
import iconPhone from 'assets/img/icons8-phone-50.png';
import iconEdit from 'assets/img/icon/edit.png';
import iconRubbishBin from 'assets/img/icon/rubbish-bin.png';
import iconSchedule from 'assets/img/icon/schedule.png';

import iconAddNote from 'assets/img/icon/addnote.png';
import iconAlarm from 'assets/img/icon/alarm.png';
import iconAdd from 'assets/img/icon/add.png';

import 'styles/reduction.scss';
import 'App.css';

// Core modules
import React, {useState, useEffect} from 'react';
import Accordion from 'react-bootstrap/Accordion';

// Framework modules
import Page from 'components/Page';
import { Badge, Card, CardBody, CardHeader, CardImg, CardLink, CardText, CardTitle, Col, Input, ListGroup, ListGroupItem, Row, Modal, ModalBody, ModalFooter, ModalHeader, Button, ButtonGroup, FormGroup, Label, Alert } from 'reactstrap';
import NotificationSystem from 'react-notification-system';
import { NOTIFICATION_SYSTEM_STYLE } from 'utils/constants';

import { Cookies } from 'react-cookie';
import ComboBox from '../../component/ComboBox.js';
import Dialog from "../../component/Dialog";
import ViewProject from '../Projects/ViewProject.js';
import Task from './Task.js';
import FormSubTask from './FormSubTask.js';
import ViewTaskDetail from '../TaskDetails/ViewTaskDetail.js';
import ViewTaskDetailByProject from '../TaskDetails/ViewTaskDetailByProject';
import ViewTaskPerson from '../Tasks/ViewTaskPerson'
import TaskDetail from '../TaskDetails/TaskDetail.js';
import ViewReceiveAndPayment from '../Invoices/ViewReceiveAndPayment';
import DateTime from '../../lib/DateTime.js';
import DataAdapter from '../../adapter/DataAdapter.js';
//import Project from '../Project.js';
import TaskAction from './TaskAction.js';
import ViewProjectTasks from './ViewProjectTasks.js';
import Avatar from '../../../components/Avatar';
import Project	from "../Projects/Project";
import Drive from '../../ViewDrive/Drive.js';
import DriveGrid from '../../ViewDrive/DriveGrid-v1.1.js';

const cookie =new Cookies();
const notificationSystem = React.createRef();
const addNotification = (_MESSAGE_, _TYPE_) => {
	const notification = notificationSystem.current;
	notification.addNotification({
	  message: _MESSAGE_,
	  level: _TYPE_
	});
};	

	const getSource	=(_id)=>{
		var _src = require("assets/img/users/avetar.jpg");
		try{
			_src = require('assets/img/users/' + _id + '.jpg');
		}catch(err){}
		return _src;
	}

function ViewTask(props){
	const [isProgressing, setIsProgressing] = useState(false);
	const [isOpen, setIsOpen] = useState(true);
	const [task, setTask] = useState(0);
	const [note, setNote] = useState('');
	const [prj_folder_id, setPrj_folder_id] = useState('');
	const [task_folder_id, setTask_folder_id] = useState('');
	const [currentFolder, setCurrentFolder] = useState('');
	const [reload, setReload] = useState(false);
	const [reloadDriveGrid, setReloadDriveGrid] = useState(false);
	const [client, setClient] = useState([]);
	const [receiver, setReceiver] = useState([]);
	const [actions, setActions] = useState([]);
	

	useEffect(()=>{
		setTask(props.project);
		loadActionForm(props.project.task_type_id);
		findTasksFolder(props.project.prj_folder_id);
	},[]);

	useEffect(()=>{
		if(task!=0){
			if(task.attachments == null || task.attachments == undefined ){
				onCreateCloudSpace(null, task, task_folder_id);
			}
		}
	},[task_folder_id]);

	const findTasksFolder = (_parent) => {
				let requestOptions = {
					method: 'GET',
					headers: {
						'Authorization':'Basic ' + Base64.btoa('sizotech:IsKfs$m$SZV#4Eprtj7zSPbO')
					}
				};
				fetch("https://node.renobuilding.ca:3001" + '/search?fullText=Tasks&parent=' + _parent + '&mimeType=application/vnd.google-apps.folder', requestOptions)
				.then(response => {
				  return Promise.all([response.json(),response])
				})
				.then(([responseData,response]) => {
					if(responseData.length>0){
						console.log(responseData.filter(d=>!d.trashed)[0].id)
						setTask_folder_id(responseData.filter(d=>!d.trashed)[0].id);
					}else{
						(new Drive()).createFolder(_parent, "Tasks").then(data=>{setTask_folder_id(data); setReloadDriveGrid(!reloadDriveGrid);});
					}
				})
				.catch(error => {
					console.error('Error:', error);
				})			
	}
	

	/**
	 * This function create project folder and subfolders on Google Drive
	 * @param {*} event 
	 */
	const onCreateCloudSpace = (event, task, task_folder) => {
		let _name = "Task-" + task.pt_id + "-" + task.task_type + "-" + task.receiver;
		if(task.item_no)
			_name = task.item_no + "-" + task.sort_id + "-" + task.pt_id + "-" + task.task;
		
		(new Drive()).createFolder(task_folder, _name).then(data=>{
				task.attachments = data;
				setTask(task);
				let formData;
				setCurrentFolder(data);
				formData = new FormData();
				formData.append("query", "UPDATE rb_project_tasks SET attachments = '" + data + "' WHERE pt_id=" + task.pt_id);
				let requestOptions = {
					method: 'POST',
					headers: {
						'Authorization': 'Basic ' + Base64.btoa('sizotech:IsKfs$m$SZV#4Eprtj7zSPbO')
					},
					body: formData
				};
				
				fetch('https://renobuilding.ca/wp-json/reno/v1/query/', requestOptions)
					.then(response => {
						return Promise.all([response.json(), response])
					})
					.then(([responseData, response]) => {
						//setIsOpen(false);
						setReloadDriveGrid(!reloadDriveGrid);
					})
					.catch(error => {
						console.error('Error:', error);
					})
			
		});
		/*
		let formData = new FormData();
		formData.append("parent", task_folder);
		formData.append("name", _name);

		fetch("https://node.renobuilding.ca:3001" + '/folder', {
			method: 'POST',
			body: formData
		})
			.then(response => response.json())
			.then(data => {
				let formData;
				setCurrentFolder(data);
				formData = new FormData();
				formData.append("query", "UPDATE rb_project_tasks SET attachments = '" + data + "' WHERE pt_id=" + task.pt_id);
				let requestOptions = {
					method: 'POST',
					headers: {
						'Authorization': 'Basic ' + Base64.btoa('sizotech:IsKfs$m$SZV#4Eprtj7zSPbO')
					},
					body: formData
				};
				
				fetch('https://renobuilding.ca/wp-json/reno/v1/query/', requestOptions)
					.then(response => {
						return Promise.all([response.json(), response])
					})
					.then(([responseData, response]) => {
						setIsOpen(false);
					})
					.catch(error => {
						console.error('Error:', error);
					})
			})
			.catch(error => {
				console.error(error)
			});
			*/
	}

	const handleAttachmentUpload = (files, _currentFolder) => {
		for(var i=0; i < files.length-1 ; i++){
			const formData = new FormData()
			formData.append('myfile', files[i]);
		
			fetch("https://node.renobuilding.ca:3001" + '/file?project=' + _currentFolder, {
					method: 'POST',
			body: formData
			})
			.then(response => response.json())
			.then(data => {
				addNotification(data.data.name + ' File(s) Uploaded', "info");
			})
			.catch(error => {
				console.error(error)
			});
		}
			const formData = new FormData()
			formData.append('myfile', files[files.length-1]);
		
			fetch("https://node.renobuilding.ca:3001" + '/file?project=' + _currentFolder, {
					method: 'POST',
			body: formData
			})
			.then(response => response.json())
			.then(data => {
				addNotification(data.data.name + ' File(s) Uploaded', "info");
				setReloadDriveGrid(!reloadDriveGrid);
			})
			.catch(error => {
				console.error(error)
			});
	}

	const callback =   (response)	=>{
		setIsProgressing(false);
		setReload(!reload);
	}

	const loadActionForm = (_task_type) =>{
		var dataAdapter =new DataAdapter("SELECT * FROM rb_link WHERE id IN (SELECT rb_type_actionform FROM rb_types WHERE rb_type='task_type' AND rb_type_no=" + _task_type + ")");
			dataAdapter.getRecords().then(data=>{
				setActions(data);
			})
	}
	
    return(
        <>
					<Row>
						<Col md={12} sm={12} xs={12} >
							<Card>
								<ListGroup>
									<ListGroupItem>
										<Row className="mb-1" >
											<Col>
												<Card>
													<CardBody>
															<Row>
																<Col xl={2} lg={2} md={2}>
																	<Avatar src={getSource(task.sender_id)} /><br/>
																	<small>Sender : {task.sender}</small>
																</Col>
																<Col xl={8} lg={8} md={8}>
																	<small><b>Task No.:</b>{task.pt_id}</small>&nbsp;&nbsp;
																	<small><b>Task Type:</b>{task.task_type}</small>&nbsp;&nbsp;
																	<small><b>Created Date:</b>{task.create_date}</small><br/>
																	<small><b>Expected Start by:</b>{task.expected_start_date}</small>&nbsp;
																	<small><b>Expected Finish by:</b>{task.expected_end_date}</small>&nbsp;
																	<small><b>Start Date:</b>{task.start_date}</small>&nbsp;&nbsp;
																	<small><b>Done Date:</b>{task.end_date}</small>
																	<ButtonGroup size="sm"  >
																	  {task.task_status_id*1==8? <Badge color="warning" className="ml-1" >To Start</Badge>:<Badge color="light" style={{cursor:'pointer'}} onClick={(e)=>{setIsProgressing(true);(new Task()).changeStatus(task.pt_id , 8).then((response)=>{callback(response);props.callback(response);});}} >To Start</Badge>}
																	  {task.task_status_id*1==9? <Badge color="info" className="ml-1" >In Progress</Badge>:<Badge color="light" style={{cursor:'pointer'}} onClick={(e)=>{setIsProgressing(true);(new Task()).changeStatus(task.pt_id , 9).then((response)=>{callback(response);props.callback(response);});}} >In Progress</Badge>}
																	  {task.task_status_id*1==10? <Badge color="success" className="ml-1" >Done</Badge>:<Badge color="light" style={{cursor:'pointer'}} onClick={(e)=>{setIsProgressing(true);(new Task()).changeStatus(task.pt_id , 10).then((response)=>{callback(response);props.callback(response);});}} >Done</Badge>}
																	  {task.task_status_id*1==11? <Badge color="dark" className="ml-1" >Closed</Badge>:<Badge color="light" style={{cursor:'pointer'}} onClick={(e)=>{setIsProgressing(true);(new Task()).changeStatus(task.pt_id , 11).then((response)=>{callback(response);props.callback(response);});}} >Closed</Badge>}
																	  </ButtonGroup>
																	<hr/>
																	<b>Task:</b><span className="text-info" > {task.task}</span><br/>
																	<small><b>Description:</b>{task.task_description != 'undefined'?task.task_description:''}</small>
																</Col>
																<Col xl={2} lg={2} md={2}>
																	<Avatar src={getSource(task.receiver_id)} /><br/>
																	<small>Receiver : {task.receiver}</small>
																</Col>
															</Row>
															<Row>
																<Col md={12} sm={12} xs={12} className="mb-3">
																	{task.attachments != null && task.attachments != 'null' ?
																		<>
																		<Row>
																			<Col md={12} sm={12} xs={12} className="mb-3">
																				<form style={{display:'block'}} > 
																					<div className="mb-3">
																						<label htmlFor={"formFileMultiple"}>
																							<i className="btn btn-outline-secondary btn-sm" >Attach Files</i>
																							<input className="form-control" style={{display: 'none'}}  type="file" id={"formFileMultiple"} onChange={(event)=> {handleAttachmentUpload(event.target.files, task.attachments);}} accept="*/*" multiple data-original-title="upload photos" />
																						</label>
																					</div>
																				</form>
																			</Col>
																		</Row>
																			<Row>
																				<Col md={12} sm={12} xs={12} className="mb-3">
																					<DriveGrid reload={reloadDriveGrid} parent={task.attachments} onChildrenChange={(_id) => { } } />
																				</Col>
																			</Row>
																		</>
																		:
																		<>
																			<Row>
																				<Col md={12} sm={12} xs={12} className="mb-3">
																					{task_folder_id !=''?
																						<div className="mb-3">
																							<label htmlFor="btnCreateFolder">
																								<input onClick={(e)=>{onCreateCloudSpace(e, task, task_folder_id)}} className="form-control" type="button" id="btnCreateFolder" value="Create Folder" />
																							</label>
																						</div>
																					:<Alert color="warning" >The "Tasks" folder not found! Please contact system administrator.</Alert>}
																				</Col>
																			</Row>
																		</>
																	}
																</Col>
															</Row>
													<Row>
														<Col xl={6} lg={6} md={6}>
															<Card>
																<ListGroup flush>
																	<ListGroupItem>
																		{task?
																			<>
																				<h6 className="bg-secondary p-1" > NOTES :
																					<img className="ml-2" src={iconAddNote} width={16} title="Add Note" 
																							style={{cursor:'pointer'}}
																							data-task-id={task.pt_id?task.pt_id:0} 
																							onClick={(e)=>{
																								var _task	=new Task();
																								_task.openAddNote(task).then(response => {
																									callback(response);
																									props.callback(response);
																								})
																							}}
																						/>																				
																				</h6>
																				<ViewTaskDetail taskType="37" taskId={task.pt_id} reload={reload} callback={(response)=>{callback(response); props.callback(response);}} />
																			</>
																		:<img src={loadingGif} height="32"  />}
																	</ListGroupItem>
																</ListGroup>
															</Card>
														</Col>
														<Col xl={6} lg={6} md={6}>
															<Card>
																<ListGroup flush>
																	<ListGroupItem>
																		{task?
																			<>
																				<h6 className="bg-secondary p-1" >Events/Appointments :
																					<img className="ml-2" src={iconSchedule} width={16} title="Add Schedule" 
																							style={{cursor:'pointer'}}
																							data-task-id={task.pt_id?task.pt_id:0} 
																							onClick={(e)=>{
																								var _task	=new Task();
																								_task.openAlarm(task).then(response => {
																									callback(response);
																									props.callback(response);
																								})
																							}}
																						/>
																				</h6>
																				<ViewTaskDetail taskType="113" taskId={task.pt_id} reload={reload} callback={(response)=>{callback(response); props.callback(response);}} />
																				<Input className="d-none" type="textarea" onChange={(e)=>{e.persist();setNote(e.target.value);}} ></Input> <br />
																				<Button color="secondary" className="btn-block d-none" onClick={(e)=>{
																					e.persist();
																					var _taskDetail = {
																						pt_id		:	props.taskId, 
																						prj_id		:	props.projectId, 
																						task_type	:	37, 
																						task_status	:	10, 
																						sender_id	:	cookie.get("v-person-id"), 
																						receiver_id	:	cookie.get("v-person-id"), 
																						note		:	note.replaceAll("'","\\\'"), 
																						start_date	:	(new DateTime()).getUTC(), 
																						end_date	:	(new DateTime()).getUTC()
																					};
																					var taskDetail =new TaskDetail(); 
																					taskDetail.insert(_taskDetail).then(data=>{
																						setReload(!reload);
																					});
																					}} ><small>Send</small></Button>{' '}
																			</>
																		:<img src={loadingGif} height="32"  />}
																	</ListGroupItem>
																</ListGroup>
															</Card>
														</Col>
													</Row>
													</CardBody>
												</Card>
											</Col>
										</Row>
										<Accordion defaultActiveKey="-1" flush>
											<Accordion.Item eventKey="0">
												<Accordion.Header >TASK ACTIONS</Accordion.Header>
												<Accordion.Body>
														<Col  md={12} sm={12} xs={12}>
															<Card>
																<ListGroup flush>
																{actions.map(action=>{
																	return (<>
																		<ListGroupItem>
																			<a href={'/#' + action.url} target="_blank" >{action.caption}</a>
																		</ListGroupItem>
																	</>);
																})}
																</ListGroup>
															</Card>
														</Col>
												</Accordion.Body>
											</Accordion.Item>
											<Accordion.Item eventKey="1">
												<Accordion.Header >PROJECT DETAILS</Accordion.Header>
												<Accordion.Body>
													<ViewProject project={task} />
												</Accordion.Body>
											</Accordion.Item>
											<Accordion.Item eventKey="2" >
												<Accordion.Header >TASK ASSIGNS</Accordion.Header>
												<Accordion.Body>
													<FormGroup row >
														<Col  md={12} sm={12} xs={12}>
															<Card>
																<ListGroup flush>
																	<ListGroupItem>
																	<ViewTaskDetail taskType="122" taskId={task.pt_id} reload={reload} />
																	{/*
																		<ViewTaskPerson personId={task.receiver_id} projectId={task.prj_id} taskId={task.pt_id} reload={reload} />
																	*/}
																	</ListGroupItem>
																</ListGroup>
															</Card>
														</Col>
													</FormGroup>
												</Accordion.Body>
											</Accordion.Item>
											<Accordion.Item eventKey="3" className="d-none" >
												<Accordion.Header >
												{/*<small>{task? '#' + task.pt_id + ' - ' + task.task:<img src={loadingGif} height="32"  />}</small> */}
													ACTIONS
												</Accordion.Header>
												<Accordion.Body>
													<FormGroup row >
														<Col  md={12} sm={12} xs={12}>
															<Card>
																<ListGroup flush>
																	<ListGroupItem>
																		<ViewTaskPerson personId={task.receiver_id} projectId={task.prj_id} taskId={task.pt_id} reload={reload} />
																	</ListGroupItem>
																</ListGroup>
															</Card>
														</Col>
													</FormGroup>
												</Accordion.Body>
											</Accordion.Item>
											<Accordion.Item eventKey="4"  >
												<Accordion.Header >
													TIMESHEET
												</Accordion.Header>
												<Accordion.Body>
													<FormGroup row >
														<Col  md={12} sm={12} xs={12}>
															<Card>
																<ListGroup flush>
																	<ListGroupItem>
																	{/*
																			<ViewTaskDetail taskType="98" taskId={task.pt_id} reload={reload} />
																	*/}
																		{task?
																			<ViewTaskDetailByProject personId={task.receiver_id} taskType="122" projectId={task.prj_id} reload={reload} />
																		:<img src={loadingGif} height="32"  />}
																	</ListGroupItem>
																</ListGroup>
															</Card>
														</Col>
													</FormGroup>
												</Accordion.Body>
											</Accordion.Item>
											<Accordion.Item eventKey="5"  >
												<Accordion.Header >Receive And Payments</Accordion.Header>
												<Accordion.Body>
													<FormGroup row >
														<Col  md={12} sm={12} xs={12}>
															<Card>
																<ListGroup flush>
																	<ListGroupItem>
																		{task?
																			<ViewTaskDetail taskType="83" taskId={task.pt_id} reload={reload} />
																		:<img src={loadingGif} height="32"  />}
																	</ListGroupItem>
																</ListGroup>
															</Card>
														</Col>
													</FormGroup>
													{/*
													<ViewReceiveAndPayment project={task} />
													*/}
												</Accordion.Body>
											</Accordion.Item>
											<Accordion.Item eventKey="6"  className="d-none" >
												<Accordion.Header >
													Work Log
												</Accordion.Header>
												<Accordion.Body>
													<FormGroup row >
														<Col  md={12} sm={12} xs={12}>
															<Card>
																<ListGroup flush>
																	<ListGroupItem>
																		{task?
																			<ViewTaskDetail taskType="93" taskId={task.pt_id} reload={reload} />
																		:<img src={loadingGif} height="32"  />}
																	</ListGroupItem>
																</ListGroup>
															</Card>
														</Col>
													</FormGroup>
												</Accordion.Body>
											</Accordion.Item>
										</Accordion>
									</ListGroupItem>
								</ListGroup>
							</Card>
						</Col>
					</Row>
				<NotificationSystem ref={notificationSystem} style={NOTIFICATION_SYSTEM_STYLE}/>
        </>
    );

}
export default ViewTask;