// Assets
import iconThumbsUp from 'assets/img/icon/thumbs-up.png';
import iconHourGlass from 'assets/img/icon/hour-glass.png';
import iconClockIn from 'assets/img/icon/clock-in.png';
import iconClockOut from 'assets/img/icon/clock-out.png';
import iconRubbishBin from 'assets/img/icon/rubbish-bin.png';
import iconHightPerformance from 'assets/img/icon/triangle-green.png';
import iconMiddlePerformance from 'assets/img/icon/triangle-yellow.png';
import iconLowPerformance from 'assets/img/icon/triangle-red.png';
import iconDuration from 'assets/img/icon/duration.png';
import iconDeadline from 'assets/img/icon/deadline.png';
import iconMoveToNext from 'assets/img/icon/move-right.png';
import iconMoveToPre from 'assets/img/icon/move-left.png';
import iconSchedule from 'assets/img/icon/schedule.png';
import iconCalculator from 'assets/img/icon/calculator.png';
import iconAddNote from 'assets/img/icon/addnote.png';
import iconAttachment from 'assets/img/icon/attachment.png';
import iconDays from 'assets/img/icon/days.png';
import iconAlarm from 'assets/img/icon/alarm.png';
import iconDepartment from 'assets/img/icon/department.png';
import iconBoard from 'assets/img/icon/board.png';

import React, {useEffect, useState} from 'react';
import {Row, Col, Card, CardHeader, CardTitle, CardBody, CardFooter,Badge} from 'reactstrap';
import { Cookies } from 'react-cookie';
import Avatar from '../../components/Avatar';
import Accordion from 'react-bootstrap/Accordion';

import VtxTimer 		from './VtxTimer';
import Task				from "../model/Tasks/Task";
import TaskDetail		from "../model/TaskDetails/TaskDetail";

const cookie =new Cookies();

var isNumber = function isNumber(value) {
   return typeof value === 'number' && isFinite(value) && value != 'null';
}
function allowDrop(e) {
	e.preventDefault();
}

export const Filter = (props) =>{
	//const [defaultValue, setDefaultValue] = useState(props.defaultValue);

	var projects = props.projects;
	var tempValue	=	[];
	var displayField	=	props.displayField;
	var valueField		=	props.valueField;
	var defaultValue	=	props.defaultValue;

	// useEffect(()=>{
	// 	setDefaultValue(props.defaultValue);
	// 	console.log(props.defaultValue);
	// 	console.log(defaultValue);
	// });
	projects.sort((a, b) => a[displayField]?a[displayField].localeCompare(b[displayField]):a[displayField]);

	if(!projects)
		return null;
	return(
		<>
			<select value={defaultValue} id={props.valueField} onChange={(e)=>props.onFilterChange(e)} style={{width:'100%'}} >
				<option value="-1" style={{color:'gray'}} > {props.text}</option>
				{projects.map(project=>{
					return(
						<>
							{ tempValue.findIndex((v)=> v==project[displayField]) == -1 ?
								<>
									<option value={project[valueField]} selected={project[valueField]==props.defaultValue? true : false} >{project[displayField]}</option>
									{tempValue.push(project[displayField]) }
								</>
							:''}
						</>
					)
				})}
			</select>
		</>
	);
}

export const LeadLabel = (props) =>{
	var _filtered_project	=	props.projects.filter(p=>p.task_status_id==props.status && isNumber(p.percent_done*1));

	var _average			=	(_filtered_project?_filtered_project.reduce((a, b) => {
		b	=	(isNumber(b.percent_done*1) ? b.percent_done*1 :0);
		return a+b;
	},0) / _filtered_project.length:0);

	var _performance		=	(_filtered_project?_filtered_project.reduce((a, b) => {
		var _performance = (b.percent_done/100)/(b.real_hours/60/b.estimate_hours);
		b	=	(isNumber(_performance) ?   _performance:0);
		return a+b;
	},0) / _filtered_project.length:0);
	
	var _performance_color = (_performance<=60?'#fb2a55':
			_performance<=80?	'#ffd700':
			_performance<=100?	'#45b649':
			''
		);
	var _average_color = (_average<=60?'#fb2a55':
		_average<=80?	'#ffd700':
		_average<=100?	'#45b649':
			''
	);


	return <div className="myHeader border-top rounded-top p-1" style={{width:'300px', backgroundColor:'lightgray',color:'black', marginBottom:'50px'}}>{props.text}{'  '}
				<Badge color="dark" pill className="mr-1">
					{props.projects? props.projects.filter(p=>p.task_status_id==props.status).length :''}
				</Badge><br />

				<img src={iconThumbsUp} width={16} />
				<small>{_average.toFixed(1)}</small>{'	'}

				<img src={iconHourGlass} width={16} />
				<small>{props.projects? Math.round(10*(props.projects.filter(p=>p.task_status_id==props.status).reduce((a,b)=>{return a*1 + b.real_hours*1},0))/60)/10:0} </small>{'	'}

				{_performance<0.6?
					<img src={iconLowPerformance} width={16} />
				:''}
				{_performance>=0.6 && _performance<0.8?
					<img src={iconMiddlePerformance} width={16} />
				:''}
				{_performance>=0.8?
					<img src={iconHightPerformance} width={16} />
				:''}
				{(100*_performance).toFixed(1)}

				<Badge color="light" pill className="mr-1">
					ESTIMATE : {props.projects? props.projects.filter(p=>p.task_status_id==props.status).reduce((a,b)=>{return a*1 + b.estimate_hours*1},0).toFixed(1) :''}
				</Badge>

				{/* <Badge color="info" pill className="mr-1">
					SUM : {props.projects? Math.round(10*(props.projects.filter(p=>p.task_status_id==props.status).reduce((a,b)=>{return a*1 + b.real_hours*1},0))/60)/10 :''}
				</Badge> */}


				{/* <Badge color="light" style={{color : _performance_color}} pill className="mr-1">
					AVG. PERFORM. : {Math.round(_performance*1000)/10}
				</Badge> */}
				
				{/* <Badge color="light" style={{color : _average_color}}  pill className="mr-1">
					AVG. DONE : {Math.round(_average*100)/100}
				</Badge> */}
			</div>
			
}

export const Lead = (props) =>{
	const colorMap = props.colorMap;

	var projects = props.projects;
	var _status = props.status;
	var today = new Date();
	var date = today.getFullYear() + '-' + (today.getMonth() <= 9 ? '0' + (today.getMonth() + 1) : today.getMonth() + 1) + '-' + (today.getDate() <= 9 ? '0' + today.getDate() : today.getDate());
	var task_status_color	=	'';
	var card_priority_color	=	'';
	var _performance;
	var _performance_color;
	var date0, date1, date2;
	const getSource	=(person)=>{
		var _src = require("assets/img/users/avetar.jpg");
		try{
			_src = require('assets/img/users/' + person + '.jpg');
		}catch(err){}
		return _src;
	}
	const getDepartmentLogo	=(_departmentId)=>{
		var _src = require("assets/img/logo/-1-.png");
		try{
			_src = require('assets/img/logo/' + _departmentId + '.png');
		}catch(err){}
		return _src;
	}
	
	
	if(!projects)
		return null;
	return(
		<>
			{projects.filter(p=> p.parent==null || props.isSubTask).map(project=>{
				task_status_color = (project.status*1==0? "black":
					project.priority*1==1?"black":
					project.priority*1==2?"yellow":
					project.priority*1==3?"red":'');
				card_priority_color = (project.priority*1==0? "#dae0e5":
					project.priority*1==1?"#fb2a55":
					project.priority*1==2?"#ffd700":
					project.priority*1==3?"#dae0e5":'');
				
				_performance	= Math.round(100*(project.percent_done/100)/(project.real_hours/60/project.estimate_hours))>100? 100: Math.round(100*(project.percent_done/100)/(project.real_hours/60/project.estimate_hours));
				_performance_color = (_performance<=60?'#fb2a55':
										_performance<=80?	'#ffd700':
										_performance<=100?	'#45b649':
										''
				);
				date0 	=new Date(project.start_date);
				//date0 	=(project.expected_start_date? new Date(project.expected_start_date): new Date(project.start_date));
				date1 	=new Date();
				date2	=new Date(project.expected_end_date);

				const diffTime0 = Math.abs(date1 - date0);
				const diffDays0 = Math.ceil(diffTime0 / (1000 * 60 * 60 * 24)); 

				const diffTime = Math.abs(date2 - date1);
				const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
				return(
				<>
				{project.task_status_id == _status?
					<>
						<Accordion defaultActiveKey="-1" id={project.pt_id?project.pt_id:0} flush draggable="true" onDragStart={(event)=>props.drag(event)}  >
							<Accordion.Item eventKey="0">
								<Accordion.Header className="pt-2 pb-2 text-right" style={{backgroundColor: colorMap[project.task_type_id]}} >
									<span style={{width:'100%'}}>
										<Avatar className="float-left" src={getSource(project.sender_id)} size={30} />
										<Avatar className="float-right" src={getSource(project.receiver_id)} size={30} />
										<small style={{color:'#9a7d34'}} ><img src={getDepartmentLogo(project.prj_department)} height={18} title="Department" /> {project.department}</small><br/>
										<small><img src={iconBoard} height={18} title={project.prj_code + " - " + project.prj_name + " Board"} /> {project.prj_name}</small><br/>
										<small>{project.task_type}</small><br />										
										<small className="text-secondary" >{project.pt_id}-{project.task}</small>&nbsp;&nbsp;<br />
										{project.attachments? <img className="float-left" src={iconAttachment} width={16} />: ''}
										{project.note_list.split('---').filter(n=>n!=',' && n!='').length>0? <img className="float-left" src={iconAddNote} width={16} />: ''}
										{project.alarm_list.split('---').filter(n=>n!=',' && n!='').length>0? <img className="float-left" src={iconSchedule} width={16} />: ''}
										{project.unread_note_list.split('---').filter(n=>n!=',' && n!='').length>0? <img className="float-left" src={iconAlarm} width={16} />: ''}
									</span>
								</Accordion.Header>
								<Accordion.Body>
									<div >
										<Card>
											<CardBody style={{backgroundColor: colorMap[project.task_type_id], borderColor: card_priority_color }} >
											
												<CardTitle className="text-capitalize">																		
													<small className="float-right" >{project.category_desc}</small><br/>
													<b title="Project Title" >{project.prj_title}</b><br/>
													<b title="Task Type" >{project.task_type}</b>
													<br/>
													<small><b>{project.pt_id}</b></small> |
													<small><b>{project.start_date}</b></small> |
													<small className="ml-1">
														<img src={iconDays} width={13} title="Duration" />
														<b> {Math.ceil(diffTime0 / (1000 * 60 * 60 * 24))} Day(s)</b>{'	'}
													</small> 
													<br />
													<Badge
														data-task-id={project.pt_id?project.pt_id:0} 
														data-task-status={project.task_status_id?project.task_status_id:0} 
														data-task-task={project.task?project.task:''} 
														data-task-description={project.task_description?project.task_description:''} 
														data-task-percent-done={project.percent_done? project.percent_done:0} 
														data-task-note={project.note? project.note:''} 
														data-task-end={project.end_date? project.end_date: ''} 
														data-task-priority={project.priority? project.priority: ''} 
														data-task-real-hours={project.real_hours? project.real_hours: ''} 
														data-task-estimate-hours={project.estimate_hours? project.estimate_hours: ''} 
														data-task-project={project.prj_id? project.prj_id: ''} 
														data-task-sender={project.sender? project.sender: ''} 
														data-task-receiver={project.receiver? project.receiver: ''} 
														data-task-type={project.task_type? project.task_type: ''} 
														data-task-start={project.start_date? project.start_date: ''} 
														data-task-expected-end-date={project.expected_end_date? project.expected_end_date: ''} 
														data-project={project.prj_id? project.prj_id : ''}
														onClick={(e)=>props.onActionHanlder(e)}
														data-project-id={project.prj_id}										
														href="javascript:(0);" 														
														className='float-right' 
													>#{project.pt_id} </Badge>
														{ cookie.get('v-person-role')*1===0 || true?
															<>
																<a style={{color:'#000'}} target={'_blank'} href={"/#/ViewProjectTask/" + project.prj_id + "/" + project.pt_id} >{project.task}</a>
															</>
														:
															<>
																<snap style={{color:'#000'}} >{project.task}</snap>
															</>
														}
														<>
																{
																	project.note_list.split('---').map(n=>{
																		var _note = n.split("|")[0];
																		var _status = n.split("|")[1];
																		if(n!=',' && n!='')
																				return(
																					<>
																						<br/>
																						<img className="mr-2" src={_status==8?iconAlarm:iconAddNote} width={16} />
																						<small className="text-dark" style={{cursor:'pointer'}} onClick={(e)=>{alert(n.replace(',',''))}} >{_note.length>50?_note.substring(0,50).replace(',','') + '...' : _note.replace(',','')}</small>
																					</>
																				)
																	})
																}
																{
																	project.alarm_list.split('---').map(n=>{
																		if(n!=',' && n!='')
																				return(
																					<>
																						<br/>
																						<img className="mr-2" src={iconSchedule} width={16} title="Add Alarm" />
																						<small className="text-dark" style={{cursor:'pointer'}} onClick={(e)=>{alert(n.replace(',',''))}} >{n.length>50?n.substring(0,50).replace(',','') + '...' : n.replace(',','')}</small>
																					</>
																				)
																	})
																}
																									
															{ cookie.get('v-person-role')*1===0 || cookie.get('v-person-role')*1===1 || cookie.get('v-person-id')*1===66?
															<>
															<hr/>
															<div className='float-left mr-2' >
															<a href='javascript:void(0)' onClick={(e)=>{
																e.persist();
																var _projects	= projects;
																var _index	= _projects.findIndex(p=>p.pt_id == e.target.attributes["data-id"].nodeValue);
																const taskInfo = "#" + _projects[_index].pt_id + " - " + _projects[_index].task ;
																if(window.confirm("Are you sure to move to next scrum?\n" + taskInfo)){
																	props.nextScrum(_index);
																}
																}} >
																	<img title='To the Next Month'  data-id={project.pt_id?project.pt_id:0} src={iconMoveToNext} width={16} /></a>
															</div>
															<div className='float-left mr-2' >
															<a href='javascript:void(0)' onClick={(e)=>{
																e.persist();
																var _projects	= projects;
																var _index	= _projects.findIndex(p=>p.pt_id == e.target.attributes["data-id"].nodeValue);
																const taskInfo = "#" + _projects[_index].pt_id + " - " + _projects[_index].task ;
																if(window.confirm("Are you sure to move to pre scrum?\n" + taskInfo)){
																	props.preScrum(_index);
																}
																}} >
																	<img title='To the Pre Month'  data-id={project.pt_id?project.pt_id:0} src={iconMoveToPre} width={16} /></a>
															</div>
															<div className='float-left mr-2' >
															<a href='javascript:void(0)' onClick={(e)=>{
																e.persist();
																var _projects	= projects;
																var _index	= _projects.findIndex(p=>p.pt_id == e.target.attributes["data-id"].nodeValue);
																const taskInfo = "#" + _projects[_index].pt_id + " - " + _projects[_index].task ;
																if(window.confirm("Are you sure to delete?\n" + taskInfo)){
																	props.remove(_index);
																}
																}} >
																	<img title='Remove the Task'  data-id={project.pt_id?project.pt_id:0} src={iconRubbishBin} width={16} /></a>
															</div>
															</>

														: ''}											
																
															<div className='float-right mr-2' >
															{project.clockedIn != '0' ? 
																<>
																	<img src={iconClockOut} height={16} data-task-id={project.pt_id} data-project-id={project.prj_id}
																	onClick={(e)=>{
																		e.persist();
																		var _projects	= props.projects;
																		var _index	= _projects.findIndex(p=>p.pt_id == e.target.attributes['data-task-id'].nodeValue);
																		_projects[_index].clockedIn	 = 0;
																		props.clockOut(e, _projects);

																	}}  
																	/>
																	<VtxTimer clockIn={project.clockedIn} />
																</>
															:
																<img src={iconClockIn} height={16} data-task-id={project.pt_id} data-project-id={project.prj_id}
																onClick={(e)=>{
																	e.persist();
																	var _projects	= props.projects;
																	var _index	= _projects.findIndex(p=>p.pt_id == e.target.attributes['data-task-id'].nodeValue);
																	_projects[_index].task_status			= "In Progress";
																	_projects[_index].task_status_id		= 9;
																	_projects[_index].clockedIn				= 1;
																	props.clockIn(e, _projects);
																	// this.runQuery("UPDATE rb_project_tasks SET estimate_hours = ROUND(real_hours/60) WHERE (estimate_hours is null or estimate_hours=0) and  pt_id=" + _pt_id, "Task estimate hours has been updated");
																}} 
																/>
															}
															</div>
															<div className='float-right mr-2' >
																	<img title='Calculate'  data-task-id={project.pt_id?project.pt_id:0} data-task-receiver={project.receiver_id?project.receiver_id:0} src={iconDuration} width={16} onClick={(e)=>{
																e.persist();
																props.onCalculateHandler(e);
																}}/>
															</div>
															
															<br/>
															<small>{project.prj_address}, {project.prj_city}</small>
															<br/>
															<i>{project.name}{' '}{project.lastname}</i>
															<br/>
															<small style={{color:'#9a7d34'}} >{project.department}</small>
															{projects.filter(p=>p.parent==project.pt_id).length>0?
															<Accordion defaultActiveKey={props.isCollapse} flush>
																<Accordion.Item eventKey="0">
																	<Accordion.Header className="p-0 text-right" >
																		<small>Sub Tasks</small>
																	</Accordion.Header>
																	<Accordion.Body>
																		<Lead isSubTask={true} colorMap={colorMap} onCalculateHandler={(event)=>props.onCalculateHandler(event)} clockOut={(event, _projects)=>props.clockOut(event, _projects)} clockIn={(event, _projects)=>props.clockIn(event, _projects)} projects={props.projects.filter(p=>p.parent==project.pt_id)} status={project["task_status_id"]} drag={(e)=>{props.drag(e)}} onActionHanlder={(e)=>{props.onActionHanlder(e)}} onProjectManager={(e)=>{props.onProjectManager(e)}} remove={(i)=>props.remove(i)} nextScrum={(i)=>props.nextScrum(i)} preScrum={(i)=>props.preScrum(i)} />																	
																	</Accordion.Body>
																</Accordion.Item>
															</Accordion>:''}									
															
														</>
												</CardTitle>
											</CardBody>
										</Card>
									</div>
								</Accordion.Body>
							</Accordion.Item>
						</Accordion>									
						<br />
					</>
				:''}
				</>
				)
			})}
		</>
	)
}

export const Grid = (props) =>{
	const [colorMap, SetColorMap]	=	useState([]);
	var projects	=	props.projects;
	projects = projects.sort((a,b) => a.task_status_id - b.task_status_id)
	var tempValue	=	[
		{task_status_id:7, task_status:"In Queue"},
		{task_status_id:8, task_status:"To Start"},
		{task_status_id:9, task_status:"In Progress"},
		{task_status_id:10, task_status:"Done"},
		{task_status_id:11, task_status:"Closed"},
		{task_status_id:12, task_status:"Hold/Wait/Pending"},
		]; 
	useEffect(()=>{
		var _colors	=	[];
		  const arrayOfColorFunctions = "0123456789abcdef";
		  for (let i = 0; i < 200; i++) {
			let randomColorString = "#";
			  for (let x = 0; x < 6; x++) {
				let index = Math.floor(Math.random() * 16);
				let value = arrayOfColorFunctions[index];

				randomColorString += value;
			  }
			  _colors.push(randomColorString + 20);
		  }
		SetColorMap(_colors);
	}, []);
	
	return (
		<>
			<div className='Container Flipped'>
				<div className='Content' >
					<table className='card' >
						<tr>
						{tempValue.map(project=>{
							return(
								<>
									<td className="border-right" status-id={project["task_status_id"]} status={project["task_status"]} onDrop={(event)=>props.drop(event)} onDragOver={(event)=>allowDrop(event)}  style={props.columns.findIndex(i=>i==project["task_status_id"]*1) ==-1?{display:'none'}:{}} >
										<LeadLabel  text={project["task_status"]} projects={projects} status={project["task_status_id"]} />											
										<Lead colorMap={colorMap} onCalculateHandler={(event)=>props.onCalculateHandler(event)} clockOut={(event, _projects)=>props.clockOut(event, _projects)} clockIn={(event, _projects)=>props.clockIn(event, _projects)} projects={projects} status={project["task_status_id"]} drag={(e)=>{props.drag(e)}} onActionHanlder={(e)=>{props.onActionHanlder(e)}} onProjectManager={(e)=>{props.onProjectManager(e)}} remove={(i)=>props.remove(i)} nextScrum={(i)=>props.nextScrum(i)} preScrum={(i)=>props.preScrum(i)} />
									</td>								
								</>
							);
							})
						}
						
						</tr>
					</table>
				</div>
			</div>
		</>		
	)
}

