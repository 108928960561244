import React from 'react';
import Page from 'components/Page';
import { Badge, Card, CardBody, CardHeader, CardImg, CardLink, CardText, CardTitle, Col, Input, ListGroup, ListGroupItem, Row, Modal, ModalBody, ModalFooter, ModalHeader, Button, FormGroup, Label } from 'reactstrap';
import {
	StaticGoogleMap,
	Marker,
	Path,
  } from 'react-static-google-map';
import Base64 from 'Base64';
import DataAdapter from "./adapter/DataAdapter";
import ComboBox from "./component/ComboBox";
import LayoutUtil from './lib/LayoutUtil';
import iconMenu		 from 'assets/img/icon/menu.png';

const YOUR_API_KEY = 'AIzaSyBQmriWNd6AOEON52zbnw27RMG29YQQclI';
var loc = '';
class ViewMap extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			projects : [],
			latitude	:	35.7305479,
			longitude	:	51.3092334,
			status		:	9,
		};
		/*
		navigator.geolocation.getCurrentPosition(function(position) {
				this.setState({
					latitude	:	position.coords.latitude,
					longitude	:	position.coords.longitude,
				});
			});		
*/
		this.loadData(9);

	}
	
	loadData = (_status) =>{
		var dataAdapter =new DataAdapter("SELECT * FROM rb_projects where latitude<>0 and longitude<>0 AND prj_status=" + _status + ";");
			dataAdapter.getRecords().then(data=>{
				this.setState({projects : data});				
			})		
	}
	render(){
		return (
			<Page className="DashboardPage" title="Projects Map" >
				<Row>
					<Col>
							<ComboBox onChange={(e)=>{
								e.persist();
								this.setState({status : e.target.value});
								this.loadData(e.target.value);
							}} table="rb_types" filterField="rb_type" filterValue={'prj_status'} valueField="rb_type_no" displayField="rb_type_desc"  orderby="rb_type_no" defaultValue={this.state.status} />
					</Col>
				</Row>
				<Row>
					<Col>
						{this.state.projects.map((project,i)=>{
							return(
								<Card>
									<CardBody>{i+1} - {project.prj_id} - {project.prj_address} - {project.prj_city} - {project.prj_postal_code}
													<span className="mr-2 float-right">
														<img height="25px" src={iconMenu} style={{cursor:'pointer'}} onClick={(e)=>{
															var layoutUtil	=new LayoutUtil();
																layoutUtil.openProjectMenu(project);
														}} />
													</span>

									</CardBody>
								</Card>
							)
						})}
					</Col>
					<Col>
						{this.state.projects?
							<img src={getMap(this.state.projects, YOUR_API_KEY, this.state.latitude, this.state.longitude)} />:''}
					</Col>
				</Row>
			</Page>
				);
	}
}

function getMap(projects, apikey, latitude, longitude){
	var location	= '';
	//var markers		=	'size:normal%7Ccolor:0xf04e23%7Clabel:R';	
	var _icon	=	"";
	var _size	=	"size:normal";
	var _color	=	"color:0xf04e23";
	var _label	=	"label:P";
	var _point	=	latitude + ',' + longitude;
	//var markers	=	"markers=" + _size + "|" + _color + "|" + _label + "|" + _point + "&";
	var markers	=	"";
	projects.forEach((element, i) => {
		if(element.latitude!=0 & element.longitude !=0){
			//location = "%7C" + element.latitude + ',' + element.longitude;
			//markers	+= 'markers=%7C %7C %7C label:' + (i%2==0?'R':'L') + "%7C" + element.latitude + ',' + element.longitude + '&';
			markers	+=	"markers=";
			_icon	=	"icon:http://cdn.sstatic.net/Sites/stackoverflow/img/favicon.ico";
			_size	=	"size:normal";
			_color	=	"color:" + (element.prj_status==10? "0x196F3D" : (element.prj_status==9? "0x2874A6": (element.prj_status==8? "0xF1C40F": "0xf04e23")));
			_label	=	"label:" + (i+1);
			_point	=	element.latitude + ',' + element.longitude;
			
			markers		+=	_size + "|" + _color + "|" + _label + "|" + _point + "&" 
		}
	});
	
	return 'https://maps.googleapis.com/maps/api/staticmap?size=1024x500&scale=1&format=png&maptype=roadmap&' + markers + '&key=' + apikey ;

}

export default ViewMap;