import React,{Suspense} from "react";
import ReactDOM from 'react-dom';
import DataAdapter from "../../adapter/DataAdapter";
import Dialog from "../../component/Dialog";


class Accounting {
    constructor(){
		
    }
	
	openPurchase	=	async()	=>{
		const _return = await new Promise((resolve, rej) => {
			const div	=	document.createElement('div');
			document.body.appendChild(div);
			const FormPurchase = React.lazy(() => import('./FormPurchase'));
			ReactDOM.render(
				<Suspense fallback={<div>Loading... </div>}>
						<FormPurchase />
				</Suspense>
			, div);		
			
		});
	
		return _return;
	}

}
export default Accounting;