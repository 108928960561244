// Assets
import loadingGif from 'assets/img/line-loading.gif';
import iconEdit from 'assets/img/icon/edit.png';
import iconMail from 'assets/img/icon/email-color.png';
import iconPhone from 'assets/img/icon/phone-color.png';

import React, {useState, useEffect} from "react";
import Project from "./Project";
import Base64 from 'Base64';

import DriveGrid from '../../ViewDrive/DriveGrid.js';

import { Badge, Card, CardBody, CardHeader, CardImg, CardLink, CardText, CardTitle, Col, Input, ListGroup, ListGroupItem, Row, Modal, ModalBody, ModalFooter, ModalHeader, Button, FormGroup, Label } from 'reactstrap';
// Constants
const _BACKEND_URL_ = "https://node.renobuilding.ca:3001"

// Variables
var __PROJECT_ID__;

function ViewProject(props){
    const [project, setProject] = useState(0);
    const [current, setCurrent] = useState([]);
    const [upload, setUpload] = useState();
    const [xtraResponse, setXtraResponse] = useState();

    useEffect(()=>{
        initial(props);
    },[props])

    const initial = (props) =>{
		/*
        var projects =new Project();
        projects.viewById(props.projectId)
        .then(data=>{
            setProject(data);
        })
		*/
		__PROJECT_ID__	=	props.project.prj_id;
		setProject(props.project);
    }
	const numberWithCommas = (x) => {
		return (x?x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","):'');
	}
	
	const aiResponse = (e) =>{
		let formData = new FormData();
		formData.append("model", 'gpt-3.5-turbo');
		let requestOptions = { method: 'POST', headers: { 'Authorization': 'Basic ' + Base64.btoa('sizotech:IsKfs$m$SZV#4Eprtj7zSPbO') }, body: formData };
		fetch('https://node.renobuilding.ca:3001/openai/chat', requestOptions)
			.then(response => {
				return Promise.all([response.json(), response])
			})
			.then(([responseData, response]) => {
				console.log(responseData)
				setXtraResponse('' + responseData.content.replaceAll('AI language model', 'RenoXtra'));

			})
			.catch(error => {
				console.error('Error:', error);
			})
	}
    
	const onCreateCloudSpace = (event, project) => {
		let _name = project.prj_id + ' - ' + project.prj_address + ', ' + project.prj_city + ' - ' + project.prj_title + ' - ' + project.client_name + ' ' + project.client_lastname;

		let formData = new FormData();
		formData.append("parent", "1rG7p5Z5ObLqPJTNV231TT38vbkFvRltH");
		formData.append("name", _name);
		console.log(_name);

		fetch(_BACKEND_URL_ + '/folder', {
			method: 'POST',
			body: formData
		})
			.then(response => response.json())
			.then(data => {
				console.log(data);
				let formData;
				// Images
				formData = new FormData();
				formData.append("parent", data);
				formData.append("name", "Images");
				fetch(_BACKEND_URL_ + '/folder', { method: 'POST', body: formData }).then(response => response.json()).then(data => { this.addNotification('Image folder Created'); }).catch(error => { })
				// Images
				formData = new FormData();
				formData.append("parent", data);
				formData.append("name", "Drawings");
				fetch(_BACKEND_URL_ + '/folder', { method: 'POST', body: formData }).then(response => response.json()).then(data => { this.addNotification('Drawing folder Created'); }).catch(error => { })

				formData = new FormData();
				formData.append("parent", data);
				formData.append("name", "Models");
				fetch(_BACKEND_URL_ + '/folder', { method: 'POST', body: formData }).then(response => response.json()).then(data => { this.addNotification('Models folder Created'); }).catch(error => { })

				formData = new FormData();
				formData.append("parent", data);
				formData.append("name", "Orders");
				fetch(_BACKEND_URL_ + '/folder', { method: 'POST', body: formData }).then(response => response.json()).then(data => { this.addNotification('Orders folder Created'); }).catch(error => { })

				formData = new FormData();
				formData.append("parent", data);
				formData.append("name", "Quote");
				fetch(_BACKEND_URL_ + '/folder', { method: 'POST', body: formData }).then(response => response.json()).then(data => { this.addNotification('Quote folder Created'); }).catch(error => { })

				formData = new FormData();
				formData.append("parent", data);
				formData.append("name", "Videos");
				fetch(_BACKEND_URL_ + '/folder', { method: 'POST', body: formData }).then(response => response.json()).then(data => { this.addNotification('Videos folder Created'); }).catch(error => { })

				formData = new FormData();
				formData.append("parent", data);
				formData.append("name", "Docs");
				fetch(_BACKEND_URL_ + '/folder', { method: 'POST', body: formData }).then(response => response.json()).then(data => { this.addNotification('Docs folder Created'); }).catch(error => { })
				
				formData = new FormData();
				formData.append("query", "UPDATE rb_projects SET prj_folder_id = '" + data + "' WHERE prj_id=" + __PROJECT_ID__);
				let requestOptions = {
					method: 'POST',
					headers: {
						'Authorization': 'Basic ' + Base64.btoa('sizotech:IsKfs$m$SZV#4Eprtj7zSPbO')
					},
					body: formData
				};
				fetch('https://renobuilding.ca/wp-json/reno/v1/query/', requestOptions)
					.then(response => {
						return Promise.all([response.json(), response])
					})
					.then(([responseData, response]) => {
						console.log(responseData);
						setTimeout(() => {
							window.location.reload();
						}, 5000)
					})
					.catch(error => {
						console.error('Error:', error);
					})
			})
			.catch(error => {
				console.error(error)
			});
	}
	
    return(
        <>{project?
                <FormGroup row >
                <Col  md={11} sm={11} xs={12}>
                    <Card>
                        <ListGroup flush>
                            <ListGroupItem>
                                <Label md={2}><b>Project Status</b></Label>
									{project.prj_status_desc}
                            </ListGroupItem>
                            <ListGroupItem>
                                <Label md={2}><b>Title</b></Label>
                                #{project.prj_id} - {project.prj_title}
                            </ListGroupItem>
                            <ListGroupItem>
                                <Label md={2}><b>Description</b></Label>
                                {project.prj_desc}
								<div>{xtraResponse?
									<>
										<Badge color="success">
											RenoXtra
										</Badge>
										{xtraResponse}
									</>
								:''}</div>
								
                            </ListGroupItem>
                            <ListGroupItem>
                                <Label md={2}><b>Location :</b></Label>																	 
                                {project.prj_address}, {project.prj_city}, {project.prj_postal_code}  
                            </ListGroupItem>
                            <ListGroupItem>
                                <Label md={2}><b>Received :</b></Label>
                                {project.prj_posted_date}
                            </ListGroupItem>
                            <ListGroupItem>
                                <Label md={2}><b>Start Date :</b></Label>
                                {project.start_date}
                            </ListGroupItem>
                            <ListGroupItem>
                                <Label md={2}><b>Budget :</b></Label>
                                ${numberWithCommas(project.prj_budget)}
                            </ListGroupItem>
							{project.contact?
                            <ListGroupItem>
                                <Label md={2}><b>Contact Name :</b></Label>
                                {project.contact.split(';')[0] ? <><span>{project.contact.split(';')[0]}</span></> : ''}{'	'}
                                {project.contact.split(';')[1] ? <><a href={'tel:' + project.contact.split(';')[1]} title={project.contact.split(';')[1]}><img src={iconPhone} height={16} /></a></> : ''}{'	'}
                                {project.contact.split(';')[2] ? <><a href={'mailto:' + project.contact.split(';')[2]} title={project.contact.split(';')[2]}><img src={iconMail} height={16} /></a></> : ''}{'	'}
                            </ListGroupItem>:null}
                            <ListGroupItem>
                                <Col md={12} sm={12} xs={12} className="mb-3">
                                    {project.prj_folder_id != null ?
                                        <>
                                            <Row className="d-none" >
                                                    <Col md={12} sm={12} xs={12} className="mb-3">
                                                        <form onSubmit={()=>{}} style={{ display: 'block' }} >
                                                            <div className="mb-3">
                                                                <label htmlFor="formFileMultiple">
                                                                    <i className="btn btn-outline-secondary btn-sm" >Upload</i>
                                                                    <input className="form-control" style={{ display: 'none' }} type="file" id="formFileMultiple" onChange={()=>{}} accept="image/*" multiple data-original-title="upload photos" />
                                                                </label>
                                                            </div>
                                                        </form>
                                                    </Col>
                                            </Row>
                                            <Row>
                                                <Col md={12} sm={12} xs={12} className="mb-3">
                                                    <DriveGrid parent={project.prj_folder_id} onChildrenChange={(_id) => { setCurrent(_id) } } />
                                                </Col>
                                            </Row>
                                        </>
                                        :
                                        <>
											<Row>
												<Col md={12} sm={12} xs={12} className="mb-3">
													<div className="mb-3">
														<label htmlFor="btnCreateFolder">
															<input onClick={(e)=>{onCreateCloudSpace(e, project)}} className="form-control" type="button" id="btnCreateFolder" value="Create Cloud Folder" />
														</label>
													</div>
												</Col>
											</Row>
                                        </>
                                    }
                                </Col>
                            </ListGroupItem>
                        </ListGroup>
                    </Card>
                </Col>
                <Col  md={1} sm={1} xs={1}>
					<img style={{cursor:'pointer'}} src={iconEdit} width={16} onClick={(e)=>{
						var _project	=new Project();
							_project.editProjectForm(project.prj_id);
						}
					} />
					<Badge
						color="primary"
						data-project-id={project.prj_id}
						onClick={(e) => { aiResponse(e) }}
						href="javascript:(0);" className="mr-1">
						RenoXtra
					</Badge>
				</Col>
            </FormGroup>
		:
            <img src={loadingGif} height="32"  />
        }</>
    );
}

export default ViewProject;