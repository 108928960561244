class ObjectUtil {
    constructor(){

    }
}
export default ObjectUtil;

export const isObject = (object) => {
  return object != null && typeof object === 'object';
}
export const isEqual = (object1, object2) =>{
  
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (let key of keys1) {
    if (typeof(object1[key])!='function' && object1[key] !== object2[key]) {
      return false;
    }
  }

  return true;
}