import React from "react";
import { Badge } from "reactstrap";
import { Cookies } from 'react-cookie';
import Base64 from 'Base64';
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import Card from "reactstrap/lib/Card";
import CardBody from "reactstrap/lib/CardBody";
import CardTitle from "reactstrap/lib/CardTitle";
import CardText from "reactstrap/lib/CardText";
import NotificationSystem from 'react-notification-system';
import { NOTIFICATION_SYSTEM_STYLE } from 'utils/constants';

const ViewDrive = React.lazy(() => import('vortex/ViewDrive/ViewDrive'));

const cookie =new Cookies();

const _BACKEND_URL_ = "https://node.renobuilding.ca:3001"
//const _BACKEND_URL_ = "http://localhost:3001"

class DriveGrid extends React.Component {
	notificationSystem = React.createRef();

    constructor (props){
        super(props);
        window.DriveGrid = this;
        this.state = {
			selectedFile:[],
			selectedFolderId : 0,
            parent : this.props.parent,
            projects : [],
            projectimages : [],
            indexedImages : [],
			selectedIndex : 0,
			selectedImage : '',
			image_id : 0,
			image_thumbnail : '',
			selectedImages : [],
        };
        this.fetchData(this.props.parent);
    }
    componentDidUpdate(prevProps, prevState, snapshot){
        if (this.props.parent != prevProps.parent) {
            this.fetchData(this.props.parent);
        }
    }
    fetchData(parent){
		let requestOptions = {
			method: 'GET',
			headers: {
				'Authorization':'Basic ' + Base64.btoa('sizotech:IsKfs$m$SZV#4Eprtj7zSPbO')
			}
		};
		
		//fetch(_BACKEND_URL_ + '/search?fullText=&mimeType=image/jpeg&parent=' + parent, requestOptions)
		fetch(_BACKEND_URL_ + '/file?parent=' + parent, requestOptions)
		.then(response => {
			return Promise.all([response.json(),response])
		})
		.then(([responseData,response]) => {
			this.setState({projectimages : responseData});
		})
		.catch(error => {
			console.error('Error:', error);
		})		

        fetch('https://renobuilding.ca/wp-json/reno/v1/rb_images', requestOptions)
            .then(response => {
                return Promise.all([response.json(),response])
            })
            .then(([responseData,response]) => {
                this.setState({indexedImages : responseData});
            })
            .catch(error => {
                console.error('Error:', error);
            })		

    }
	checkImage(e){
		e.persist();
		
		const selectedIndex = e.target.attributes[1].nodeValue;
		var _selectedImages = this.state.selectedImages;
		_selectedImages.push(this.state.projectimages[selectedIndex].thumbnailLink);
		this.setState({
			selectedIndex : selectedIndex,
			selectedImage : this.state.projectimages[selectedIndex].thumbnailLink,
			image_id : this.state.projectimages[selectedIndex].id,
			image_thumbnail : this.state.projectimages[selectedIndex].thumbnailLink,
			selectedImages : _selectedImages,
		});

		//this.setState({visible : true, assignFormVisible : false});
	};

	showDriveContent = (e) =>{
		this.setState({selectedFolderId : e.target.attributes[0].nodeValue});
		this.props.onChildrenChange(e.target.attributes[0].nodeValue);
	}
	onAttachClick = (e) =>{
		//e.persist();
		//const thumbnailLink = e.target.attributes[0].nodeValue;
		//this.props.onAttachClick(e);
	}
	/**
	 * About Google Drive things
	 * @param {*} event 
	 */
	 onFileChange = (event, id) => { 
		this.setState({ selectedFile: event.target.files }); 
		this.imageUpload(event.target.files, id);	
	}; 

	imageUpload(files, parent){
		for(var i=0; i < files.length ; i++){
			const formData = new FormData()
			formData.append('myfile', files[i]);
		
			//fetch(_BACKEND_URL_ + '/file?project=' + this.state.projects.prj_folder_id, {
			fetch(_BACKEND_URL_ + '/file?project=' + parent, {
					method: 'POST',
			body: formData
			})
			.then(response => response.json())
			.then(data => {
				this.addNotification(data.data.name + ' File(s) Uploaded');
				//this.addNotification(' File(s) Uploaded');
				//window.location.reload();	
			})
			.catch(error => {
			console.error(error)
			});
		}
	}

	imageDelete (_file_id){
			const formData = new FormData()
		
			//fetch(_BACKEND_URL_ + '/file?project=' + this.state.projects.prj_folder_id, {
			fetch(_BACKEND_URL_ + '/file/' + _file_id + '/delete', {
					method: 'GET',
			})
			.then(response => response.json())
			.then(data => {
				this.fetchData(this.props.parent);
				this.addNotification("File Deleted");
			})
			.catch(error => {
			console.error(error)
			});
		
	}

    render(){		
		var projectimages = this.state.projectimages;
		if(projectimages.length>0 && Object.keys(projectimages) && Object.getPrototypeOf(projectimages)){
			projectimages = this.state.projectimages.filter(i=>!i.trashed);
			return(
				<>
					<Row>
							{projectimages.map(i=>{
								return (
									<>
									{i.name!='Orders' && i.name!='Videos' ?
										<Col>
										<Card>
											<CardBody>							
												<CardTitle data-thumbnailLink={i.thumbnailLink} onClick={(e)=>{this.onAttachClick(i.thumbnailLink)}} >{i.name}</CardTitle>
												<CardText>
													{i.mimeType === 'application/vnd.google-apps.folder'?
														<>
															{/*<a href={"/#/ViewDrive/" + i.id } className="btn btn-secondary btn-sm  mx-1" role="button" aria-disabled="true">OPEN</a>*/}
															<form style={{display:'block'}} > 
																<div className="mb-3">
																	<a data-id={i.id} href="javascript:void(0);" onClick={(e)=>{this.showDriveContent(e)}} className="btn btn-secondary btn-sm  mx-1" role="button" aria-disabled="true">View</a>
																	<label htmlFor={"formFileMultiple"  + i.name}>
																		<i className="btn btn-outline-secondary btn-sm" >Upload</i>
																		<input className="form-control" style={{display: 'none'}}  type="file" id={"formFileMultiple"  + i.name} onChange={(event)=>this.onFileChange(event, i.id)} accept="image/*" multiple data-original-title="upload photos" />
																	</label>
																</div>
															</form>
														</>
													:
													<>
														{i.hasThumbnail?
																<>
																	<a style={{cursor:'pointer'}} onClick={(e)=>{this.imageDelete(i.id)}} target="_blank" >Delete</a>|
																	<a href={i.webViewLink} target="_blank" ><img id={'thumb_' + i} src={i.thumbnailLink} height="100px" alt="" />Preview</a>
																</>
															:
																<>
																	<a style={{cursor:'pointer'}} onClick={(e)=>{this.imageDelete(i.id)}} target="_blank" >Delete</a>|
																	<a href={i.webViewLink} target="_blank" >Preview</a>
																</>
														}
													</>
													}
												</CardText>
											</CardBody>
										</Card>
									</Col>
								:''}
									</>			
								)
							})
							}
					</Row>
					<Row>
						{this.state.selectedFolderId!=0?
							<DriveGrid onAttachClick={this.onAttachClick} parent={this.state.selectedFolderId} />
						:''}
					</Row>
					<NotificationSystem ref={this.notificationSystem} style={NOTIFICATION_SYSTEM_STYLE}/>
				</>
			);
	
		}else
			return (
			<p>Nothing to show!</p>
		)
    }
    addNotification (_MESSAGE_) {
		const notification = this.notificationSystem.current;
		notification.addNotification({message: _MESSAGE_, level: 'success'});
	};	

}


export default DriveGrid;