import React,{Suspense} from "react";
import ReactDOM from 'react-dom';
import DataAdapter from "../../adapter/DataAdapter";
import Dialog from "../../component/Dialog";

class Person {
    constructor(){

    }
    selectById = async (_id) =>{
        const _query    =   "select * from rb_person where person_id = " + _id;
        var dataAdapter =new DataAdapter(_query);
        var data = await dataAdapter.getRecords();
        return data;
    }
    updateById = async (_id, _field, _value) =>{
        const _query    =   "UPDATE rb_person SET " + _field + "='" + _value +  "' where person_id = " + _id;
        var dataAdapter =new DataAdapter(_query);
        var data = await dataAdapter.getRecords();
        return data;
    }
	openFormPerson		=	async(props)	=>{
		    //window.open('/#/FormPerson', '_blank');

		const _return = await new Promise((resolve, rej) => {
			const div	=	document.createElement('div');
			document.body.appendChild(div);
			const FormPerson = React.lazy(() => import('./FormPerson'));
			ReactDOM.render(
				<Suspense fallback={<div>Loading... </div>}>
					<FormPerson person={props} callback={(obj)=>resolve(obj)} />
				</Suspense>
			, div);		
			
		});
	
		return _return;
	}
	openViewPersons		=	async()	=>{
		const _return = await new Promise((resolve, rej) => {
			const div	=	document.createElement('div');
			document.body.appendChild(div);
			const ViewPersons = React.lazy(() => import('./ViewPersons'));
			ReactDOM.render(
				<Suspense fallback={<div>Loading... </div>}>
					<Dialog title="Person List"  isOpen={true} >
						<ViewPersons callback={(obj)=>resolve(obj)} />
					</Dialog>
				</Suspense>
			, div);		
			
		});
	
		return _return;
	}
	openViewPayStub		=	async()	=>{
		const _return = await new Promise((resolve, rej) => {
			const div	=	document.createElement('div');
			document.body.appendChild(div);
			const ViewPayStub = React.lazy(() => import('./ViewPayStub'));
			ReactDOM.render(
				<Suspense fallback={<div>Loading... </div>}>
					<Dialog title="Pay Stub" isOpen={true} >
						<ViewPayStub callback={(obj)=>resolve(obj)} />
					</Dialog>
				</Suspense>
			, div);		
			
		});
	
		return _return;
	}
	
}
export default Person;