import React from 'react';
import Base64 from 'Base64';
import { Card, CardBody, CardHeader, CardImg, CardLink, CardText, CardTitle, Col, Input, ListGroup, ListGroupItem, Row, Modal, ModalBody, ModalFooter, ModalHeader, Button, FormGroup, Label} from 'reactstrap';

import {isEqual} from "../lib/ObjectUtil";

class ComboBox extends React.Component  {
	constructor(props){
		super(props);
		this.state = {
            data : [],
            options			: this.props.options, 
            defaultValue	: this.props.default, 
            table			: this.props.table, 
            valueField		: this.props.valueField, 
            displayField	: this.props.displayField, 
            filterField		: this.props.filterField, 
            filterValue		: this.props.filterValue,
            orderby 		: this.props.orderby,
            attribute 		: this.props.attribute,
			title			: this.props.title,
        };
		let requestOptions = {
			method: 'GET',
			headers: {
				'Authorization':'Basic ' + Base64.btoa('sizotech:IsKfs$m$SZV#4Eprtj7zSPbO')
			}
		};
		fetch('https://renobuilding.ca/wp-json/reno/v1/query?table=' + this.state.table + '&filterfield=' + this.state.filterField + '&filtervalue=' + this.state.filterValue + (this.state.orderby?'&order=' + this.state.orderby:'') , requestOptions)
		.then(response => {
		    return Promise.all([response.json(),response])
		})
		.then(([data,response]) => {
            this.setState({data : data});
				if(data.length==1){
					if(this.props)this.props.onCallback((this.state.valueField.indexOf(',')!=-1?data[0][this.state.valueField.split(',')[0]]+','+data[0][this.state.valueField.split(',')[1]]:data[0][this.state.valueField]));
				}
			//console.log(responseData);
		})
		.catch(error => {
			console.error('Error:', error);
		})		
    }
    onChnageHandler = (event) =>{
        event.persist();
        //console.log(event.target.value);
        this.props.onChange(event);
    };
    componentWillReceiveProps(nextProps, prevState){
		if(!isEqual(nextProps, this.props)){
			let requestOptions = {
				method: 'GET',
				headers: {
					'Authorization':'Basic ' + Base64.btoa('sizotech:IsKfs$m$SZV#4Eprtj7zSPbO')
				}
			};
			fetch('https://renobuilding.ca/wp-json/reno/v1/query?table=' + nextProps.table + '&filterfield=' + nextProps.filterField + '&filtervalue=' + nextProps.filterValue + (nextProps.orderby?'&order=' + nextProps.orderby:'') , requestOptions)
			.then(response => {
			  return Promise.all([response.json(),response])
			})
			.then(([responseData,response]) => {
				this.setState({data : responseData});
				if(responseData.length==1){
					if(this.props)this.props.onCallback();
				}
				//console.log(responseData);
			})
			.catch(error => {
				console.error('Error:', error);
			})
		}
    }
    render(){
        const data = this.state.data;
        const options = (this.state.options? this.state.options :[]);
        return(
            <>
            <span>
                <Input type="select" multiple={this.props.multiple? this.props.multiple: false}  size={this.props.size? this.props.size:1} value={data.length>1 || true? this.props.defaultValue : (data.length==1 ? (this.state.valueField.indexOf(',')!=-1?data[0][this.state.valueField.split(',')[0]]+','+data[0][this.state.valueField.split(',')[1]]:data[0][this.state.valueField]):'')} id={this.props.id? this.props.id : this.state.table + '-' + this.state.filterField + '-' + this.state.filterValue } name={this.props.id? this.props.id : this.state.table + '-' + this.state.filterField + '-' + this.state.filterValue } onChange={this.onChnageHandler} >
                    <option value="-1" className="text-muted" >{this.state.title? this.state.title : '-'}</option>                                
                    {
                        options.map(item=>{
                            return(
                                <>
                                    <option value={item.key} style={{backgroundColor:'gray', color: 'white'}} >{item.value}</option>                                
                                </>
                            )
                        })
                    }
                    {
                        data.map(item=>{
                            return(
                                <>
                                    <option attribute={item[this.state.attribute]} value={this.state.valueField.indexOf(',')!=-1? item[this.state.valueField.split(',')[0]] + ',' + item[this.state.valueField.split(',')[1]] + (this.state.valueField.split(',')[2]? ','+item[this.state.valueField.split(',')[2]]:'') : item[this.state.valueField]} >{item[this.state.displayField.split(',')[0]]} {item[this.state.displayField.split(',')[1]]} {item[this.state.displayField.split(',')[2]]}</option>                                
                                </>
                            )
                        })
                    }
                </Input>
            </span>
            </>
        );
    }
}

export default ComboBox;