import React, { Component, useEffect, useState } from 'react';
import Base64 from 'Base64';

import Page from 'components/Page';
import {Input, Modal, ModalBody, ModalFooter, ModalHeader, Button, Col, Row } from 'reactstrap';


import NotificationSystem from 'react-notification-system';
import { NOTIFICATION_SYSTEM_STYLE } from 'utils/constants';

import DataAdapter from './adapter/DataAdapter';
import Task from './model/Tasks/Task';
import ViewTask from './model/Tasks/ViewTask';
import FormTaskAction from './FormTaskAction';
import ComboBox from './component/ComboBox';
import {Grid, Filter} from './component/Grid';

import { Cookies } from 'react-cookie';
const cookie =new Cookies();


var __PERSON__, __STATUS__ ="0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25";
var __PERSON_ROLE__;


class ViewBoards extends React.Component {
	notificationSystem = React.createRef();

	constructor(props){
		super(props);
		__PERSON__ = cookie.get("v-person-id");
		__PERSON_ROLE__ = cookie.get("v-person-role");
        if(props.match){
            __STATUS__ = this.props.match.params.status;
        }else{
            __STATUS__ = this.props.status;
        }
		__STATUS__ = (__STATUS__==null?"0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25": __STATUS__);

		var date = new Date();
		var _fromdate = (new Date(date.getFullYear(), date.getMonth(), 1)).toISOString().split('T')[0];
		var _todate = (new Date(date.getFullYear(), date.getMonth() + 1, 0)).toISOString().split('T')[0];
		// var _fromdate = data.getFullYear() + '-' + (data.getMonth() < 10? '0':'') + (data.getMonth()+1) + (data.getDate() > 15 ?  '-16' : '-01' );
		// var _todate = data.getFullYear() + '-' + (data.getMonth() < 10? '0':'') +  (data.getMonth()+1) + (data.getDate() > 15 ?  '-31' : '-15' );

		// if(__PERSON_ROLE__*1!=1 || __PERSON_ROLE__*1!=2){
		// 	_fromdate = data.getFullYear() + '-01-01';
		// 	_todate = data.getFullYear() + '-12-29';
		// }

		this.state = {projects : [], dataset : [], dataset_filtered : [], columns : __STATUS__.split(','), person : __PERSON__,  status : __STATUS__, loadingActionForm: false, loadingProjectManager:false, actionModal: false, task_id:0, task: null, 
			filter_person_sender		:	-1,
			filter_person_receiver		:	__PERSON__,
			filter_department			:	-1,
			filter_board				:	-1,
			filter_from					: _fromdate, 
			filter_to					: _todate,
			filter_project				:	-1,
			current_project_id			:0,
			current_task_id				:0,
			current_task				:'',
			current_task_desc			:'',
			inProgress					:	false,
			sender_id					: 	false,
			receiver_id					: 	true,
		};
		this.loadData(__STATUS__, __PERSON__);
	}
	loadData = (_status, _person) => {
		var query = "call Get_Boards();";
		var dataAdapter =new DataAdapter(query);
			dataAdapter.getRecords()
			.then(response=>{
				this.setState({projects : response, dataset : response});
				this.setState({
					//projects				: response.filter(p=>(p["expected_end_date"] >= this.state.filter_from && p["expected_end_date"] <= this.state.filter_to   ))/*.filter(p=>(p["receiver_id"] == cookie.get("v-person-id") ))*/, 
					projects				: response,
					dataset					: response,
					dataset_filtered		: response,
					//filter_person_receiver	: cookie.get("v-person-id")
				});
			})
	}
	componentWillReceiveProps(nextProps) {
		/*
		var query = "SELECT prj.prj_department,de.rb_type_desc AS department,prj.prj_id, prj.prj_code, prj.prj_title, prj.prj_address, prj.prj_city, pt.pt_id, pt.task, pt.task_description, p.name AS sender_id, pp.name AS receiver, pt.receiver_id, pt.sender_id, pt.task_status AS task_status_id, ty.rb_type_desc as task_status, pt.expected_start_date, pt.start_date, pt.expected_end_date, pt.end_date, pt.percent_done, pt.note, pt.task_type AS `task_type_id`, tyt.rb_type_desc AS `task_type`, tyt.rb_type_color, pt.percent_done, pt.priority, pt.real_hours, pt.estimate_hours FROM rb_projects prj INNER JOIN rb_project_tasks pt ON pt.prj_id = prj.prj_id  LEFT JOIN rb_person p ON p.person_id = pt.sender_id LEFT JOIN rb_person pp ON pp.person_id = pt.receiver_id INNER JOIN rb_types ty ON ty.rb_type_no = pt.task_status AND ty.rb_type = 'task_status' LEFT JOIN rb_types de ON de.rb_type_no=prj.prj_department AND de.rb_type='prj_department' LEFT JOIN rb_types tyt ON tyt.rb_type_no = pt.task_type AND tyt.rb_type = 'task_type'  ORDER BY ty.rb_type_no , pt.priority, pt.pt_id desc;";
		var dataAdapter =new DataAdapter(query);
			dataAdapter.getRecords()
			.then(response=>{
				this.setState({projects : response, dataset : response});
			})
			*/
			
	}	

    componentDidUpdate(prevProps, prevState, snapshot){
        if (this.props.status != prevProps.status) {
			this.setState({person : prevProps.person,  status : prevProps.status});
			this.loadData(prevProps.status, prevProps.person);
		}
        if (this.state.sender_id != prevState.sender_id || this.state.receiver_id != prevState.receiver_id) {
			this.loadData(__STATUS__, __PERSON__);
		}
    }
	
	onClearFilter=(e)=>{
		e.persist();
		this.setState({
			dataset_filtered			:	this.state.dataset,
			//projects					:	this.state.dataset.filter(p=>(p["expected_end_date"] >= this.state.filter_from && p["expected_end_date"] <= this.state.filter_to   )),
			projects					:	this.state.dataset,
			filter_person_sender		:	-1,
			filter_person_receiver		:	-1,
			filter_department			:	-1,
			filter_board				:	-1,
			filter_project				:	-1,
		})

	}
	onFilterChange = (e) =>{
		e.persist();
		console.log(e);
		if(e.target.id=='sender_id'){
			this.setState({
				filter_person_sender		:	e.target.value,
			})	
		}
		if(e.target.id=='receiver_id'){
			this.setState({
				filter_person_receiver		:	e.target.value,
			})	
		}
		if(e.target.id=='prj_department'){
			this.setState({
				filter_department		:	e.target.value,
			})				
		}
		if(e.target.id=='task_type_id'){
			this.setState({
				filter_board		:	e.target.value,
			})				
		}
		var dataset_filtered = this.state.dataset_filtered;
		
		// var projects = this.state.dataset; //Reset projects from
		var valueField	=	e.target.attributes[0].value;
		this.setState({
			dataset_filtered	:	dataset_filtered.filter(p=>p[valueField]==e.target.value || e.target.value==-1),
			//projects			:	dataset_filtered.filter(p=>p[valueField]==e.target.value || e.target.value==-1).filter(p=>(p["expected_end_date"] >= this.state.filter_from && p["expected_end_date"] <= this.state.filter_to   )),
			projects			:	dataset_filtered.filter(p=>p[valueField]==e.target.value || e.target.value==-1),
		})
		//this.setState({dataset	:	projects.filter(p=>p[valueField]==e.target.value || e.target.value==-1)})
	}
	onFilterDateChange = (_fromdate, _todate) =>{
		var projects = this.state.dataset_filtered;
		this.setState({projects : projects.filter(p=>(p["expected_end_date"] >= _fromdate && p["expected_end_date"] <= _todate   ))});

	}

	onFromDateChange = (e) =>{
		e.persist();
		var projects = this.state.dataset;
		var valueField	=	e.target.attributes[0].value;
		this.setState({filter_from : e.target.value, projects	:	projects.filter(p=>p["expected_end_date"]>=e.target.value && (this.state.filter_to != '' ? p["expected_end_date"]<=this.state.filter_to : true) )})
		//this.setState({dataset	:	projects.filter(p=>p[valueField]>=e.target.value )})
	}
	onToDateChange = (e) =>{
		e.persist();
		var projects = this.state.dataset;
		var valueField	=	e.target.attributes[0].value;
		this.setState({filter_to : e.target.value, projects	:	projects.filter(p=>(this.state.filter_from != ''?p["expected_end_date"]>=this.state.filter_from : true) && p["expected_end_date"]<=e.target.value   )})
		//this.setState({dataset	:	projects.filter(p=>p[valueField]<=e.target.value )})
	}

	onStatusChnageHandler = (e) => {
		this.setState({ prj_status: e.target.value });
		this.runQuery("UPDATE rb_projects SET prj_status = '" + e.target.value + "' WHERE prj_id=" + this.state.prj_id, 'Status Updated!');
		this.runQuery("INSERT INTO rb_project_tasks (prj_id,sender_id, receiver_id, start_date, task_status,task_type,task,task_description,expected_end_date,estimate_hours) VALUES (" + this.state.prj_id + "," + cookie.get("v-person-id") + "," + cookie.get("v-person-id") + ",now(), 8," + e.target.value + ",'" + this.state.task.prj_title + "','', now(),1);", "Project status has been changed")
	}
	onPreStatus = (e)=>{
		this.setState({ prj_status: (this.state.status*1-1)  });
		this.runQuery("UPDATE rb_projects SET prj_status = " + (this.state.status*1-1)  + " WHERE prj_id=" + this.state.prj_id, "Project Status changed");
	}
	onNxtStatus = (e)=>{
		this.setState({ prj_status: (this.state.status*1+1) });
		this.runQuery("UPDATE rb_projects SET prj_status = " + (this.state.status*1+1)  + " WHERE prj_id=" + this.state.prj_id, "Project Status changed");
	}

	onHoldHandler = (e) => {
		this.runQuery("UPDATE rb_project_tasks SET task_status = 12 WHERE prj_id=" + this.state.prj_id, "Task has been holded");
		this.runQuery("UPDATE rb_projects SET prj_status = 12 WHERE prj_id=" + this.state.prj_id, "Project has been holded");
	}
	onCloseHandler = (e) => {
		e.persist();
		this.runQuery("UPDATE rb_project_tasks SET task_status = 10 WHERE prj_id=" + this.state.prj_id, "Task has been closed");
		this.runQuery("UPDATE rb_projects SET prj_status = prj_status + 13 WHERE prj_id=" + this.state.prj_id, "Project has been closed");
	}
	
	onInprogrss	=	() =>{
		var projects = this.state.dataset_filtered;		
		if(!this.state.inProgress)
			this.setState({projects	:	projects.filter(p=>p["clockedIn"]*1==1)});
		else
			this.setState({projects	:	projects});
		
		this.setState({inProgress : !this.state.inProgress});
	}
	

    addNotification (_MESSAGE_) {
		const notification = this.notificationSystem.current;
		notification.addNotification({
		  message: _MESSAGE_,
		  level: 'info'
		});
	};	

	onProjectManager = (e) =>{
		e.persist();
		if(this.state.projectManagerModal){
			this.setState({projectManagerModal: false});
		}else{
			this.setState({
				projectManagerModal	:	true,
				loadingProjectManager				:	true,
				prj_id				:	e.target.attributes[0].value,
				prj_status			:	e.target.attributes[1].value,
			})
		}
	}
	onActionHanlder = (e) =>{
		e.persist();
		if(this.state.actionModal){
			this.setState({actionModal: false});
		}else{
			this.setState({
				actionModal			:	true,
				loadingActionForm	:	true,
				task_id				:	e.target.attributes[0].value,
				task_status			:	e.target.attributes[1].value,
				task_task			:	e.target.attributes[2].value,
				task_description	:	e.target.attributes[3].value,
				task_percent_done	:	e.target.attributes[4].value,
				task_note			:	e.target.attributes[5].value,
				task_end			:	e.target.attributes[6].value,
				task_priority		:	e.target.attributes[7].value,
				task_real_hours		:	e.target.attributes[8].value,
				task_estimate_hours	:	e.target.attributes[9].value,
				task_project		:	e.target.attributes[10].value,
				task_sender			:	e.target.attributes[11].value,
				task_receiver		:	e.target.attributes[12].value,
				task_type			:	e.target.attributes[13].value,
				task_start			:	e.target.attributes[14].value,
				task_expected_end	:	e.target.attributes[15].value,
			});
		}
	}
	onTaskStatusChnageHandler=(e)=>{
		this.setState({task_status	:	e.target.value});	
		this.runQuery("UPDATE rb_project_tasks SET task_status = '" + e.target.value + "' WHERE pt_id=" + this.state.task_id, 'Task Status Updated!');

	}
	onTaskTypeChnageHandler=(e)=>{
		this.setState({task_status	:	e.target.value});	
		this.runQuery("UPDATE rb_project_tasks SET task_type = '" + e.target.value + "' WHERE pt_id=" + this.state.task_id, 'Task Status Updated!');

	}

	onTaskPercentChangeHandler=(e)=>{
		this.setState({task_percent_done	:	e.target.value});	
		this.runQuery("UPDATE rb_project_tasks SET percent_done = '" + e.target.value + "' WHERE pt_id=" + this.state.task_id, 'Task Percent Updated!');
	}
	onTaskNoteChangeHandler=(e)=>{
		this.setState({task_note	:	e.target.value});	
		this.runQuery("UPDATE rb_project_tasks SET note = '" + e.target.value + "' WHERE pt_id=" + this.state.task_id, 'Task Note Updated!');
	}
	onTaskStartChangeHandler=(e)=>{
		this.setState({task_end	:	e.target.value});	
		this.runQuery("UPDATE rb_project_tasks SET start_date = '" + e.target.value + "' WHERE pt_id=" + this.state.task_id, 'Task Start date Updated!');
	}
	onTaskEndChangeHandler=(e)=>{
		this.setState({task_end	:	e.target.value});	
		this.runQuery("UPDATE rb_project_tasks SET end_date = '" + e.target.value + "' WHERE pt_id=" + this.state.task_id, 'Task End Date Updated!');
	}
	onTaskExpectedEndChangeHandler=(e)=>{
		this.setState({task_expected_end	:	e.target.value});	
		this.runQuery("UPDATE rb_project_tasks SET expected_end_date = '" + e.target.value + "' WHERE pt_id=" + this.state.task_id, 'Task expected End Date Updated!');
	}
	onTaskPriorityChangeHandler=(e)=>{
		this.setState({task_end	:	e.target.value});	
		this.runQuery("UPDATE rb_project_tasks SET priority = '" + e.target.value + "' WHERE pt_id=" + this.state.task_id, 'Task Priority Updated!');
	}
	onTaskRealChangeHandler=(e)=>{
		this.setState({task_end	:	e.target.value});	
		this.runQuery("UPDATE rb_project_tasks SET real_hours = '" + e.target.value + "' WHERE pt_id=" + this.state.task_id, 'Task real hours Updated!');
	}
	onTaskEstimateChangeHandler=(e)=>{
		this.setState({task_end	:	e.target.value});	
		this.runQuery("UPDATE rb_project_tasks SET estimate_hours = '" + e.target.value + "' WHERE pt_id=" + this.state.task_id, 'Task estimate hours Updated!');
	}
	
	onClockInHandler=(e, _project)=>{
		e.persist();
		this.setState({ projects : _project});
		var _UTC_ = (new Date()).toISOString().split('T')[0] + ' ' + (new Date()).toISOString().split('T')[1].slice(0, 5);
		var	sql ="INSERT INTO rb_project_task_details ( pt_id, prj_id, task_status, task_type, sender_id, receiver_id, start_date, Clock_in ) values " +
				"(" + e.target.attributes["data-task-id"].nodeValue + ", " + e.target.attributes["data-project-id"].nodeValue + ", 9 , 122 , " + cookie.get('v-person-id') + "," + cookie.get('v-person-id') + ", '" + _UTC_ + "', '" + _UTC_ + "')" ;

		this.runQuery( sql, "Clocked In", "success");
		this.runQuery("UPDATE rb_project_tasks SET task_status = 9, end_date=now() WHERE pt_id=" + e.target.attributes["data-task-id"].nodeValue, "Task status has been updated", "info");
		// this.setState({actionModal: true});


	}
	
	onClockOutHandler= async (e, _project)=>{
		e.persist();
		this.setState({ projects : _project});
		var task =new Task();
		var _response	=	await task.clockOut(cookie.get('v-person-id'), e.target.attributes["data-task-id"].nodeValue);
		//console.log(_response);
	}

	onCalculateHandler=(e, _project)=>{
		e.persist();

		var sql	=	"UPDATE rb_project_tasks SET real_hours = (" +
					"SELECT sum(TIMESTAMPDIFF(MINUTE, Clock_in, Clock_out)) FROM `rb_project_task_details`" +
					"WHERE pt_id = " + e.target.attributes["data-task-id"].nodeValue + " AND task_status=10 AND task_type=98 AND sender_id=" + e.target.attributes["data-task-receiver"].nodeValue + "), percent_done = (select sum(percent_done) from rb_project_task_details WHERE pt_id = " + e.target.attributes["data-task-id"].nodeValue + " AND task_status=10 AND task_type=98 AND sender_id=" + e.target.attributes["data-task-receiver"].nodeValue + ") where pt_id = " + e.target.attributes["data-task-id"].nodeValue  + ";";

		this.runQuery(sql, "Real hours calculated", "info");		
		//console.log(sql);

	}
	
	projectManagerForm = () => {
		if(this.state.loadingProjectManager){
			var query = "SELECT * FROM rb_projects WHERE prj_id=" + this.state.prj_id;
			var dataAdapter =new DataAdapter(query);
				dataAdapter.getRecords()
				.then(response=>{
					this.setState({
						task : response[0], 
						loadingProjectManager : false, 
						projectManagerModal:true});
				})	
		}
		
		return (
			<>
				<Modal isOpen={this.state.projectManagerModal} >
					<ModalHeader>
						<button type="button" className="btn btn-primary btn-sm" onClick={(event)=>{this.setState({projectManagerModal	:	false})}} >X</button>
					</ModalHeader>
					<ModalBody>
						<ComboBox id="c1" onChange={this.onStatusChnageHandler} table="rb_types" valueField="rb_type_no" displayField="rb_type_desc" filterField="rb_type" filterValue="prj_status" defaultValue={this.state.prj_status} order="rb_type_no" />{'	'}
						<button id={'pre'} type="button" className="btn btn-outline-secondary btn-sm" onClick={(event) => { this.onPreStatus(event) }} >Pre Status</button>{'	'}
						<button id={'nxt'} type="button" className="btn btn-outline-secondary btn-sm" onClick={(event) => { this.onNxtStatus(event) }} >Next Status</button>{'	'}
						{this.state.prj_status * 1 <= 12 ?
							<button type="button" className="btn btn-outline-info btn-sm" onClick={(event) => { this.onHoldHandler(event) }} >Hold/Stop the Job</button>
							: ''}{'			'}
						{this.state.prj_status * 1 <= 13 ?
							<button type="button" className="btn btn-outline-danger btn-sm" onClick={(event) => { this.onCloseHandler(event) }} >Close the Job</button>
							: ''}
					</ModalBody>
				</Modal>
			</>
		);
	}

	actionForm = () => {
		if(this.state.loadingActionForm){
			
			var query = "SELECT * FROM rb_project_tasks WHERE pt_id=" + this.state.task_id;
			//console.log(query);
			var dataAdapter =new DataAdapter(query);
				dataAdapter.getRecords()
				.then(response=>{
					this.setState({task : response, loadingActionForm : false, actionModal:true});
				})	
				
			query = "SELECT * FROM rb_project_task_details WHERE pt_id=" + this.state.task_id + " AND sender_id=" + cookie.get('v-person-id') + " AND task_type=98 AND Clock_in is not null AND Clock_out is null";
			dataAdapter =new DataAdapter(query);
				dataAdapter.getRecords()
				.then(response=>{
					this.setState({task_detail : response, loadingActionForm : false, actionModal:true});
				})	
		}
		const options = {
			year: "numeric",
			month: "2-digit",
			day: "2-digit",
			hour:"2-digit",
			minute:"2-digit"
		};

		return (
			<>
				<Modal isOpen={this.state.actionModal} >
					<ModalHeader>
						<Row>
							<Col>
								{"Project "  + this.state.task_project + " - Task " + this.state.task_id}
							</Col>
							<Col>
							</Col>
							<Col>
								<button data-task-id={this.state.task_id} data-project-id={this.state.task_project} type="button" className="btn btn-primary btn-sm float-right ml-1 mr-1" onClick={(e) => {
									e.persist();
									this.setState({actionModal:false});
									
								}} >x</button>{'	'}
							</Col>
						</Row>
					</ModalHeader>
					<ModalBody>
						<ViewTask project={this.state.projects.find(p=>p.pt_id == this.state.task_id)} projectId={this.state.task_project} taskId={this.state.task_id} onChangeTaskStatus={(e)=>this.onChangeTaskStatus(e)} callback={(obj)=>{this.callback(obj);}} />
					</ModalBody>
				</Modal>
			</>
		);
	}
	runQuery=(query, message)=>{
		let formData = new FormData();
		formData.append("query", query);
		let requestOptions = {method: 'POST',headers: {'Authorization':'Basic ' + Base64.btoa('sizotech:IsKfs$m$SZV#4Eprtj7zSPbO')},body : formData};
		fetch('https://renobuilding.ca/wp-json/reno/v1/query/' , requestOptions)
			.then(response => {
				return Promise.all([response.json(),response])
			})
			.then(([responseData,response]) => {
				console.log(responseData);
				this.addNotification(message);
					
			})
			.catch(error => {
				console.error('Error:', error);
			})		
	}
	drag = (e) => {
		e.persist();
		if(this.state.projects){
			e.dataTransfer.setData("text", e.target.id);
		}
	}
	drop = (e) => {
		e.persist();
		if(e.target.attributes['status']){
			var _pt_id = e.dataTransfer.getData("text");
			var _projects	= this.state.projects;
			var _index	= _projects.findIndex(p=>p.pt_id == _pt_id);
			_projects[_index].task_status	 = e.target.attributes['status'].nodeValue;
			_projects[_index].task_status_id = e.target.attributes['status-id'].nodeValue;
			this.runQuery("UPDATE rb_project_tasks SET task_status = " + e.target.attributes['status-id'].nodeValue + ", end_date=now() WHERE pt_id=" + _pt_id, "Task status has been updated");
			if(e.target.attributes['status-id'].nodeValue*1==10){
				_projects[_index].estimate_hours = Math.round(_projects[_index].real_hours/60);
				this.runQuery("UPDATE rb_project_tasks SET estimate_hours = ROUND(real_hours/60) WHERE (estimate_hours is null or estimate_hours=0) and  pt_id=" + _pt_id, "Task estimate hours has been updated");
			}
			this.setState({ projects : _projects});

		}
	}
	remove =(_index)=>{
		var _projects	= this.state.projects;
		this.runQuery("DELETE FROM rb_project_tasks WHERE pt_id=" + _projects[_index].pt_id, "Task #" + _projects[_index].pt_id + " removed");
		_projects.splice(_index, 1);
		this.setState({ projects : _projects});									
	}
	nextScrum =(_index)=>{
		var _projects	= this.state.projects;
		var expected_end_date = new Date(_projects[_index].expected_end_date);
		var expected_end_date_ =new Date(expected_end_date.setMonth(expected_end_date.getMonth() +1)).toISOString().split('T')[0];
		//var expected_end_date_ = (new Date(expected_end_date.getFullYear(), expected_end_date.getMonth()+1, expected_end_date.getDate()+1)).toISOString().split('T')[0];
		
		this.runQuery("UPDATE rb_project_tasks SET expected_end_date='" + expected_end_date_ + "' WHERE pt_id=" + _projects[_index].pt_id, "Task #" + _projects[_index].pt_id + " moved to the next month");
		_projects[_index].expected_end_date	=	expected_end_date_;
		this.setState({ projects : _projects.filter(p=>(p["expected_end_date"] >= this.state.filter_from && p["expected_end_date"] <= this.state.filter_to   ))});									
	}
	preScrum =(_index)=>{
		var _projects	= this.state.projects;
		var expected_end_date = new Date(_projects[_index].expected_end_date);
		var expected_end_date_ =new Date(expected_end_date.setMonth(expected_end_date.getMonth() -1)).toISOString().split('T')[0];
		//var expected_end_date_ = (new Date(expected_end_date.getFullYear(), expected_end_date.getMonth()-1, expected_end_date.getDate())).toISOString().split('T')[0];
		this.runQuery("UPDATE rb_project_tasks SET expected_end_date='" + expected_end_date_ + "' WHERE pt_id=" + _projects[_index].pt_id, "Task #" + _projects[_index].pt_id + " moved to the pre month");
		_projects[_index].expected_end_date	=	expected_end_date_;
		this.setState({ projects : _projects.filter(p=>(p["expected_end_date"] >= this.state.filter_from && p["expected_end_date"] <= this.state.filter_to   ))});									
	}

	onChangeTaskStatus =(e)=>{
		var _projects	= this.state.projects;
		var _index	= _projects.findIndex(p=>p.pt_id == e.target.attributes['data-id'].nodeValue);
		_projects[_index].task_status	 = e.target.attributes['data-status'].nodeValue;
		_projects[_index].task_status_id = e.target.attributes['data-status-id'].nodeValue;
		this.setState({ projects : _projects});
	}

	callback					=   (obj)	=>{
		var _projects	= this.state.projects;
		if(obj.callbackType == 'status'){
				var _index	= _projects.findIndex(p=>p.pt_id == obj.response[0].pt_id);
				if(_index!=-1){
					_projects[_index].task_type = obj.response[0].task_type_desc;
					_projects[_index].task_status_id = obj.response[0].task_status;
					_projects[_index].task_status = obj.response[0].task_status_desc;
				}
				this.setState({ projects : _projects, actionModal : false});
		}
	}

	render(){
		var projects = this.state.projects;
		return (
			<Page className="DashboardPage" title="Task Boards" >
				<Row>
					<Col lg={2} md={2} sm={12} xs={12} >
						<Filter text="Board" displayField="prj_name" valueField="prj_name" projects={this.state.dataset_filtered} onFilterChange={this.onFilterChange} defaultValue={this.state.prj_name } />{'	'}
					</Col>
					<Col lg={2} md={2} sm={12} xs={12} >
						<Filter text="Receiver" displayField="receiver" valueField="receiver_id" projects={this.state.dataset_filtered} onFilterChange={this.onFilterChange} defaultValue={this.state.filter_department} />{'	'}
					</Col>
					<Col lg={2} md={2} sm={12} xs={12} >
						<Filter text="Task Type" displayField="task_type" valueField="task_type_id" projects={this.state.dataset_filtered} onFilterChange={this.onFilterChange} defaultValue={this.state.filter_board} />{'	'}
					</Col>
					<Col lg={2} md={2} sm={12} xs={12} >
						<button class="btn btn-primary btn-sm " onClick={(event)=>{this.onClearFilter(event)}} >Clear Filter</button>{'		'}
					</Col>
					<Col lg={12} md={12} sm={12} xs={12} >						
						<Grid onCalculateHandler={(event)=>this.onCalculateHandler(event)} clockIn={(event, _projects)=>this.onClockInHandler(event, _projects)} clockOut={(event, _projects)=>this.onClockOutHandler(event, _projects)} columns={this.state.columns} projects={this.state.projects} onActionHanlder={(e)=>{this.onActionHanlder(e)}} onProjectManager={(e)=>{this.onProjectManager(e)}} drag={(e)=>{this.drag(e)}} drop={(e)=>{this.drop(e)}} remove={(i)=>{this.remove(i)}} nextScrum={(i)=>this.nextScrum(i)} preScrum={(i)=>this.preScrum(i)} />
					</Col>
				</Row>	
				<NotificationSystem ref={this.notificationSystem} style={NOTIFICATION_SYSTEM_STYLE}/>
				{this.projectManagerForm()}
				{this.actionForm()}
			</Page>
		);
	}

}

export default ViewBoards;