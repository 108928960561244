import React, {useState, useEffect} from 'react';
import Base64 from 'Base64';
import { Card, CardBody, CardHeader, CardImg, CardLink, CardText, CardTitle, Col, Input, ListGroup, ListGroupItem, Row, Modal, ModalBody, ModalFooter, ModalHeader, Button, FormGroup, Label} from 'reactstrap';
import DataAdapter from "../../adapter/DataAdapter";

function ComboBox (props){
	const [data, setData]					=	useState([]);
	const [valueField, setValueField]		=	useState({});
	const [displayField, setDisplayField]	=	useState({});
	const onChnageHandler = (event) =>{
        event.persist();
        props.onChange(event);
    };

	useEffect(()=>{
		if(props.dataSource && props.dataSource.type==='json'){
			setData(props.dataSource.data);
			setValueField(props.dataSource.valueField);
			setDisplayField(props.dataSource.displayField);			
		}
			/*
		else{
			(new DataAdapter(props.queryString)).getRecords().then(responseData=>{
				this.setState({data : responseData});
				if(responseData && responseData.length==1){
					//this.props.onCallback((this.state.valueField.indexOf(',')!=-1?responseData[0][this.state.valueField.split(',')[0]]+','+responseData[0][this.state.valueField.split(',')[1]]:responseData[0][this.state.valueField]));
				}				
			})
			*/
		},[props]);
	
	return(
		<>
		{data?
			<select disabled={props.disabled} className={props.className} style={props.style}  type="select" multiple={props.multiple? props.multiple: false}  size={props.size? props.size:1} value={props.defaultValue } id={props.id? props.id : '' } name={props.id? props.id : '' } onChange={onChnageHandler} >
				<option value="-1" >{props.title? props.title : '-'}</option>                                
				{
					data.map(item=>{
						return(
							<>
								<option title={displayField.indexOf(',')!=-1?item[displayField.split(',')[0]] + ' ' + item[displayField.split(',')[1]]:item[displayField]} attribute={item[props.attribute]} value={item[valueField]} >{(displayField.indexOf(',')!=-1?item[displayField.split(',')[0]] + ' ' + item[displayField.split(',')[1]]:item[displayField])} </option>                                
							</>
						)
					})
				}
			</select>
		:''}
		</>
	);
	
}
export default ComboBox;
