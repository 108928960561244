import React,{useState, Suspense} from "react";
import ReactDOM from 'react-dom';
import DataAdapter from "../../adapter/DataAdapter";
import Dialog from "../../component/Dialog";
import { Cookies } from 'react-cookie';

import { Card, CardBody, CardHeader, CardImg, CardLink, CardText, CardTitle, Col, Input, ListGroup, ListGroupItem, Row, Modal, ModalBody, ModalFooter, ModalHeader, Button, FormGroup, Label} from 'reactstrap';

const cookie =new Cookies();

class Chat {
    constructor(){

    }
	load			= async (_prj_id)	=>{
        const _query    =   "SELECT prj_id AS `roomId`, sender_id AS `senderId`,  task_description AS `message`, start_date AS `timestamp` FROM rb_project_task_details WHERE task_type=116 AND prj_id=" + _prj_id ;
        var dataAdapter =new DataAdapter(_query);
        var data = await dataAdapter.getRecords();
        return data;
		
	}
	
	save			= async (_taskDetail)	=>{
        const _query    =   "INSERT INTO rb_project_task_details (prj_id, task_type, task_status, sender_id,  task_description, start_date, end_date) VALUES (" + _taskDetail.roomId + ",116,8," + _taskDetail.senderId + ",'" + _taskDetail.message + "','" + _taskDetail.timestamp + "','" + _taskDetail.timestamp + "');\r\nSELECT * FROM rb_project_task_details WHERE prj_id=" + _taskDetail.prj_id + " ORDER BY ptd_id DESC LIMIT 1";
        var dataAdapter =new DataAdapter(_query);
        var data = await dataAdapter.getRecords();
        return data;
		
	}
	openChatRooms	= async	(_roomID)	=>{
		
		const _return = await new Promise((resolve, rej) => {
			const div	=	document.createElement('div');
			document.body.appendChild(div);
			const ChatWindow = React.lazy(() => import('./ChatWindow'));
			ReactDOM.render(
				<Suspense fallback={<div>Loading... </div>}>
					<Dialog title="Chat Rooms" isOpen={true} isProgressing={false} notify="" >
						<ChatWindow roomId={_roomID} userName={cookie.get('v-person-id')} callback={(obj)=>resolve(obj)} />
					</Dialog>
				</Suspense>
			, div);
		});
	
		return _return;
	}	

}
export default Chat;