import React,{Suspense} from "react";
import ReactDOM from 'react-dom';
import DataAdapter from "../../adapter/DataAdapter";
import Dialog from "../../component/Dialog";


class ReceiveAndPayment {
    constructor(){
    }

	openReceiveAndPayment		=	async(_project)	=>{
		const _return = await new Promise((resolve, rej) => {
			const div	=	document.createElement('div');
			document.body.appendChild(div);
			const FormReceiveAndPayment = React.lazy(() => import('./FormReceiveAndPayment'));
			ReactDOM.render(
				<Suspense fallback={<div>Loading... </div>}>
					<FormReceiveAndPayment project={_project} callback={(obj)=>resolve(obj)} />
				</Suspense>
			, div);		
			
		});
	
		return _return;
	}
	
	viewReceiveAndPayment		=	async(_project)	=>{
		const _return = await new Promise((resolve, rej) => {
			const div	=	document.createElement('div');
			document.body.appendChild(div);
			const ViewReceiveAndPayment = React.lazy(() => import('./ViewReceiveAndPayment'));
			ReactDOM.render(
				<Suspense fallback={<div>Loading... </div>}>
					<Dialog title="" isOpen={true} isProgressing={false} notify="" >
						<ViewReceiveAndPayment project={_project} callback={(obj)=>resolve(obj)} />
					</Dialog>
				</Suspense>
			, div);		
			
		});
	
		return _return;
	}

}
export default ReceiveAndPayment;