import React,{useState, UseEffect, Suspense} from "react";
import ReactDOM from 'react-dom';
import DataAdapter from "../adapter/DataAdapter";
import Dialog from "../component/Dialog";

class LayoutUtil {
	constructor(){
	}
	/**
		Views
	*/
	openProjectMenu		=	async(_project)	=>{
		const _return = await new Promise((resolve, rej) => {
			const div	=	document.createElement('div');
			document.body.appendChild(div);
			const Tiles = React.lazy(() => import('./view/Tiles'));
			ReactDOM.render(
				<Suspense fallback={<div>Loading... </div>}>
					<Dialog title={'PROJECT ' + _project.prj_id} isOpen={true} isProgressing={false} notify={''} >
						<Tiles project={_project} callback={(obj)=>resolve(obj)} />
					</Dialog>
				</Suspense>
			, div);		
			
		});
	
		return _return;
	}
	
}
export default LayoutUtil;