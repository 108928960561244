import React, {useState, useEffect} from 'react';
import { Row, Col, Input, Modal, ModalHeader, ModalBody} from 'reactstrap';

import NotificationSystem from 'react-notification-system';
import { NOTIFICATION_SYSTEM_STYLE } from 'utils/constants';

const notificationSystem = React.createRef();
const addNotification = (_MESSAGE_, _TYPE_) => {
	const notification = notificationSystem.current;
	notification.addNotification({
	  message: _MESSAGE_,
	  level: _TYPE_
	});
};	

function Dialog(props){
    const [isOpen, setIsOpen] 					= useState(false);
    const [isProgressing, setIsProgressing] 	= useState(false);
    const [title, setTitle] 					= useState('');
    const [notify, setNotify] 					= useState('');
	
	useEffect(()=>{
		setTitle(props.title);
		setIsOpen(props.isOpen);
		setIsProgressing(props.isProgressing);
		if(props.notify){
			addNotification(props.notify, "info");
		}
	},[props]);
	
	return (
		<>
			<Modal  isOpen={isOpen} >
				<ModalHeader className="bg-secondary text-white" >
					<Row>
						<Col  lg={8} md={8} sm={8} xs={8}  >
							<span className=''>{title}</span>
						</Col>
						<Col  lg={2} md={2} sm={2} xs={2} >
							<span className=''>{isProgressing? 'Please Wait ...' : ''}</span>
						</Col>
						<Col lg={2} md={2} sm={2} xs={2}  >						
							<button type="button" className="btn btn-primary btn-sm float-right ml-1 mr-1" onClick={(event)=>{
								setIsOpen(false);
								//props.callback({...{callbackType:'close'}, ...{response : [{status: true}]}});
								}
							} >X</button>{' '}
						</Col>
					</Row>
				</ModalHeader>
				<ModalBody>
					{props.children}
				</ModalBody>
			</Modal>
			<NotificationSystem ref={notificationSystem} style={NOTIFICATION_SYSTEM_STYLE}/>
		</>
	);
}
export default Dialog;