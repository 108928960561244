import React from 'react';
import PropTypes from 'utils/propTypes';

import { Media } from 'reactstrap';

import TaskDetail from "../vortex/model/TaskDetails/TaskDetail";

import Avatar from 'components/Avatar';
const getSource	=(_id)=>{
	var _src = require("assets/img/users/avetar.jpg");
	try{
		_src = require('assets/img/users/' + _id + '.jpg');
	}catch(err){}
	return _src;
}

const Notifications = (props) => {
  return (
    props.notificationsData &&
    props.notificationsData.length &&
    props.notificationsData.map((_taskDetail/*{ id, avatar, message, date }*/) => (
      <Media key={_taskDetail.ptd_id } className="pb-2" onClick={(e)=>{
		  var taskDetail =new TaskDetail();
		  taskDetail.openTaskDetailCard(_taskDetail);
		  props.onClick(e);
		  }} style={{cursor:'pointer'}} >
        <Media left className="align-self-center pr-3">
          <Avatar tag={Media} object src={getSource(_taskDetail.sender_id)} alt="Avatar" />
        </Media>
        <Media body middle className="align-self-center">
          <small>{_taskDetail.prj_id} - {_taskDetail.note.length>50?_taskDetail.note.substring(0,50).replace(',','') + '...' : _taskDetail.note.replace(',','')}</small><br />
          <small className="text-muted">{_taskDetail.start_date}</small>
        </Media>
		{/*
        <Media right className="align-self-center">
        </Media>
		*/}
      </Media>
    ))
  );
};

Notifications.propTypes = {
  notificationsData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.ID,
      avatar: PropTypes.string,
      message: PropTypes.node,
      date: PropTypes.date,
    })
  ),
};

Notifications.defaultProps = {
  notificationsData: [],
};

export default Notifications;
