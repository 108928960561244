import React, {useState, useEffect} from "react";
import {isEqual} from '../../lib/ObjectUtil';
import Collapse from 'react-bootstrap/Collapse';

import iconOpen from 'assets/img/icon/openmenu.png';
import iconClose from 'assets/img/icon/closemenu.png';
import iconDelete from 'assets/img/icon/rubbish-bin.png';
import iconEdit from 'assets/img/icon/edit.png';
import iconAdd from 'assets/img/icon/add.png';

import {init} from './GridDefaultData';

import ComboBox from "./ComboBox";

export const Grid = (props) => {	
    const [primaryKey, setPrimaryKey] = useState('');
    const [records, setRecords] = useState([]);
    const [columns, setColumns] = useState([]);
    const [recordsDetail, setRecordsDetail] = useState(null);
    const [recordsClock, setRecordsClock] = useState(null);
    const [header, setHeader] = useState([]);
    const [hasPager, setHasPager] = useState(true);
    const [pageSize, setPageSize] 		= useState(5);
    const [currentPage, setCurrentPage] = useState(1);
    const [aggregations, setAggregations] = useState([]);
    const [aggregation, setAggregation] = useState([]);
    const [aggregationIndex, setAggregationIndex] = useState(0);
    const [loading, setLoading] = useState(true);
    const [hasHeader, setHasHeader] = useState(true);
    const [canDelete, setCanDelete] = useState(false);
    const [canUpdate, setCanUpdate] 	= useState(false);
    const [isCollaps, setIsCollaps] = useState(props.collaps);
    const [isOpen, setIsOpen] = useState(new Array(100).fill(props.collaps));
    const [rowClass, setRowClass] = useState('');

    const [newRecord, setNewRecord] = useState([]);
    const [initialized, setInitialized] = useState(true);
	
	var _aggregation		=	[];
	var _aggregationIndex	=	[];

	useEffect(() => {
		// props validation
		
		if(!props.records && initialized){
			setLoading(true);
			setHasHeader(init.hasHeader);
			setHasPager(init.hasPager);
			setPageSize(init.pageSize);
			setCanDelete(init.canDelete);
			setCanUpdate(init.canUpdate);
			
			setPrimaryKey(init.primaryKey)
			setRecords(init.records);
			setHeader(getHeader(init));
			setRecordsDetail(init.detailCollections);
			setAggregations(init.aggregations);
			setColumns(init.columns);
			
			setIsCollaps(init.collaps);
			setRowClass(init.rowClass);
			setInitialized(false);
			
		}else if(props.records && !isEqual(props.records, records) && loading){
			setLoading(true);
			setHasHeader(props.hasHeader);
			setHasPager(props.hasPager);
			setPageSize(props.pageSize);
			setCanDelete(props.canDelete);
			setCanUpdate(props.canUpdate);
			
			setPrimaryKey(props.primaryKey)
			setRecords(props.records);
			setHeader(getHeader(props));
			setRecordsDetail(props.detailCollections);
			setAggregations(props.aggregations);
			setColumns(props.columns);
			
			setIsCollaps(props.collaps);
			setRowClass(props.rowClass);
		}
    }, [props, records]);

	const onDeleteHandler	= (e, _primaryKey) =>{
		props.onDeleteHandler(e, _primaryKey);
	}
	const onUpdateHandler	= (e, _primaryKey) =>{
		props.onUpdateHandler(e, _primaryKey);
	}


    const getHeader  = (props) =>{
        var _hears = [];
		if(props.columns){
			props.columns.map(c=>_hears.push(c.id));
		}
        else if(props.records[0])
            _hears= Object.keys(props.records[0]);
		
        return _hears;
    }
    const calculateAggregation=(_values)=>{
		var _a=[];
		if(loading && _values.length>0){
			_aggregationIndex.map(_index=>{
				var _value = _values.filter(v=>v[0]==_index);
				const initialValue = 0;
				const sumWithInitial = _value.reduce(
				  (accumulator, currentValue) => accumulator + currentValue[1]*1,
				  initialValue
				);
				
				if(sumWithInitial*1!=0){
					setAggregationIndex(_index -1);
					_a=[..._a];
					_a[_index -1]=sumWithInitial.toFixed(1);
				}
				
			});
			setAggregation(_a);
			setLoading(false);
		}
	}
	return(
		<>
			<div className="p-1">
				<table className={"table table-bordered table-hover " + props.className} >
				<thead className="thead-dark">
				{hasHeader?
					<tr>
						{recordsDetail?
						<th style={{width:'25px'}} ></th>:null}
						<th style={{width:'50px'}} >#</th>
						{header.map((h,hKey)=>{
							if(h.indexOf("-") == -1)
							return (
								<th  scope="col"  key={hKey}>{h}</th>
							)
						})}
						{canUpdate?
						<th style={{width:'25px'}} ></th>:null}
						{canDelete?
						<th style={{width:'25px'}} ></th>:null}
					</tr>
				:null}
					<tr>
						{recordsDetail?
						<th></th>:null}
						<th>
							<img height={16} src={iconAdd} style={{cursor:'pointer'}} onClick={(e)=>{
								var _newRecords = {};
								columns.map(c=>_newRecords[c.id]='')
								var _records	=	JSON.parse(JSON.stringify(records));
									_records.push(_newRecords);
								//setNewRecord(_newRecords);
								setRecords(_records);
								setCurrentPage(Math.ceil(_records.length/pageSize));
								/*props.onInsertHandler(e);*/
								}} />
						</th>
						{header.map((h,i)=>{
							if(h.indexOf("-") == -1)
								return (
									<th key={i} >{aggregation[i-1]}</th>
								)
						})}
						{canUpdate?
						<th></th>:null}
						{canDelete?
						<th></th>:null}
					</tr>
				</thead>
				<tbody>
				
				{records.map((record,i)=>{
					if(i>=(currentPage -1)*pageSize && i<currentPage*pageSize || !hasPager){
					return(
					<>
						<tr key={i} className={eval(rowClass) + (record[primaryKey]==''? ' table-info':'')} >
							{recordsDetail?
							<td>
								<img onClick={(e)=>{var _isOpen = isOpen.map((c,y)=>{if(y==i)return !c; else return c;}); setIsOpen(_isOpen);}} src={isOpen[i]? iconClose : iconOpen} height={16} />
							</td>
							:null}
							<td scope="row" >{i+1}</td>
							{header.map((h,j)=>{
								if(aggregations && aggregations.findIndex(a=>a==h) != -1){
									_aggregation.push([j,record[h]]);
									_aggregationIndex.push(j);
								}
								return (
									<td key={j}>
										{columns && columns[j].type=='image'?
											<img className="border border-secondary rounded m-1 p-1" height={50} src={record[h]?'https://drive.google.com/uc?export=view&id=' + record[h]: iconClose} />
											:null}
										{columns && columns[j].type=='text'?
											<input type="text" onChange={(e)=>{e.persist();var _records = records; _records[i][h] = e.target.value; setRecords(_records); setLoading(true); }} onBlur={(e)=>{props.inlineChange(e,primaryKey,records[i][primaryKey],h,e.target.value)}} value={record[h]} className="form-control" />
											:null}
										{columns && columns[j].type=='select'?
											<ComboBox id="prj_id" onChange={(e)=>{e.persist();var _records = records; _records[i][h] = e.target.value; setRecords(_records); setLoading(true); props.inlineChange(e,primaryKey,records[i][primaryKey],h,e.target.value);}}  queryString={columns[j].queryString} valueField={columns[j].valueField} displayField={columns[j].displayField} defaultValue={record[h]} />
											:null}
										{columns && columns[j].type=='checkbox'?
											<input type="checkbox" onChange={(e)=>{e.persist();var _records = records; _records[i][h] = e.target.checked; setRecords(_records); setLoading(true); props.inlineChange(e,primaryKey,records[i][primaryKey],h,e.target.checked);}}  checked={record[h]} className="form-control" />
											:null}
										{columns && columns[j].type=='date'?
											<input type="text" onChange={(e)=>{e.persist();var _records = records; _records[i][h] = e.target.value; setRecords(_records); setLoading(true); }} onBlur={(e)=>{props.inlineChange(e,primaryKey,records[i][primaryKey],h,e.target.value)}} value={record[h]} className="form-control" />
											:null}
										{columns && columns[j].type=='time'?
											<input type="text" onChange={(e)=>{e.persist();var _records = records; _records[i][h] = e.target.value; setRecords(_records); setLoading(true); }} onBlur={(e)=>{props.inlineChange(e,primaryKey,records[i][primaryKey],h,e.target.value)}} value={record[h]} className="form-control" />
											:null}
										{columns && columns[j].type=='datetime'?
											<input type="text" onChange={(e)=>{e.persist();var _records = records; _records[i][h] = e.target.value; setRecords(_records); setLoading(true); }} onBlur={(e)=>{props.inlineChange(e,primaryKey,records[i][primaryKey],h,e.target.value)}} value={record[h]} className="form-control" />
											:null}
										{!columns || !columns[j].type?
											record[h]
											:null}
									</td>
								)
							})}
							{canUpdate?
							<th scope="row"><img height={16} src={iconEdit} style={{cursor:'pointer'}} onClick={(e)=>{onUpdateHandler(e, record[primaryKey])}} /></th>:null}
							{canDelete?
							<th scope="row"><img height={16} src={iconDelete} style={{cursor:'pointer'}} onClick={(e)=>{onDeleteHandler(e, record[primaryKey])}}/></th>:null}
						</tr>
						{recordsDetail?
					  <Collapse in={isOpen[i]}>
						<tr>
							<td colSpan={header.length+4} className="p-2" >
								{recordsDetail.map((detail, gridKey)=>{
									return(
										<Grid key={gridKey} className="table-sm" records={detail.details.filter(rd=>rd[primaryKey]==record[primaryKey])} hasHeader={true} collaps={false} />								
									)
								})}
							</td>
						</tr>
					  </Collapse>
						:null}
					 </>
					)	
					}
					})
				}
				
				</tbody>
				{hasPager?
					<tfoot className="thead-dark" >
						<tr>
							<th colSpan={header.length+4} >
								<input type="button" value="<" onClick={(e)=>{setCurrentPage(currentPage-1)}} disabled={currentPage==1? true: false} />
								<input type="text" size="1" value={currentPage} onChange={(e)=>setCurrentPage(e.target.value)} />
								<input type="button" value=">" onClick={(e)=>{setCurrentPage(currentPage+1)}} disabled={currentPage==Math.ceil(records.length/pageSize)? true: false} />
								<span className="ml-2" > / {Math.ceil(records.length/pageSize)}</span>
							</th>
						</tr>
					</tfoot>
				:null}
				</table>
		{ calculateAggregation(_aggregation)}
			</div>
		</>
	)


}
