import React,{useState, UseEffect, Suspense} from "react";
import ReactDOM from 'react-dom';
import DataAdapter from "../../adapter/DataAdapter";
import Store from "../../lib/StateUtil";
import Dialog from "../../component/Dialog";

const elementRef = React.createRef() // create the ref

class TaskDetail extends React.Component {

    constructor(props){
		super(props);
		this.state = {isOpen:true};
    }
	calling = (partialState) =>{
		//Object.assign(this.state, partialState);
		this.setState({isOpen:false});
		console.log(partialState)
	}
    selectByTaskId = async (_id, _type) =>{
        //const _query    =   "select ptd.ptd_id, ptd.pt_id, ptd.prj_id, ptd.task, ptd.task_description, ptd.task_status, ty.rb_type_desc as task_type, ptd.task_type as task_type_id, ptd.sender_id, p.name AS sender, ptd.receiver_id, pr.name as receiver, ptd.Clock_in, ptd.Clock_out, DATE_FORMAT(CONVERT_TZ(ptd.start_date,@@session.time_zone,'-04:00'),'%d %M %H:%i') AS start_date, note, ptd.attachments from rb_project_task_details ptd inner join rb_types ty on (ty.rb_type_no= ptd.task_type and ty.rb_type = 'task_type') inner join rb_person p on p.person_id = ptd.sender_id inner join rb_person pr on pr.person_id = ptd.receiver_id where pt_id = " + _id + (_type? " AND ptd.task_type = " + _type : "") + "  ORDER BY ptd_id DESC;";
        const _query    =   "select ptd.ptd_id, ptd.pt_id, ptd.prj_id, ptd.task, ptd.task_description, ptd.task_status, ty.rb_type_desc as task_type, ptd.task_type as task_type_id, ptd.sender_id, p.name AS sender, ptd.receiver_id, pr.name as receiver, ptd.Clock_in, ptd.Clock_out, DATE_FORMAT(CONVERT_TZ(ptd.start_date,@@session.time_zone,'-04:00'),'%d %M %H:%i') AS start_date, note, ptd.attachments from rb_project_task_details ptd inner join rb_types ty on (ty.rb_type_no= ptd.task_type and ty.rb_type = 'task_type') inner join rb_person p on p.person_id = ptd.sender_id inner join rb_person pr on pr.person_id = ptd.receiver_id where pt_id = " + _id + (_type? " AND ptd.task_type = " + _type : "") + "  ORDER BY ptd_id ;";
		console.log(_query);
        var dataAdapter =new DataAdapter(_query);
        var data = await dataAdapter.getRecords();
        return data;
    }
    selectByTaskParent = async (_id, _type) =>{
        const _query    =   "select ptd.ptd_id, ptd.pt_id, ptd.prj_id, ptd.task, ptd.task_description, ptd.task_status, ty.rb_type_desc as task_type, ptd.task_type as task_type_id, ptd.sender_id, p.name AS sender, ptd.receiver_id, pr.name as receiver, ptd.Clock_in, ptd.Clock_out, ptd.start_date, note, ptd.attachments from rb_project_task_details ptd inner join rb_types ty on (ty.rb_type_no= ptd.task_type and ty.rb_type = 'task_type') inner join rb_person p on p.person_id = ptd.sender_id inner join rb_person pr on pr.person_id = ptd.receiver_id where parent = " + _id + (_type? " AND ptd.task_type = " + _type : "") + "  ORDER BY ptd_id DESC;";
        var dataAdapter =new DataAdapter(_query);
        var data = await dataAdapter.getRecords();
        return data;
    }
    selectByProject = async (_id, _type, _person) =>{
        const _query    =   "select ptd.ptd_id as `Id`, ptd.pt_id, ptd.prj_id as `Project#-`, ptd.task as `Task-`, ptd.task_description as `task_description-`, ptd.task_status as `Status`, ty.rb_type_desc as `task_type-`, ptd.task_type as `task_type_id`, ptd.sender_id as `sender_id-`, p.name AS `sender`, ptd.receiver_id as `receiver_id-`, pr.name as `Receiver-`, DATE_FORMAT(CONVERT_TZ(ptd.Clock_in,@@session.time_zone,'-04:00'),'%d %M %H:%i') as `Clock In`, DATE_FORMAT(CONVERT_TZ(ptd.Clock_out,@@session.time_zone,'-04:00'),'%d %M %H:%i') as `Clock Out`, DATE_FORMAT(CONVERT_TZ(ptd.start_date,@@session.time_zone,'-04:00'),'%d %M %H:%i') as `Date-`, ptd.note as `Note-`, ptd.attachments as `image` from rb_project_task_details ptd inner join rb_types ty on (ty.rb_type_no= ptd.task_type and ty.rb_type = 'task_type') left join rb_person p on p.person_id = ptd.sender_id left join rb_person pr on pr.person_id = ptd.receiver_id where ptd.prj_id = " + _id + (_type? " AND ptd.task_type = " + _type : "") + (_person? " AND ptd.sender_id = " + _person : "") + "  ORDER BY ptd_id DESC;";
        var dataAdapter =new DataAdapter(_query);
        var data = await dataAdapter.getRecords();
        return data;
    }
    selectPersonOfTask = async (_id, _type) =>{
        const _query    =   "select ptd.ptd_id as `ptd_id`, ptd.pt_id, ptd.prj_id as `Project#-`, ptd.task as `Task-`, ptd.task_description as `task_description-`, ptd.task_status as `Status`, ty.rb_type_desc as `task_type-`, ptd.task_type as `task_type_id-`, ptd.sender_id as `sender_id-`, p.name AS `sender-`, ptd.receiver_id as `receiver_id-`, pr.name as `Receiver`, ptd.Clock_in as `Clock In`, ptd.Clock_out as `Clock Out`, DATE_FORMAT(CONVERT_TZ(ptd.start_date,@@session.time_zone,'-04:00'),'%d %M %H:%i') as `Date`, ptd.note as `Note-`, ptd.attachments as `attachments-` from rb_project_task_details ptd inner join rb_types ty on (ty.rb_type_no= ptd.task_type and ty.rb_type = 'task_type') left join rb_person p on p.person_id = ptd.sender_id left join rb_person pr on pr.person_id = ptd.receiver_id where ptd.pt_id = " + _id + (_type? " AND ptd.task_type = " + _type : "") + "  ORDER BY ptd_id DESC;";
		console.log(_query);
        var dataAdapter =new DataAdapter(_query);
        var data = await dataAdapter.getRecords();
        return data;
    }
    selectPersonOfTaskByProject = async (_id, _type, _person) =>{
        const _query    =   "select ptd.ptd_id as `ptd_id`, ptd.pt_id, ptd.prj_id as `Project#-`, ptd.task as `Task-`, ptd.task_description as `task_description-`, ptd.task_status as `Status`, ty.rb_type_desc as `task_type-`, ptd.task_type as `task_type_id-`, ptd.sender_id as `sender_id-`, p.name AS `sender-`, ptd.receiver_id as `receiver_id-`, pr.name as `Receiver`, ptd.Clock_in as `Clock In`, ptd.Clock_out as `Clock Out`, DATE_FORMAT(CONVERT_TZ(ptd.start_date,@@session.time_zone,'-04:00'),'%d %M %H:%i') as `Date`, ptd.note as `Note-`, ptd.attachments as `attachments-` from rb_project_task_details ptd inner join rb_types ty on (ty.rb_type_no= ptd.task_type and ty.rb_type = 'task_type') left join rb_person p on p.person_id = ptd.sender_id left join rb_person pr on pr.person_id = ptd.receiver_id where ptd.prj_id = " + _id + (_type? " AND ptd.task_type = " + _type : "") + (_person? " AND ptd.receiver_id = " + _person : "") + "  ORDER BY ptd_id DESC;";
        var dataAdapter =new DataAdapter(_query);
        var data = await dataAdapter.getRecords();
        return data;
    }
	
    insert = async (_taskDetail) =>{
        const _query    =   "INSERT INTO rb_project_task_details (pt_id,parent, prj_id, task_type, task_status, sender_id, receiver_id, note, task_description, start_date, end_date, attachments, Clock_in, Clock_out, latitude, longitude, expected_start_date, expected_end_date) VALUES (" + _taskDetail.pt_id + "," + _taskDetail.parent + "," + _taskDetail.prj_id + "," + _taskDetail.task_type + "," + _taskDetail.task_status + "," + _taskDetail.sender_id + "," + _taskDetail.receiver_id + ",'" + _taskDetail.note + "','" + _taskDetail.task_description + "','" + _taskDetail.start_date + "','" + _taskDetail.end_date + "','" + _taskDetail.attachments + "','" + _taskDetail.Clock_in + "','" + _taskDetail.Clock_out + "','" + _taskDetail.latitude + "','" + _taskDetail.longitude + "','" + _taskDetail.expected_start_date + "','" + _taskDetail.expected_end_date + "');\r\nSELECT * FROM rb_project_task_details WHERE pt_id=" + _taskDetail.pt_id + " ORDER BY ptd_id DESC LIMIT 1";
        var dataAdapter =new DataAdapter(_query);
        var data = await dataAdapter.getRecords();
        return data;
    }
    deleteById = async (_id) =>{
        const _query    =   "DELETE from rb_project_task_details where ptd_id = " + _id ;
        var dataAdapter =new DataAdapter(_query);
        var data = await dataAdapter.getRecords();
        return data;
    }

    changeStatus = async (_id, _status) =>{
        const _query    =   "UPDATE rb_project_task_details SET task_status=" + _status + ", end_date=now() where ptd_id = " + _id + ";";
        var dataAdapter =new DataAdapter(_query);
        var data = await dataAdapter.getRecords();
        return data;
    }

	ViewTaskDetail		=	async (_task)	=>{
		const _return = await new Promise((resolve, rej) => {
			const div	=	document.createElement('div');
			document.body.appendChild(div);
			const ViewProjectTaskDetails = React.lazy(() => import('./ViewProjectTaskDetails'));
			ReactDOM.render(
				<Suspense fallback={<div>Loading... </div>}>
					<ViewProjectTaskDetails project={_task.pt_id} projectId={_task.prj_id} taskId={_task.pt_id} callback={(obj)=>resolve(obj)} />
				</Suspense>
			, div);		
		});
	
		return _return;
	}

	openProjectLogs		=	async (_task, _type)	=>{
		const _return = await new Promise((resolve, rej) => {
			const div	=	document.createElement('div');
			document.body.appendChild(div);
			const ViewProjectLog = React.lazy(() => import('./ViewProjectLog'));
			ReactDOM.render(
				<Suspense fallback={<div>Loading... </div>}>
					<Dialog title="Project Logs" isOpen={true} isProgressing={false} notify="" >
						<ViewProjectLog projectId={_task.prj_id} taskId={_task.pt_id} type={_type} callback={(obj)=>resolve(obj)} />
					</Dialog>
				</Suspense>
			, div);		
		});
	
		return _return;
	}

	openTaskDetailCard		=	async(_taskDetail)	=>{
		//console.log(_taskDetail);
		const _return = await new Promise((resolve, rej) => {
			const div	=	document.createElement('div');
			document.body.appendChild(div);
			const TaskDetailCard = React.lazy(() => import('./TaskDetailCard'));
			ReactDOM.render(
				<Suspense fallback={<div>Loading... </div>}>
					<Dialog title="Log View" isOpen={true} isProgressing={false} notify="" >
						<TaskDetailCard taskDetail={_taskDetail} callback={(obj)=>resolve(obj)} />
					</Dialog>
				</Suspense>
			, div);		
			
		});
	
		return _return;
	}

	openFormSubTask		=	async (_projectId, _taskId)	=>{
		var callback = new TaskDetail({isOpen : true});
		
		const _return = await new Promise((resolve, rej) => {
			const div	=	document.createElement('div');
			document.body.appendChild(div);
			const FormSubTask = React.lazy(() => import('../Tasks/FormSubTask'));
			ReactDOM.render(
				<Suspense fallback={<div>Loading... </div>}>
					<Dialog ref={elementRef} title={"Assign Task " + _taskId + " Project " + _projectId}  isOpen={this.state.isOpen} isProgressing={false} notify="TEST" callback={callback.calling.bind(callback)} >
						<FormSubTask isOpen={true} projectId={_projectId} taskId={_taskId} callback={(obj)=>{if(obj.action=='close'){resolve(obj);}}} />
							{/*
						<FormSubTask parent={elementRef} isOpen={this.state.isOpen} projectId={_projectId} taskId={_taskId} callback={callback.calling.bind(callback)} />
							*/}
					</Dialog>
				</Suspense>
			, div);		
		});
	
		return _return;
	}

	openClockForm		=	async (_project)	=>{
		var callback = new TaskDetail({isOpen : true});
		
		const _return = await new Promise((resolve, rej) => {
			const div	=	document.createElement('div');
			document.body.appendChild(div);
			const FormClock = React.lazy(() => import('./FormClock'));
			ReactDOM.render(
				<Suspense fallback={<div>Loading... </div>}>
						<FormClock project={_project} isOpen={true} callback={(obj)=>{if(obj.action=='close'){resolve(obj);}}} />
				</Suspense>
			, div);		
		});
	
		return _return;
	}

	openClockInOut		=	async (_primaryKey)	=>{
		var callback = new TaskDetail({isOpen : true});
		
		const _return = await new Promise((resolve, rej) => {
			const div	=	document.createElement('div');
			document.body.appendChild(div);
			const FormClockInOut = React.lazy(() => import('./FormClockInOut'));
			ReactDOM.render(
				<Suspense fallback={<div>Loading... </div>}>
						<FormClockInOut isOpen={true} callback={(obj)=>{resolve(obj);}} primaryKey={_primaryKey} projectId={_primaryKey} />
				</Suspense>
			, div);		
		});
	
		return _return;
	}

	openDailyTimeCard		=	async()	=>{
		const _return = await new Promise((resolve, rej) => {
			const div	=	document.createElement('div');
			document.body.appendChild(div);
			const DailyTimeCard = React.lazy(() => import('../Persons/DailyTimeCard'));
			ReactDOM.render(
				<Suspense fallback={<div>Loading... </div>}>
					<Dialog title="Timesheet" isOpen={true} isProgressing={false} notify="" >
						<DailyTimeCard callback={(obj)=>resolve(obj)} />
					</Dialog>
				</Suspense>
			, div);		
			
		});
	
		return _return;
	}
	viewDailyTimeSheet		=	async()	=>{
		const _return = await new Promise((resolve, rej) => {
			const div	=	document.createElement('div');
			document.body.appendChild(div);
			const DailyTimeSheet = React.lazy(() => import('../Persons/DailyTimeSheet'));
			//const DailyTimeSheet = React.lazy(() => import('../../DailyTimeSheet'));
			ReactDOM.render(
				<Suspense fallback={<div>Loading... </div>}>
					<Dialog title="Timesheet" isOpen={true} isProgressing={false} notify="" >
						<DailyTimeSheet callback={(obj)=>resolve(obj)} />
					</Dialog>
				</Suspense>
			, div);		
			
		});
	
		return _return;
	}

	openProjectTimeSheet		=	async(_project)	=>{
		const _return = await new Promise((resolve, rej) => {
			const div	=	document.createElement('div');
			document.body.appendChild(div);
			const ProjectTimeSheet = React.lazy(() => import('../Projects/ProjectTimeSheet'));
			ReactDOM.render(
				<Suspense fallback={<div>Loading... </div>}>
					<Dialog title="Timesheet" isOpen={true} isProgressing={false} notify="TEST" >
						<ProjectTimeSheet projectId={_project.prj_id} callback={(obj)=>resolve(obj)} />
					</Dialog>
				</Suspense>
			, div);		
			
		});
	
		return _return;
	}

	/**
		Views
	*/
	viewProjectLog		=	async(_type, _projectId)	=>{
		const _return = await new Promise((resolve, rej) => {
			const div	=	document.createElement('div');
			document.body.appendChild(div);
			const ViewProjectLog = React.lazy(() => import('./ViewProjectLog'));
			ReactDOM.render(
				<Suspense fallback={<div>Loading... </div>}>
					<Dialog title={"Project Logs"} isOpen={true} isProgressing={false} notify={''} >
						<ViewProjectLog type={_type} projectId={_projectId} callback={(obj)=>resolve(obj)} />
					</Dialog>
				</Suspense>
			, div);		
			
		});
	
		return _return;
	}
	viewProjectNotes		=	async()	=>{
		const _return = await new Promise((resolve, rej) => {
			const div	=	document.createElement('div');
			document.body.appendChild(div);
			const ViewProjectNotes = React.lazy(() => import('./ViewProjectNotes'));
			ReactDOM.render(
				<Suspense fallback={<div>Loading... </div>}>
					<Dialog title="Project Notes" isOpen={true} isProgressing={false} notify={''} >
						<ViewProjectNotes callback={(obj)=>resolve(obj)} />
					</Dialog>
				</Suspense>
			, div);		
			
		});
	
		return _return;
	}
	
	viewProjectLogs		=	async(projectId)	=>{
		const _return = await new Promise((resolve, rej) => {
			const div	=	document.createElement('div');
			document.body.appendChild(div);
			const ViewProjectLogs = React.lazy(() => import('./ViewProjectLogs'));
			ReactDOM.render(
				<Suspense fallback={<div>Loading... </div>}>
					<Dialog title="Project Logs" isOpen={true} isProgressing={false} notify={''} >
						<ViewProjectLogs projectId={projectId} callback={(obj)=>resolve(obj)} />
					</Dialog>
				</Suspense>
			, div);		
			
		});
	
		return _return;
	}

}
export default TaskDetail;