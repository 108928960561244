import iconClockIn from 'assets/img/icon/clockin.png';
import iconClockOut from 'assets/img/icon/clockout.png';

import Avatar from 'components/Avatar';
import { UserCard } from 'components/Card';
import Notifications from 'components/Notifications';
import SearchInput from 'components/SearchInput';
//import { notificationsData } from 'demos/header';
import withBadge from 'hocs/withBadge';
import React from 'react';
import {
  MdClearAll,
  MdExitToApp,
  MdHelp,
  MdInsertChart,
  MdMessage,
  MdNotificationsActive,
  MdNotificationsNone,
  MdPersonPin,
  MdSettingsApplications,
} from 'react-icons/md';
import {
  Button,
  ListGroup,
  ListGroupItem,
  // NavbarToggler,
  Nav,
  Navbar,
  NavItem,
  NavLink,
  Popover,
  PopoverBody,
  Card,
  CardBody,
  CardHeader,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledButtonDropdown,
} from 'reactstrap';

import bn from 'utils/bemnames';

import { Cookies } from 'react-cookie';
import DataAdapter from '../../vortex/adapter/DataAdapter';
import Project		from "../../vortex/model/Projects/Project";
import TaskDetail		from "../../vortex/model/TaskDetails/TaskDetail";
import Task		from "../../vortex/model/Tasks/Task";
import Person		from "../../vortex/model/Persons/Person";
import Invoice from '../../vortex/model/Invoices/Invoice';
import ReceiveAndPayment from '../../vortex/model/Accounting/ReceiveAndPayment';
import Accounting from '../../vortex/model/Accounting/Accounting';

const bem = bn.create('header');

const MdNotificationsActiveWithBadge = withBadge({
  size: 'sm',
  color: 'primary',
  style: {
    top: -10,
    right: -10,
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  children: <small></small>,
})(MdNotificationsActive);

const cookie =new Cookies();
const getSource	=(_id)=>{
	var _src = require("assets/img/users/avetar.jpg");
	try{
		_src = require('assets/img/users/' + _id + '.jpg');
	}catch(err){}
	return _src;
}

class Header extends React.Component {
  constructor(props){
    super(props);
    (new DataAdapter("SELECT * FROM rb_person WHERE person_type=8 AND user_expire_date > now();"))
      .getRecords()
      .then(data=>{
        this.setState({persons : data});
      });

    (new DataAdapter("SELECT rb_type_no as id, rb_type_desc as role FROM rb_types where rb_type = 'person_role' and find_in_set(rb_type_no,(select person_role from rb_person where person_id=" +cookie.get('v-person-id') + "))>0 ORDER BY rb_type_no;"))
      .getRecords()
      .then(data=>{
        this.setState({roles : data});
      });
	  
    (new DataAdapter("SELECT pt.task,ptd.ptd_id, ty.rb_type_desc AS `task_type`, ty.rb_type_no AS `task_type_id`, ptd.note, ptd.task_description, ptd.start_date, prr.name AS sender, ptd.sender_id, pr.name AS receiver, ptd.receiver_id, ptd.attachments, prj.prj_id, prj.prj_title, prj.prj_address, prj.prj_posted_date FROM rb_project_task_details ptd LEFT JOIN rb_project_tasks pt ON pt.pt_id=ptd.pt_id LEFT JOIN rb_projects prj ON prj.prj_id =ptd.prj_id LEFT JOIN rb_types ty ON (ty.rb_type = 'task_type' AND ty.rb_type_no = ptd.task_type ) LEFT JOIN rb_person pr ON pr.person_id = ptd.receiver_id LEFT JOIN rb_person prr ON prr.person_id = ptd.sender_id WHERE ty.rb_type_type_id=20 AND ptd.receiver_id=" +cookie.get('v-person-id') + " AND ptd.task_status=8 ORDER BY ptd.ptd_id DESC;"))
      .getRecords()
      .then(data=>{
        this.setState({notificationsData : data});
      });
	  
  }
  state = {
    isOpenNotificationPopover: false,
    isNotificationConfirmed: false,
    isOpenUserCardPopover: false,
    persons:[{}],
    roles:[{}],
	notificationsData : {},
  };

  toggleNotificationPopover = () => {
    this.setState({
      isOpenNotificationPopover: !this.state.isOpenNotificationPopover,
    });

    if (!this.state.isNotificationConfirmed) {
      this.setState({ isNotificationConfirmed: true });
    }
  };

  toggleUserCardPopover = () => {
    this.setState({
      isOpenUserCardPopover: !this.state.isOpenUserCardPopover,
    });
  };

  handleSidebarControlButton = event => {
    event.preventDefault();
    event.stopPropagation();

    document.querySelector('.cr-sidebar').classList.toggle('cr-sidebar--open');
  };

  onSignoutHandler = event =>{
    event.preventDefault();

    cookie.remove('v-person-id');
    cookie.remove('v-email');
    cookie.remove('v-tel');
    cookie.remove('v-name');
    cookie.remove('v-lastname');
    cookie.remove('v-person-title');
    cookie.remove('v-person-type');
    cookie.remove('v-person-role');
    cookie.remove('v-person-role-name');
    cookie.remove('v-person-desc');
    cookie.remove('v-email-organization');
    cookie.remove('v-tel-organization');
    cookie.remove('v-name-signature');
    cookie.remove('v-lastname-signature');
    cookie.remove('v-title-signature');
    cookie.remove('v-tel-signature');
    cookie.remove('v-email-signature');
    cookie.remove('v-link-signature');
    cookie.remove('v-ext-signature');
    cookie.remove('v-cell-signature');
    cookie.remove('v-person-token');
    	
    window.location = '/#/login';
    window.location.reload();
  }

  onRoleChangeHandler = e =>{
    cookie.remove('v-person-role');
    cookie.set('v-person-role', e.target.value)
    window.location.reload();
  }
  onPersonChangeHandler = e =>{
    e.persist();
    console.log(e)
    cookie.remove('v-person-id');
    cookie.remove('v-name');
    cookie.remove('v-lastname');
    cookie.set('v-person-id', e.target.value)
    cookie.set('v-name', e.target.selectedOptions[0].attributes[0].nodeValue)
    cookie.set('v-lastname', e.target.selectedOptions[0].attributes[1].nodeValue)
    window.location.reload();
  }
	callback					=   (obj)	=>{
		console.log(obj);
	}

  render() {
    const { isNotificationConfirmed } = this.state;

    return (
      <Navbar light expand className={bem.b('bg-white')}>
        <Nav navbar className="mr-2">
          <Button outline onClick={this.handleSidebarControlButton}>
            <MdClearAll size={25} />
          </Button>
        </Nav>
        <Nav navbar className="mr-2">
		{/*
            <SearchInput />
		*/}
        </Nav>
		
        <Nav navbar className="mr-2">
		{/* Module : new_items_button */}
			{cookie.get('v-person-role')*1 == 1 || cookie.get('v-person-role')*1 == 10 || cookie.get('v-person-role')*1 == 11 || cookie.get('v-person-role')*1 == 14?
			<>
              <UncontrolledButtonDropdown className="m-1">
                <DropdownToggle caret >
                  New
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={(e)=>{	var _person	=new Person();_person.openFormPerson(-1); }} >Person</DropdownItem>
                  <DropdownItem disabled onClick={(e)=>{}} >Project</DropdownItem>
                  <DropdownItem onClick={(e)=>{	var _task	=new Task();_task.openQuickTaskForm(-1); }} >Task</DropdownItem>
                  <DropdownItem disabled >Note</DropdownItem>
                  <DropdownItem onClick={(e)=>{	var _receiveAndPayment	=new ReceiveAndPayment(); _receiveAndPayment.openReceiveAndPayment(); }} >Payment</DropdownItem>
                  <DropdownItem disabled >Email/SMS</DropdownItem>
                  <DropdownItem disabled >Alarm</DropdownItem>
                  <DropdownItem disabled >Picture</DropdownItem>
                  <DropdownItem onClick={(e)=>{	var _project	=new Project();_project.provideEstimate(); }} >Estimate</DropdownItem>
                  <DropdownItem onClick={(e)=>{	var _project	=new Project();_project.provideQuote(); }} >Quote</DropdownItem>
                  <DropdownItem onClick={(e)=>{	var _project	=new Project();_project.provideInvoice(); }} >Invoice</DropdownItem>
                  <DropdownItem onClick={(e)=>{	var _invoice	=new Invoice();_invoice.openLog(1,-1); }} >Project Log</DropdownItem>
                  <DropdownItem onClick={(e)=>{	var _accounting	=new Accounting();_accounting.openPurchase(); }} >Purchase</DropdownItem>
                  <DropdownItem onClick={(e)=>{	
	  						var taskDetail =new TaskDetail();
							taskDetail.openClockInOut();

				  }} >Clock In/Out</DropdownItem>
                  <DropdownItem onClick={(e)=>{	var _project	=new Project();_project.provideReport(); }} >Project Report</DropdownItem>
                </DropdownMenu>
              </UncontrolledButtonDropdown>
              <UncontrolledButtonDropdown className="m-1">
                <DropdownToggle caret >
                  View
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={(e)=>{	var _person	=new Person(); _person.openViewPersons(); }} >Person</DropdownItem>
                  <DropdownItem onClick={(e)=>{	var _project	=new Project(); _project.viewProjectPlan(); }} >Project Plan</DropdownItem>
                  <DropdownItem onClick={(e)=>{	var _taskDetail	=new TaskDetail(); _taskDetail.openProjectLogs({}, 93); }} >Project Log</DropdownItem>
                  <DropdownItem onClick={(e)=>{	var _taskDetail	=new TaskDetail(); _taskDetail.openProjectLogs({}, 122); }} >Project Clock</DropdownItem>
                  <DropdownItem onClick={(e)=>{	var _taskDetail	=new TaskDetail(); _taskDetail.openProjectLogs({}, 83); }} >Purchase</DropdownItem>
                  <DropdownItem onClick={(e)=>{	var _taskDetail	=new TaskDetail(); _taskDetail.openProjectLogs({}, 37); }} >Notes</DropdownItem>
                  <DropdownItem onClick={(e)=>{	var _taskDetail	=new TaskDetail(); _taskDetail.viewDailyTimeSheet(); }} >Timesheet</DropdownItem>
                  <DropdownItem onClick={(e)=>{	var _taskDetail	=new TaskDetail(); _taskDetail.viewProjectLogs(-1); }} >Daily Report</DropdownItem>
                </DropdownMenu>
              </UncontrolledButtonDropdown>
			  </>
			:''}
			  
        </Nav>
        
        <Nav navbar className={bem.e('nav-right')}>
          <NavItem className="d-inline-flex">
            <NavLink id="Popover1" className="position-relative"  >
              {isNotificationConfirmed ? (
                <MdNotificationsNone
                  size={25}
                  className="text-secondary can-click"
                  onClick={this.toggleNotificationPopover}
                />
              ) : (
                <MdNotificationsActiveWithBadge
                  size={25}
                  className="text-secondary can-click animated swing infinite"
                  onClick={this.toggleNotificationPopover}
                />
              )}
            </NavLink>
            <Popover
              placement="bottom"
              isOpen={this.state.isOpenNotificationPopover}
              toggle={this.toggleNotificationPopover}
              target="Popover1"
            >
              <PopoverBody>
                <Notifications notificationsData={this.state.notificationsData} onClick={(e)=>{this.setState({isOpenNotificationPopover:false})}} />
              </PopoverBody>
            </Popover>
          </NavItem>
		  
          <NavItem>
            <NavLink id="Popover2">
				<Avatar className="can-click" src={getSource(cookie.get('v-person-id'))} title={cookie.get('v-name')} />
					{/*
				  <Avatar
					onClick={this.toggleUserCardPopover}
					className="can-click"
				  />
				  */}
            </NavLink>
            <Popover
              placement="bottom-end"
              isOpen={this.state.isOpenUserCardPopover}
              toggle={this.toggleUserCardPopover}
              target="Popover2"
              className="p-0 border-0"
              style={{ minWidth: 250 }}
            >
              <PopoverBody className="p-0 border-light">
                <UserCard
                  title={cookie.get('v-name') + ' ' + cookie.get('v-lastname')}
                  subtitle={cookie.get('v-email')}				  
                  text={
					  cookie.get('v-name-signature') + ' ' + cookie.get('v-lastname-signature') + '<br/>' + 
					  cookie.get('v-title-signature') + '<br/>' + 
					  'RENO BUILDING CANADA, INC.' + '<br/>' + 
					  'TELL  | ' + cookie.get('v-tel-signature') + '<br/>' + 
					  cookie.get('v-email-signature')
					  }
                  className="border-light"
                >
                  <ListGroup flush>
				  {/*
                    <ListGroupItem tag="button" action className="border-light">
                      <MdPersonPin /> Profile
                    </ListGroupItem>
                    <ListGroupItem tag="button" action className="border-light">
                      <MdInsertChart /> Stats
                    </ListGroupItem>
                    <ListGroupItem tag="button" action className="border-light">
                      <MdMessage /> Messages
                    </ListGroupItem>
                    <ListGroupItem tag="button" action className="border-light">
                      <MdSettingsApplications /> Settings
                    </ListGroupItem>
                    <ListGroupItem tag="button" action className="border-light">
                      <MdHelp /> Help
                    </ListGroupItem>
				  */}
                    {cookie.get('v-person-id')*1==66?
                    <>
                    <ListGroupItem tag="button" action className="border-light">
                      <label>Person : </label><br/>
                      <select style={{width:'100%'}} defaultValue={cookie.get('v-person-id')} onChange={(e)=>{this.onPersonChangeHandler(e)}} >
                        <option value="">-</option>
                        {this.state.persons?this.state.persons.map(p=>{
                          return(
                            <option data-name={p.name} data-lastname={p.lastname} value={p.person_id}>{p.name + ' ' + p.lastname}</option>
                          )
                        })
                        :''}
                      </select>
                    </ListGroupItem>
                    <ListGroupItem tag="button" action className="border-light">
                      <label>Role : </label><br/>
                      <select style={{width:'100%'}} defaultValue={cookie.get('v-person-role')} onChange={(e)=>{this.onRoleChangeHandler(e)}} >
                        <option value="">-</option>
                        {this.state.roles?this.state.roles.map(r=>{
                          return(
                            <option value={r.id}>{r.role}</option>
                          )
                        })
                        :''}
                      </select>
                    </ListGroupItem>
                    </>                    
                    :''}
                    <ListGroupItem onClick={this.onSignoutHandler} tag="button" action className="border-light">
                      <MdExitToApp  /> Signout
                    </ListGroupItem>
                  </ListGroup>
                </UserCard>
              </PopoverBody>
            </Popover>
          </NavItem>
        </Nav>
      </Navbar>
    );
  }
}

export default Header;
