import React from 'react';

import 'jqwidgets-scripts/jqwidgets/styles/jqx.base.css';
import 'jqwidgets-scripts/jqwidgets/styles/jqx.dark.css';
import JqxChart, { IChartProps, jqx } from 'jqwidgets-scripts/jqwidgets-react-tsx/jqxchart';


class VtxChart extends React.Component {
	constructor(props){
		super(props);
		
		const data = [
            { Category: 'Income', received: this.props.received, balance: this.props.balance},
            { Category: 'subTotal', subTotal: this.props.subTotal},
            { Category: 'Expense', cost : this.props.cost, extra : this.props.extra, creditLimit : this.props.creditLimit, markup : this.props.markup, HSTDue : this.props.HSTDue, HSTofBalance : this.props.HSTofBalance },
        ];
		this.state = {
			description: '',
			padding: { bottom: 1, left: 2, right: 2, top: 2 },
			seriesGroups: [
                {
					orientation: 'horizontal',
					useGradient: false,       
                    columnsGapPercent: 0,					
					formatSettings : {thousandsSeparator: ',', decimalPlaces : 0},
					series: [
                        { dataField: 'subTotal', displayText: 'subTotal', labels : {visible : true}, color: '#F9F9F3' },
                        { dataField: 'received', displayText: 'received', labels : {visible : true}, color: '#FFD93D'  },
                        { dataField: 'balance', displayText: 'balance', labels : {visible : true}, color: '#4D96FF'  },
                        { dataField: 'cost', displayText: 'cost', labels : {visible : true}, color: '#FF6B6B' },
                        { dataField: 'extra', displayText: 'extra', labels : {visible : false}, color: '#000' },
                        { dataField: 'creditLimit', displayText: 'creditLimit', labels : {visible : true}, color: '#6BCB77' },
                        { dataField: 'markup', displayText: 'markup', labels : {visible : true}, color: '#CBC3E3' },
                        { dataField: 'HST', displayText: 'HST', labels : {visible : true}, color: '#808080' },
                        { dataField: 'HSTDue', displayText: 'HSTDue', labels : {visible : true}, color: '#D3D3D3' },
                        { dataField: 'HSTofBalance', displayText: 'HSTofBalance', labels : {visible : true}, color: '#A9A9A9' },
					],
                    seriesGapPercent: 1,
                    type: 'stackedcolumn100'
                }
            ],
			source: data,
			title: '',
			titlePadding: { left: 90, top: 0, right: 0, bottom: 10 },
			valueAxis: {
				visible : false,
				flip: true,
                labels: {
                    formatSettings: { sufix: '' },
                    horizontalAlignment: 'right',
                    visible: false
                },
                gridLines: {
                    color: '#BCBCBC',
                    interval: 1,
                    visible: false
                },
                tickMarks: { color: '#BCBCBC' },
                title: { text: '' },
                unitInterval: 1000,
				maxValue : 150000
            },
            xAxis: {            
				visible : false,
                labels: {
                    visible: false
                },
                axisSize: 'auto',
                dataField: 'Category',
                gridLines: {
                    color: '#BCBCBC',
                    interval: 1,
                    visible: false
                },
                tickMarks: {
                    color: '#BCBCBC',
                    interval: 1,
                    visible: false
                }
            }
		};
		
	}

	componentWillReceiveProps(nextProps) {
		const data = [
            { Category: 'Income', received: nextProps.received, balance: nextProps.balance},
            { Category: 'subTotal', subTotal: nextProps.subTotal, HST : nextProps.HST},
            { Category: 'Expense', cost : Math.abs(nextProps.cost), creditLimit : nextProps.creditLimit, markup : nextProps.markup, HSTDue: nextProps.HSTDue, HSTofBalance : nextProps.HST*1-nextProps.HSTDue*1 },
        ];
		this.setState({ 
			source: data,
		 });
/*
		const [datafields, columns] = getColumns(nextProps.data);
		const source =
		{
			datafields: datafields,
			datatype: 'json',
			localdata: nextProps.data
		};
		this.setState({ 
					columns: columns,
					source: new jqx.dataAdapter(source),
		 });
		 */
	}

	render(){
		return(
			<>
 				<JqxChart style={{ width: '100%', height: '75px' }}
					title={this.state.title} description={this.state.description} titlePadding={this.state.titlePadding}
					showLegend={false} enableAnimations={true} padding={this.state.padding}
					source={this.state.source} xAxis={this.state.xAxis} valueAxis={this.state.valueAxis}
					colorScheme={'scheme04'} seriesGroups={this.state.seriesGroups} 
					borderLineColor={'#fc5c7d'}
					/>
			</>
	
		)
	}	
}

function getColumns(data){
	var keys = Object.keys(data[0]);
	var datafields = [];
	var columns = [];
	for(var key of keys){
		datafields.push({ name: key, type: 'string'});
		columns.push({ text: key, datafield: key });
	}
	return [datafields, columns];
}
function numberWithCommas(x) {
	return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  }
  
export default VtxChart;